import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import ReactSelect from 'react-select'
import errorRed from '../../public/images/error-red.svg'
import {
  Progress,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import jobsActions from '../../redux/actions/jobs'
import general from '../../redux/actions/general'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DataTable from '../../components/Datatable'
import { isAdminRole } from '../../AutominerUtility'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

let Index = () => {
  const [searchValue, setSearchValue] = useState({
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
    user: 'Everyone',
    specificUsers: [],
    status: ['Any'],
    company: [],
    mill: [],
    machine: [],
    keyword: '',
    selectedUsers: [],
    selectedCompanies: [],
    selectedMills:[],
    selectedMachines: [],
    selectedStatusOption: [],
    sortColumn: 'id',
    sortType: 'desc',
    itemsPerPage: 10,
    selectedPageNumber: 1
  })
  const [searchTages, setSearchTags] = useState([])
  const { userInfo } = useSelector((state) => state.auth.login)
  
  const filterSubmit = (filters) => {
    console.log(filters)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    let searchRequest = {
      'userInfo' :{
          'userId' : userInfo.id
      },
      'filterCriteria': {
        'keyword': searchValue.keyword,
        'fromDate': searchValue.fromDate,
        'toDate': searchValue.toDate,
        'selectedCompanies':searchValue.selectedCompanies,
        'selectedMills': searchValue.selectedMills,
        'selectedMachines': searchValue.selectedMachines,
        'selectedUsers': searchValue.selectedUsers,
        'selectedStatusOption': searchValue.selectedStatusOption,
        'listingType': 'all'
      },
      'sortCriteria': {
        'sortColumn': searchValue.sortColumn,
        'sortType': searchValue.sortType
      },
      'pagingCriteria': {
        'itemsPerPage': searchValue.itemsPerPage,
        'selectedPageNumber': searchValue.selectedPageNumber
      }
    }
    dispatch(jobsActions.searchJobs(searchRequest))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    dispatch(general.getFilterData({userId: userInfo.id, listingType: 'Search'}))
  }, [dispatch, userInfo.id])


  const { filterData } = useSelector((state) => state.general.Filter)

  const [isValidDate, setValidDate] = useState(true)
  const [companyList] = useState(filterData.companies)
  const [millList, setMillList] = useState(null)
  const [machineList, setMachineList] = useState(null)
  const [userList] = useState(filterData.users)

  const [isTagExpanded, setTagExpanded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  let handelSearchtagChange = (type, value, isChecked, option) => {
    if (type === 'keyword') {
      setSearchTags(searchTages.filter((item) => item.key !== type))
      setSearchTags((oldArray) => [
        ...oldArray,
        { key: type, value: 'Keyword: ' + value },
      ])
    } else if (['fromDate', 'fromTime', 'toDate', 'toTime'].includes(type)) {
      if (type === 'toTime' || type === 'fromTime') {
        value = dayjs(value).format('hh:mm a')
      }
      let fromDate = type === 'fromDate' ? value : searchValue.fromDate
      let toDate = type === 'toDate' ? value : searchValue.toDate
      let fromTime =
        type === 'fromTime'
          ? value
          : searchValue.fromTime !== ''
          ? dayjs(searchValue.fromTime).format('hh:mm a')
          : ''
      let toTime =
        type === 'toTime'
          ? value
          : searchValue.toTime !== ''
          ? dayjs(searchValue.toTime).format('hh:mm a')
          : ''

      let tagString = ''
      tagString += fromDate !== '' ? dayjs(fromDate).format('MMM Do YYYY') : ''
      tagString += fromTime ? ' @ ' + fromTime : ''
      tagString +=
        toDate !== '' ? ' - ' + dayjs(toDate).format('MMM Do YY') : ''
      tagString += toTime ? ' @ ' + toTime : ''

      setSearchTags(searchTages.filter((item) => item.key !== 'dateTime'))
      setSearchTags((oldArray) => [
        ...oldArray,
        { key: 'dateTime', value: 'Date Range: ' + tagString },
      ])
    } else if (type === 'user') {
      
      setSearchTags(
        searchTages.filter((item) => {
          return (![type, 'Specific User(s)'].includes(item.key))
        })
      )
      if (value === 'Only Me') {
        setSearchTags((oldArray) => [
          ...oldArray,
          { key: type, value: 'Users: ' + value },
        ]) 
      }
    } else if (type === 'Specific User(s)') {
      
      setSearchTags(searchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Users: ' + val.label, userId: val.value },
          ])
        })
      }
      
      
    } else if (type === 'company') {
      setSearchTags(searchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Company: ' + val.value, label: val.value },
          ])
        })

        let compArray = value.map((vl) => { return vl.value})
        dispatch(general.getMillFromSelectedCompany(compArray)).then((result) => {
          if(result) {
            setMillList(result)
          }
        })
      }
    } else if (type === 'mill') {
      setSearchTags(searchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Mill: ' + val.value, label: val.value },
          ])
        })
        
        let millArray = value.map((vl) => { return vl.value})
        dispatch(general.getMachineFromSelectedMills(millArray)).then((result) => {
          if(result) {
            setMachineList(result)
          }
        })
      }
      
    } else if (type === 'machine') {
      setSearchTags(searchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Machine: ' + val.value, label: val.value },
          ])
        })
      }
    } else if (type === 'status' && value !== 'Any') {
      if (isChecked) {
        setSearchTags((oldArray) => [
          ...oldArray,
          { key: value, value: 'Status: ' + value, statusId: option.value },
        ])
      } else {
        setSearchTags(searchTages.filter((item) => item.key !== value))
      }
    } else if (type === 'status' && value === 'Any') {
      setSearchTags(
        searchTages.filter((item) => {
          return (![
            'Running Exploration',
            'Running Prediction',
            'Submitted',
            'Completed',
            'Stopped',
          ].includes(item.key))
        })
      )
    }
  }

  useEffect(() => {
  }, [searchTages])

  useEffect(() => {
  }, [searchValue.specificUsers])

  let removeTag = (index) => {
    let removeItem = searchTages[index]
    setSearchTags(searchTages.filter((item, i) => i !== index))

    if (removeItem.key === 'keyword') {
      setSearchValue({
        ...searchValue,
        keyword: '',
      })
    } else if (removeItem.key === 'company') {
      let searchValueCompany = searchValue.company
      let selectedCompanies = searchValue.selectedCompanies
      setSearchValue({
        ...searchValue,
        company: searchValueCompany.filter((d) => d.label !== removeItem.label),
        selectedCompanies: selectedCompanies.filter((d) => d !== removeItem.label),
      })

      if (searchValueCompany.length === 1) {
        setSearchValue({
          ...searchValue,
          company: [],
          mill: [],
          machine: [],
          selectedCompanies: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = searchTages.filter((d) => d.key !== 'company' && d.key !== 'mill' && d.key !== 'machine' )
        setSearchTags(
          tempSearchTags
        )
      }
    } else if (removeItem.key === 'mill') {
      let searchValueMills = searchValue.mill
      let searchSelectedMills = searchValue.selectedMills

      setSearchValue({
        ...searchValue,
        mill: searchValueMills.filter((d) => d.value !== removeItem.label),
        selectedMills: searchSelectedMills.filter((d) => d !== removeItem.label),
      })
      if (searchValueMills.length === 1) {
        setSearchValue({
          ...searchValue,
          mill: [],
          machine: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = searchTages.filter((d) => d.key !== 'mill' && d.key !== 'machine' )
        tempSearchTags(
          tempSearchTags
        )
      }
    } else if (removeItem.key === 'machine') {
      let searchValueMachine = searchValue.machine
      let selectedValueMachine = searchValue.selectedMachines
      setSearchValue({
        ...searchValue,
        machine: searchValueMachine.filter((d) => d.value !== removeItem.label),
        selectedMachines: selectedValueMachine.filter((d) => d !== removeItem.label)
      })
    } else if(removeItem.key === 'user'){
      setSearchValue({
        ...searchValue,
        user: 'Everyone',
        selectedUsers: []
      })
    } else if(removeItem.key === 'Specific User(s)'){
      let searchValueSpecificUser = searchValue.specificUsers
      let searchValueSelectedUser = searchValue.selectedUsers

      if(searchValueSpecificUser.length > 0 && searchValueSelectedUser.length > 0) {
        setSearchValue({
          ...searchValue,
          specificUsers: searchValueSpecificUser.filter((d) => d.value !== removeItem.userId),
          selectedUsers: searchValueSelectedUser.filter((d) => d !== removeItem.userId),
        })
      } else {
        setSearchValue({
          ...searchValue,
          specificUsers: [],
          selectedUsers: []
        })
      }
     } else if (
      [
        'Any',
        'Running Exploration',
        'Running Prediction',
        'Submitted',
        'Completed',
        'Stopped',
      ].includes(removeItem.key)
    ) {
      let status = searchValue.status
      let selectedStatusOption = searchValue.selectedStatusOption
      status.splice(status.indexOf(removeItem.key), 1)
      selectedStatusOption.splice(selectedStatusOption.indexOf(removeItem.statusId), 1)
      setSearchValue({
        ...searchValue,
        status: status,
        selectedStatusOption: selectedStatusOption
      })

    } else if (removeItem.key === 'dateTime') {
      setSearchValue({
        ...searchValue,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
      })
    }
  }

  const [tableData, setTableData] = useState([])
  const [extendedColumns, setExtendedColumns] = useState([])

  const clearExpanded = () => {
    let updateTableData = [...tableData]
    updateTableData = updateTableData.filter((item) => {
      item.collapsable = false
      return !item.expanded
    })
    setExtendedColumns([])
    setTableData(updateTableData)
  }

  const [validFromDate, setValidFromDate] = useState(true)
  const [validToDate, setValidToDate] = useState(true)
  const [validFromTime, setValidFromTime] = useState(true)
  const [validToTime, setValidToTime] = useState(true)

  let handelSearchClick = () => {
    let hadError = false;
    if (searchValue.fromDate > new Date() || searchValue.toDate > new Date()) {
      setErrorMessage('Future date in not allowed.')
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate !== '' &&
      searchValue.toDate !== '' &&
      searchValue.fromDate > searchValue.toDate
    ) {
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(false)
      setErrorMessage('From Date cannot later than To Date.')
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate !== '' &&
      searchValue.toDate !== '' &&
      (searchValue.toTime === '' || searchValue.fromTime === '')
    ) {
      setErrorMessage('Enter Time.')
      searchValue.toTime === '' ? setValidToTime(false) : setValidToTime(true)
      searchValue.fromTime === ''
        ? setValidFromTime(false)
        : setValidFromTime(true)
      setValidFromDate(true)
      setValidToDate(true)
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromTime !== '' &&
      searchValue.toTime !== '' &&
      (searchValue.fromDate === '' || searchValue.toDate === '')
    ) {
      setErrorMessage('Enter Date.')
      searchValue.fromDate === ''
        ? setValidFromDate(false)
        : setValidFromDate(true)
      searchValue.toDate === '' ? setValidToDate(false) : setValidToDate(true)
      setValidFromTime(true)
      setValidToTime(true)
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate !== '' &&
      searchValue.toDate === '' &&
      searchValue.fromTime === '' &&
      searchValue.toTime === ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(true)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate === '' &&
      searchValue.toDate !== '' &&
      searchValue.fromTime === '' &&
      searchValue.toTime === ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(false)
      setValidToDate(true)
      setValidFromTime(false)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate === '' &&
      searchValue.toDate === '' &&
      searchValue.fromTime !== '' &&
      searchValue.toTime === ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(true)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate === '' &&
      searchValue.toDate === '' &&
      searchValue.fromTime === '' &&
      searchValue.toTime !== ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(true)
      setErrorMessage('Enter valid date-time.')
      setValidDate(false)
      hadError = true;
    } else if (
      searchValue.fromDate !== '' ||
      searchValue.toDate !== '' ||
      searchValue.fromTime !== '' ||
      searchValue.toTime !== ''
    ) {
      if (searchValue.fromDate === '') setValidFromDate(false)
      else setValidFromDate(true)

      if (searchValue.toDate === '') setValidToDate(false)
      else setValidToDate(true)

      if (searchValue.fromTime === '') setValidFromTime(false)
      else setValidFromTime(true)

      if (searchValue.toTime === '') setValidToTime(false)
      else setValidToTime(true)

      setErrorMessage('Enter valid date-time')
      setValidDate(false)

      if (
        searchValue.fromDate !== '' &&
        searchValue.toDate !== '' &&
        searchValue.fromTime !== '' &&
        searchValue.toTime !== '' &&
        searchValue.fromDate <= new Date() &&
        searchValue.toDate <= new Date() &&
        searchValue.fromDate <= searchValue.toDate
      ) {
        hadError = false
        setValidFromDate(true)
        setValidToDate(true)
        setValidFromTime(true)
        setValidToTime(true)
        setErrorMessage('')
        setValidDate(true)
      }
    } else {
      hadError = false
      setValidFromDate(true)
      setValidToDate(true)
      setValidFromTime(true)
      setValidToTime(true)
      setErrorMessage('')
      setValidDate(true)
    }

    if (hadError === false) {
      setSearchValue({
        ...searchValue,
        selectedPageNumber: 1 
      });
      let searchRequest = {
        'userInfo' :{
          'userId' : userInfo.id
      },
        'filterCriteria': {
          'keyword': searchValue.keyword,
          'fromDate': searchValue.fromDate,
          'toDate': searchValue.toDate,
          'selectedCompanies':searchValue.selectedCompanies,
          'selectedMills': searchValue.selectedMills,
          'selectedMachines': searchValue.selectedMachines,
          'selectedUsers': searchValue.selectedUsers,
          'selectedStatusOption': searchValue.selectedStatusOption,
          'listingType': 'all'
        },
        'sortCriteria': {
          'sortColumn': searchValue.sortColumn,
          'sortType': searchValue.sortType
        },
        'pagingCriteria': {
          'itemsPerPage': searchValue.itemsPerPage,
          'selectedPageNumber': 1
        }
      }
      
      dispatch(jobsActions.searchJobs(searchRequest))
    }
  }

  let handelClearAll = () => {
    setSearchTags([])
    setSearchValue({
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      user: 'Everyone',
      specificUsers: [],
      status: ['Any'],
      company: [],
      mill: [],
      machine: [],
      keyword: '',
      selectedUsers: [],
      selectedCompanies: [],
      selectedMills:[],
      selectedMachines: [],
      selectedStatusOption: [],
      sortColumn: 'id',
      sortType: 'desc',
      itemsPerPage: 10,
      selectedPageNumber: 1
    })
    setValidFromDate(true)
    setValidToDate(true)
    setValidFromTime(true)
    setValidToTime(true)
    setErrorMessage('')
    setValidDate(true)
  }

  const fetchMoreData = (page, showEntries) => {
    setSearchValue({
      ...searchValue,
      selectedPageNumber: page,
      itemsPerPage: JSON.parse(showEntries),
    })
    let searchRequest = {
      'userInfo' :{
        'userId' : userInfo.id
      },
      'filterCriteria': {
        'keyword': searchValue.keyword,
        'fromDate': searchValue.fromDate,
        'toDate': searchValue.toDate,
        'selectedCompanies':searchValue.selectedCompanies,
        'selectedMills': searchValue.selectedMills,
        'selectedMachines': searchValue.selectedMachines,
        'selectedUsers': searchValue.selectedUsers,
        'selectedStatusOption': searchValue.selectedStatusOption,
        'listingType': 'all'
      },
      'sortCriteria': {
        'sortColumn': searchValue.sortColumn,
        'sortType': searchValue.sortType
      },
      'pagingCriteria': {
        'itemsPerPage': JSON.parse(showEntries),
        'selectedPageNumber': page
      }
    }
    
    dispatch(jobsActions.searchJobs(searchRequest))
  }
  
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [jobTitle, setJobTitle] = useState('')
  const [jobIdForStop, setJobIdForStop] = useState(-1)

  let stopJobConfirmation = (jobTitle, jobId) => {
    setJobTitle(jobTitle)
    setJobIdForStop(JSON.stringify(jobId))
    setConfirmationModalOpen(true)
  }

  let handelStopJob = () => {
    dispatch(jobsActions.stopJobProgress({'jobId': jobIdForStop})).then((result) => {
     if(result.status) {
       setConfirmationModalOpen(false)
       dispatch(jobsActions.getJobsInProgress())
     }
    })
  }

  const columns = [
    {
      label: 'Id',
      field: 'id',
      sort: true,
      width: '90px',
      align: 'center',
      customRow: (row) => {
        return row.percentage === 100 ? (
          <Link to={'/completed-job/' + row.id} alt="">
            <span className={row.status_name === 'Stopped' ? 'td-id td-stopped' : 'td-id'}>
              {row.id}
            </span>
          </Link>
        ) : (
          <Link to={'/job-in-progress/' + row.id} alt="">
            <span className={row.status_name === 'Stopped' ? 'td-id td-stopped' : 'td-id'}>
              {row.id}
            </span>
          </Link>
        )
      },
    },
    {
      label: 'Submitted',
      field: 'submission_time',
      sort: true,
      wrap: true,
      width: '140px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped' : ''}>
            {row.submission_time}
          </span>
        )
      },
    },
    {
      label: 'User',
      field: 'user_name',
      sort: true,
      width: '145px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped' : ''}>
            {row.user_name === userInfo.user_name ? (
              <span className="td-highLight">
                <b>{row.user_name}</b> (me)
              </span>
            ) : (
              row.user_name
            )}
          </span>
        )
      },
    },
    {
      label: 'Job Name',
      field: 'job_name',
      sort: true,
      wrap: true,
      width: '290px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.job_name}
          </span>
        )
      },
    },
    {
      label: 'File',
      field: 's3_file_path',
      wrap: true,
      width: '70px',
      align: 'center',
      customRow: (row) => {
        return (
          <span className="td-icon">
            <a download={row.s3_file_path} href={row.s3_file_path}>
              <i className="icon-source-file-sm"></i>
            </a>
          </span>
        )
      },
    },
    {
      label: 'Company',
      field: 'company',
      sort: true,
      wrap: true,
      width: '195px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.company}
          </span>
        )
      },
    },
    {
      label: 'Mill',
      field: 'mill',
      sort: true,
      wrap: true,
      width: '150px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.mill}
          </span>
        )
      },
    },
    {
      label: 'Machine',
      field: 'machine',
      sort: true,
      wrap: true,
      width: '130px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.machine}
          </span>
        )
      },
    },
    {
      label: 'Status',
      field: 'status_name',
      sort: true,
      wrap: true,
      width: '230px',
      customRow: (row) => {
        return (
          <>
            {row.status_name === 'Stopped' ? (
              <span className="text-danger">{row.status_name}</span>
            ) : (
              <span className="text-strong">{row.status_name}</span>
            )}
            <Progress className="td-stopped" value={row.percentage} />
          </>
        )
      },
    },
    {
      label: 'Notes',
      field: 'notes',
      wrap: true,
      width: '225px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-light' : 'td-light'}>
            {row.notes}
          </span>
        )
      },
    },
    {
      label: 'Action',
      field: 'stop_job',
      width: '125px',
      align: 'center',
      customRow: (row) => {
        return (
          <div className="td-btn-flex">
            {row.percentage === 100 ? (
              <Link to={'/completed-job/' + row.id} alt="">
                <Button
                  className="dark-shadow view-small view-btn"
                  type="button"
                  id={'btnCompleted-' + row.id}
                >
                  View
                </Button>
              </Link>
            ) : row.status_name === 'Stopped' ? (
              <span
                className="small-icon danger-icon"
                onClick={() => stopJobConfirmation(row.job_name, row.id)}
              >
                <i id={'btnStopJob-' + row.id} className="fa fa-stop"></i>
              </span>
            ) : (
              <span
                className="small-icon"
                onClick={() => stopJobConfirmation(row.job_name, row.id)}
              >
                <i id={'btnStopJob-' + row.id} className="fa fa-stop"></i>
              </span>
            )}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (searchTages.mill && searchTages.mill.length === 0) {
      setSearchTags({...searchTages, machine: []})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTages.mill])

  const { searchedJobs } = useSelector((state) => state.jobs)

  useEffect(() => {
    // console.log('searchedJobs', searchedJobs)
  }, [searchedJobs])

  

  return (
    <>
      <Header></Header>
      <main>
        <section>
          <div className="container">
            <div className="search-job-top">
              <div className="row align-items-center justify-content-between">
                <div className="col-7">
                  <ul className="list-of-name">
                    <li>
                      <i className="icon icon-progress"></i>
                      <span>jobs in progress</span>
                      <span className="number">
                        {filterData.inProgressJobsCount}
                      </span>
                    </li>
                    <li>
                      <i className="icon icon-completed"></i>
                      <span>Completed jobs</span>
                      <span className="number">
                        {filterData.completedJobsCount}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-5 text-right">
                  <div className="btn-box">
                    <Link to="/dashboard" alt="">
                      <button
                        type="button"
                        id="btnStartANewJob"
                        className="btn-lg min-btn btn btn-secondary"
                      >
                        Start a new job
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container search-form">
            <div className="title-center">
              <h2>
                <i className="icon icon-search"></i> Search jobs
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-label-group">
                  <label>keyword or id#</label>
                  <input
                    type="text"
                    id="txtsearchKeyword"
                    className="form-control f-sm search-id"
                    placeholder="Type a keyword or ID#"
                    value={searchValue.keyword}
                    onChange={(e) => {
                      setSearchValue({
                        ...searchValue,
                        keyword: e.target.value,
                      })
                      handelSearchtagChange('keyword', e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <div className="form-label-group data-range">
                  <label>Date range</label>
                  <div className="from-to search-from-to">
                    <div className="from-row form-label-group">
                      <span>From</span>
                      <div
                        className={
                          validFromDate
                            ? 'select-custom select-sm'
                            : 'select-custom select-sm error-invalid'
                        }
                      >
                        <DatePicker
                          className="form-control"
                          id="selFromDate"
                          selected={searchValue.fromDate}
                          onChange={(date) => {
                            setSearchValue({
                              ...searchValue,
                              fromDate: date,
                            })
                            handelSearchtagChange('fromDate', date)
                          }}
                          placeholderText="Date"
                        />
                      </div>
                      <div
                        className={
                          validFromTime
                            ? 'select-custom select-sm to-range'
                            : 'select-custom select-sm to-range error-invalid'
                        }
                      >
                        <DatePicker
                          selected={searchValue.fromTime}
                          placeholderText="Time"
                          value={searchValue.fromTime || ''}
                          className="form-control"
                          onChange={(e) => {
                            setSearchValue({
                              ...searchValue,
                              fromTime: e,
                            })
                            handelSearchtagChange('fromTime', e)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>
                    </div>
                    <div className="from-row form-label-group">
                      <span>To</span>
                      <div
                        className={
                          validToDate
                            ? 'select-custom select-sm'
                            : 'select-custom select-sm error-invalid'
                        }
                      >
                        <DatePicker
                          className="form-control"
                          id="selToDate"
                          selected={searchValue.toDate}
                          onChange={(date) => {
                            setSearchValue({
                              ...searchValue,
                              toDate: date,
                            })
                            handelSearchtagChange('toDate', date)
                          }}
                          placeholderText="Date"
                        />
                      </div>
                      <div
                        className={
                          validToTime
                            ? 'select-custom select-sm to-range'
                            : 'select-custom select-sm to-range error-invalid'
                        }
                      >
                        <DatePicker
                          selected={searchValue.toTime}
                          placeholderText="Time"
                          value={searchValue.toTime || ''}
                          className="form-control"
                          onChange={(e) => {
                            setSearchValue({
                              ...searchValue,
                              toTime: e,
                            })
                            handelSearchtagChange('toTime', e)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      isValidDate
                        ? 'invalid-feedback'
                        : 'invalid-feedback d-flex'
                    }
                  >
                    <img alt="" src={errorRed} /> <span>{errorMessage}</span>
                  </div>
                </div>
              </div>

              <div className={'col-lg-3' + ' '+ (isAdminRole(userInfo['role_name']) ? '' : 'hide')} >
                <label>user</label>
                <span
                  value={searchValue.user}
                  id="radioUsers"
                >
                  {filterData && filterData.userOptions && filterData.userOptions.length > 0 && filterData.userOptions.map((options, index) => (
                    <div key={'optinKey-'+index} className="radiobox d-block mb-2">
                    <input
                      type="radio"
                      name="radio"
                      className="form-check-input"
                      value={options.label}
                      checked={searchValue.user === options.label ? true : false}
                      onChange={(e) => {
                        handelSearchtagChange('user', e.target.value, '', options)
                        if (e.target.value === 'Everyone') {
                          setSearchValue({
                            ...searchValue,
                            specificUsers: [],
                            user: e.target.value,
                            selectedUsers: []
                          })
                        } else if( e.target.value === 'Only Me') {
                          setSearchValue({
                            ...searchValue,
                            specificUsers: [],
                            user: e.target.value,
                            selectedUsers: options.value
                          })
                        } else {
                          setSearchValue({ ...searchValue, user: e.target.value })
                        }
                      }}
                    />
                    <label>{options.label}</label>
                  </div>
                  ))}
                </span>
                <div className="select-custom select-sm search-bottom">
                  <ReactSelect
                    isClearable={false}
                    placeholder="Select user(s)"
                    value={searchValue.specificUsers}
                    onChange={(e) => {
                      if(e) {
                        let specificUsersArray = e.map((vl) => { return vl.value})
                        setSearchValue({
                          ...searchValue,
                          specificUsers: e || [],
                          selectedUsers: specificUsersArray
                        })
                        handelSearchtagChange('Specific User(s)', e)
                      } else {
                        setSearchValue({
                          ...searchValue,
                          specificUsers: [],
                          selectedUsers: []
                        })
                        handelSearchtagChange('Specific User(s)', e)
                      }
                    }}
                    isDisabled={
                      searchValue.user === 'Specific User(s)' ? false : true
                    }
                    classNamePrefix="form-control"
                    isMulti
                    options={userList}
                  ></ReactSelect>
                </div>
              </div>

              <div className="col-lg-3">
                <label>Status</label>
                {filterData && filterData.statusOptions && filterData.statusOptions.length > 0 && filterData.statusOptions.map((statusOptions, index) => (
                  <div key={'statusOption-'+index} className="checkbox d-block mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={statusOptions.label}
                      id={'chk'+statusOptions.label}
                      checked={searchValue.status.indexOf(statusOptions.label) > -1 ? true : false}
                      onChange={(e) => {
                        let status = searchValue.status
                        let selectedStatusOption = searchValue.selectedStatusOption

                        if (searchValue.status.indexOf('Any') !== -1) {
                          searchValue.status.splice(
                            searchValue.status.indexOf('Any'),
                            1
                          )
                        }
                        if(statusOptions.label !== 'Any') {
                          if(searchValue.status.indexOf(statusOptions.label) === -1 ) {
                            status.push(statusOptions.label)
                            selectedStatusOption.push(statusOptions.value)
                          } else {
                            status.splice(status.indexOf(statusOptions.label), 1)
                            selectedStatusOption.splice(selectedStatusOption.indexOf(statusOptions.value), 1)
                          }
                        } else {
                          status = ['Any']
                          selectedStatusOption = [];
                        }

                        setSearchValue({
                          ...searchValue,
                          status: status,
                          selectedStatusOption: selectedStatusOption
                        })

                        handelSearchtagChange(
                          'status',
                          e.target.value,
                          e.target.checked,
                          statusOptions
                        )
                      }}
                    />
                  <label>{statusOptions.label}</label>
                </div>
                ))}
              </div> 

              <div className="col-lg-3">
                <label>Company</label>
                <div className="select-custom select-sm">
                  <ReactSelect
                    isClearable={false}
                    placeholder="Select Company(ies)"
                    value={searchValue.company}
                    onChange={(e) => {
                      if (!e) {
                        setSearchValue({
                          ...searchValue,
                          company: [],
                          mill: [],
                          machine: [],
                        })
                        let tempSearchTags = searchTages
                        for (let i = 0; i < tempSearchTags.length; i++) {
                          if (
                            tempSearchTags[i].key === 'machine' ||
                            tempSearchTags[i].key === 'mill'
                          ) {
                            tempSearchTags.splice(i, 1)
                          }
                        }
                        setSearchTags(tempSearchTags)
                      } else {
                        let selecetedCompayArray = e.map((vl) => { return vl.value})
                        setSearchValue({
                          ...searchValue,
                          company: e || [],
                          selectedCompanies: selecetedCompayArray
                        })
                      }
                      handelSearchtagChange('company', e)
                    }}
                    classNamePrefix="form-control"
                    isMulti
                    options={companyList}
                  ></ReactSelect>
                </div>
                <div className="row mill-machine">
                  <div className="col-lg-6 col-full">
                    <label
                      className={
                        searchValue.company.length === 0 ? 'unselect' : ''
                      }
                    >
                      Mill
                    </label>
                    <div className="select-custom select-sm">
                      <ReactSelect
                        isMulti
                        isClearable={false}
                        isDisabled={
                          searchValue.company.length === 0 ? true : false
                        }
                        id="selMill"
                        options={millList}
                        className={
                          searchValue.company.length === 0 ? 'unselect' : ''
                        }
                        classNamePrefix="form-control"
                        placeholder="Select Mill(s)"
                        value={searchValue.mill}
                        onChange={(e) => {
                          if (!e) {
                            setSearchValue({
                              ...searchValue,
                              mill: [],
                              machine: [],
                            })
                            let tempSearchTags = searchTages
                            for (let i = 0; i < tempSearchTags.length; i++) {
                              if (tempSearchTags[i].key === 'machine') {
                                tempSearchTags.splice(i, 1)
                              }
                            }
                            setSearchTags(tempSearchTags)
                          } else {
                            let selecetedMillArray = e.map((vl) => { return vl.value})
                            setSearchValue({
                              ...searchValue,
                              mill: e || [],
                              selectedMills: selecetedMillArray
                            })
                          }
                          handelSearchtagChange('mill', e)
                        }}
                      ></ReactSelect>
                    </div>
                  </div>
                  <div className="col-lg-6 col-full">
                    <label
                      className={
                        searchValue.mill.length === 0 ? 'unselect' : ''
                      }
                    >
                      Machine
                    </label>
                    <div className="select-custom select-sm">
                      <ReactSelect
                        isMulti
                        isClearable={false}
                        isDisabled={
                          searchValue.mill.length === 0 ? true : false
                        }
                        options={machineList}
                        id="selMachine"
                        className={
                          searchValue.mill.length === 0 ? 'unselect' : ''
                        }
                        classNamePrefix="form-control"
                        placeholder="Select Machine(s)"
                        value={searchValue.machine}
                        onChange={(e) => {
                          let selecetedMachineArray = e.map((vl) => { return vl.value})
                          setSearchValue({
                            ...searchValue,
                            machine: e || [],
                            selectedMachines: selecetedMachineArray
                          })
                          handelSearchtagChange('machine', e)
                        }}
                      ></ReactSelect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center filters-add-main">
              <div className="col-lg-8 filters-add">
                {searchTages.length > 0 ? (
                  <span className="mr-2">Filters</span>
                ) : (
                  ''
                )}
                {isTagExpanded
                  ? searchTages &&
                    searchTages.map((tag, index) => {
                      return (
                        <span
                          key={index.toString()}
                          className="mr-2 filter-tags"
                        >
                          {tag.value}{' '}
                          <span
                            aria-hidden="true"
                            key={index.toString()}
                            onClick={() => removeTag(index)}
                          >
                            &times;
                          </span>
                        </span>
                      )
                    })
                  : searchTages &&
                    searchTages.map((tag, index) => {
                      return index < 3 ? (
                        <span
                          key={index.toString()}
                          className="mr-2 filter-tags"
                        >
                          {tag.value}{' '}
                          <span
                            aria-hidden="true"
                            key={index.toString()}
                            onClick={() => removeTag(index)}
                          >
                            &times;
                          </span>
                        </span>
                      ) : (
                        ''
                      )
                    })}
                {!isTagExpanded && searchTages.length > 3 ? (
                  <span
                    className="mr-2 expand-tag filter-tags"
                    onClick={() => setTagExpanded(true)}
                  >
                    +{searchTages.length - 3}
                  </span>
                ) : (
                  ''
                )}
                {searchTages.length > 0 ? (
                  <span className="clear-all-tag" onClick={handelClearAll}>
                    clear all
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className="col-lg-4 text-right">
                <button
                  type="button"
                  id="btnSearchJob"
                  className="btn-lg min-btn active btn btn-secondary search-sub-btn"
                  onClick={handelSearchClick}
                >
                  Search Job
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5">
          <div className="container">
            <DataTable
              data={searchedJobs.jobs}
              columns={columns}
              filterData={{}}
              filterSubmit={filterSubmit}
              extendedColumns={extendedColumns}
              clearExpanded={clearExpanded}
              defaultSort={searchValue.sortColumn}
              defaultSortType={searchValue.sortType}
              isResultVisible={true}
              itemsPerPage={searchValue.itemsPerPage}
              selectedPageNumber={searchValue.selectedPageNumber}
              totalCount={searchedJobs.totalCount}
              fetchMoreData={fetchMoreData}
            />
          </div>
        </section>
      </main>

      <Modal
        className="modal-dialog-centered modal-lg success-modal"
        isOpen={isConfirmationModalOpen}
        onClick={() => setConfirmationModalOpen(!isConfirmationModalOpen)}
      >
        <ModalHeader toggle={() => setConfirmationModalOpen(!isConfirmationModalOpen)}></ModalHeader>
        <ModalBody>
          {/* <h2>Success!</h2> */}
          <p>Are you sure you want to stop job {jobTitle}?</p>
          <span>Once Job is stopped you can't start this job again.</span>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            type="button"
            color="primary"
            id="btnCancelSendEmail"
            onClick={() => setConfirmationModalOpen(false)}
          >
            No <i className="icon-cancel"></i>
          </Button>
          <Button
            type="button"
            className="active ml-3"
            id="btnSendEmail"
            color="secondary"
            onClick={handelStopJob}
          >
            Yes <i className="icon-apply"></i>
          </Button>
        </ModalFooter>
      </Modal>

      <Footer></Footer>
    </>
  )
}

export default Index
