const initialState = {
    filterData: {},
  }
  
  export const Filter = (state = initialState, action) => {
    switch (action.type) {
      case 'FILTER_DATA':
        return Object.assign({}, state, {
            filterData: action.payload.filterData,
        })
      default:
        return state
    }
  }
  