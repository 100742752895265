import styled from 'styled-components'
import { lighten } from 'polished'

export const Th = styled.th`
    &.sort {
        position: relative;
        cursor: pointer;
        padding-right: 20px;
        &:before, &:after {
            content: '\f0d8';
            font-size: 13px;
            font-family: 'FontAwesome';
            position: absolute;
            top: 0;
            right: 8px;
            bottom: 0;
            display: flex;
            align-items: center;
            pointer-events: none;
            transform: translateY(-4px);
            opacity: 0.2;
        }
        &:after {
            content: '\f0d7';
            transform: translateY(4px);
        }
        &.sort_asc {
            &:before {
                opacity: 1
            }
        }
        &.sort_desc {
            &:after {
                opacity: 1
            }
        }
    }
`

export const Tr = styled.tr`
    &.extended {
        background-color: ${props => props.theme.appColor ? props.theme.appColor : '#0054bb'};
        color: '#ffffff';
        &:hover {
            background-color: ${props => lighten(0.01, props.theme.appColor ? props.theme.appColor : '#0054bb')} !important;
            color: '#ffffff' !important;
        }
        .infoBtn {
            background-color: ${props => props.theme.notifyButtonColor ? props.theme.notifyButtonColor : '#0054bb'};
            .color-blue {
                color: '#ffffff';
            }
        }
    }
    td {
        position: relative;
    }
    .notifyBtn.position {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: auto;
        svg {
            width: 14px;
            margin-left: 20px;
            path {
                fill: '#ffffff';
            }
        }
        @media screen and ( max-width: ${'991px'} ) {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            padding: 0;
            display: inline-block;
            svg {
                margin: 0;
            }
            span {
                display: none;
            }
        }
    }
`
export const PaginationItems = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const CustomCheckbox = styled.label`
    input[type="checkbox"] {
        display: none;
        + .span-text {
            display: block;
            padding-left: 25px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 15px;
                height: 15px;
                background-color: ${'#f0f0f0'};
            }
        }
        &:checked {
            + .span-text {
                &::before {
                    background-color: ${props => props.theme.appColor ? props.theme.appColor : '#0054bb'}
                }
            }
        }
    }
`