import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

let Index = (props) => {
  let plotLines = [];

  const scatterData = props.chartData ? props.chartData.data : []
  const xAxisLabel = props.chartData ? props.chartData.x : ''
  const yAxisLabel = props.chartData ? props.chartData.y : ''

  if (props.isDisplayPlotLine) {
    plotLines = [
      {
        value: 0,
        width: 1,
        color: 'black',
      },
    ]
  }

  const chartOptions = {
    chart: {
      type: 'scatter',
      height: props.height ? props.height : 540,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: xAxisLabel
      },
      lineWidth: 0,
      gridLineWidth: 0,
      plotLines: plotLines,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      title: {
        text: yAxisLabel
      },
      lineWidth: 0.1,
      gridLineWidth: 1,
      plotLines: plotLines,
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 3.5,
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)',
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          pointFormat: '{point.x} , {point.y} ',
        },
      },
    },
    series: [
      {
        name: 'X-Values, y-Values',
        color: '#3c90a5',
        data: scatterData,
      },
    ],
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
