import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Pagination, PaginationItem } from 'reactstrap'
import TableContext from './TableContext'

const Paginations = (props) => {
  const [showEntry, setShowEntry] = useState(10)
  const [paginationActive, setPaginationActive] = useState(1)
  const [paginationArray, setPaginationArray] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const context = useContext(TableContext)

  const setParameters = useCallback(() => {
    setShowEntry(context.showEntry)
    setPaginationActive(context.paginationActive)
  }, [context.showEntry, context.paginationActive])

  useEffect(() => setParameters(), [setParameters])

  useEffect(() => {
    const page = Math.ceil(
      (props.totalCount - context.extended.length) / showEntry
    )
    const pagination = []
    if (page) {
      setTotalPages(page);
      if (page <= 7) {
        for (let i = 1; i <= page; i++) {
          pagination.push(i)
        }
      } else {
        if (paginationActive >= 4 && paginationActive + 1 < page) {
          pagination.push(1)
          pagination.push('...')

          for (let j = paginationActive - 1; j <= paginationActive + 1; j++) {
            pagination.push(j)
          }
          pagination.push('...')
          pagination.push(page)
        } else {
          for (let k = 1; k <= 2; k++) {
            pagination.push(k)
          }

          if (paginationActive < 4) {
            for (let l = 3; l <= 4; l++) {
              pagination.push(l)
            }
            pagination.push('...')
            pagination.push(page)
          } else {
            pagination.push('...')
            for (let m = paginationActive - 1; m <= page; m++) {
              pagination.push(m)
            }
          }
        }
      }
    }

    setPaginationArray(pagination)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEntry, paginationActive, context.data.rows, context.extended])

  const prevClick = (e) => {
    if (paginationActive > 1) {
      return context.paginationClick(paginationActive - 1)
    }
  }

  const nextClick = (e) => {
    if (paginationActive < totalPages) {
      return context.paginationClick(paginationActive + 1)
    }
  }

  return (
    <React.Fragment>
      {paginationArray.length ? (
        <Pagination className="table-pagination">
          <PaginationItem
            className="no-bg"
            disabled={paginationActive === 1}
            onClick={prevClick}
          >
            <i className="fa fa-chevron-left"></i>
          </PaginationItem>
          {paginationArray.map((page, index) => {
            return (
              <PaginationItem
                key={index}
                active={page === paginationActive}
                disabled={page === '...'}
                onClick={() => context.paginationClick(page)}
                className="font-weight-bold"
              >
                {page}
              </PaginationItem>
            )
          })}
          <PaginationItem
            className="no-bg"
            disabled={paginationActive === paginationArray.length}
            onClick={nextClick}
          >
            <i className="fa fa-chevron-right"></i>
          </PaginationItem>
        </Pagination>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default Paginations
