import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

let Index = (props) => {
  
  let tagData = [];
  let tagDesc = [];

  let data = props.chartData && props.chartData.temporalList  ? props.chartData.temporalList : [];

    data.forEach((obj, i) => {
       if (tagDesc.indexOf(obj.tagDescription) === -1) {
          tagDesc[obj.yAxis] = obj.tagDescription
       }
      tagData.push({
        name: obj.tagDescription,
        x: obj.timeStampInMillSec,
        y: obj.yAxis,
        color: obj.color,
        importance: obj.importance
      })
    })

    var chartHeight = tagDesc.length * 30

    if(chartHeight < 250) {
      chartHeight = 250
    }
    const chartOptions = {
      chart: {
        type: 'scatter',
        height: chartHeight,
      },
      title: {
        text: '',
      },
  
      xAxis: {
        type: 'datetime',
        labels: {
          rotation: -45,
          // formatter: function () {
          //   return Highcharts.formatDateForHighChart(this.value)
          // },
        },
      },
      
      yAxis: {
        categories: tagDesc,
        title: {
          text: '',
        },
      },
      tooltip: {
        formatter: function () {
          return (
            "<b>" +
            this.point.name +
            "</b><br/>" +  dayjs.utc(this.x).format('DD-MMM-YYYY HH:mm:ss')+"<br/><b> Importance:"
            + this.point.importance + "</b>"

            // + Highcharts.formatDateForHighChart(this.x)
          );
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: tagData,
          showInLegend: false,
          turboThreshold: 0,
          color: '#36c349',
        },
      ],
    }

  

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
