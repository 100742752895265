import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import errorRed from '../../public/images/error-red.svg'

import SuccessModal from '../SuccesssModal'
import generalAction from '../../redux/actions/general'

const validEmailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/

const Index = (props) => {
  const [emailData, setEmailData] = useState({
    jobId: '',
    to: '',
    subject: '',
    sendMeACopy: false,
    cc: '',
    body: '',
  })

  const [successModalVisible, setSuccessModalVisible] = useState(false)

  useEffect(() => {
    setEmailData({ ...emailData, subject: props.jobTitle, jobId: props.jobDetails ? props.jobDetails.jobId : '' })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jobTitle])

  const dispatch = useDispatch()

  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isValidSecondEmail, setIsValidSecondEmail] = useState(true)
  const [isValidSubject, setIsValidSubject] = useState(true)
  const [isValidBody, setIsValidBody] = useState(true)

  let handelSendMail = (event) => {
    event.preventDefault()
    let isError = false
    if (emailData.to.length > 0 && validEmailRegex.test(emailData.to)) {
      setIsValidEmail(true)
    } else {
      setIsValidEmail(false)
      isError = true
    }

    if (emailData.subject.length > 5) {
      setIsValidSubject(true)
    } else {
      setIsValidSubject(false)
      isError = true
    }

    if (emailData.body.length > 0) {
      setIsValidBody(true)
    } else {
      setIsValidBody(false)
      isError = true
    }

    if (emailData.sendMeACopy) {
      if (emailData.cc.length > 0 && validEmailRegex.test(emailData.cc)) {
        setIsValidSecondEmail(true)
      } else {
        setIsValidSecondEmail(false)
        isError = true
      }
    } else {
      setIsValidSecondEmail(true)
    }

    if (!isError) {
      dispatch(generalAction.sendEmail(emailData)).then((result) => {
        console.log(result)
        if (result) {
          setEmailData({
            jobId: '',
            to: '',
            subject: '',
            sendMeACopy: false,
            cc: '',
            body: '',
          })
          props.toggle()
          setSuccessModalVisible(true)
        }
      })
    }
  }
  return (
    <div>
      <Modal
        className="share-job-modal modal-dialog-centered modal-xl"
        isOpen={props.modal}
        toggle={props.toggle}
      >
        <ModalHeader toggle={props.toggle}>
          share job report via email
        </ModalHeader>
        <ModalBody>
          <div className="row justify-content-between">
            <div className="col-lg-6 pr-lg-4">
              <div
                className={
                  isValidEmail
                    ? 'form-label-group'
                    : 'form-label-group error-invalid'
                }
              >
                <label>send to</label>
                <input
                  id="txtSendTo"
                  type="text"
                  className="form-control grey-field sm-field"
                  placeholder="Enter Email Address"
                  value={emailData.to}
                  onChange={(e) =>
                    setEmailData({ ...emailData, to: e.target.value })
                  }
                ></input>
                <div
                  className={
                    isValidEmail
                      ? 'invalid-feedback'
                      : 'invalid-feedback d-block'
                  }
                >
                  <img alt="" src={errorRed} />{' '}
                  <span>Please enter a valid email.</span>
                </div>
              </div>

              <div
                className={
                  isValidSubject
                    ? 'form-label-group'
                    : 'form-label-group error-invalid'
                }
              >
                <label>subject</label>
                <input
                  id="txtSubject"
                  type="text"
                  className="form-control grey-field sm-field"
                  placeholder=""
                  value={emailData.subject}
                  onChange={(e) =>
                    setEmailData({ ...emailData, subject: e.target.value })
                  }
                ></input>
                <div
                  className={
                    isValidSubject
                      ? 'invalid-feedback'
                      : 'invalid-feedback d-block'
                  }
                >
                  <img alt="" src={errorRed} />{' '}
                  <span>Please enter a valid subject.</span>
                </div>
              </div>

              <div className={
                  isValidBody
                    ? 'form-label-group'
                    : 'form-label-group error-invalid'
                }>
                <label>Message</label>
                <textarea
                  id="txtMessage"
                  className="form-control grey-field sm-field"
                  value={emailData.body}
                  onChange={(e) =>
                    setEmailData({ ...emailData, body: e.target.value })
                  }
                ></textarea>
                <div
                  className={
                    isValidBody
                      ? 'invalid-feedback'
                      : 'invalid-feedback d-block'
                  }
                >
                  <img alt="" src={errorRed} />{' '}
                  <span>Please enter a valid body.</span>
                </div>
              </div>

              <div
                className={
                  isValidSecondEmail
                    ? 'form-label-group'
                    : 'form-label-group error-invalid'
                }
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="checkbox">
                      <input
                        id="chkSendCopy"
                        type="checkbox"
                        className="form-check-input"
                        checked={emailData.sendMeACopy}
                        onChange={(e) =>
                          setEmailData({
                            ...emailData,
                            sendMeACopy: e.target.checked,
                          })
                        }
                      ></input>
                      <label className="f-sm">
                        Send me a copy of this email
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <input
                      id="txtSendCopyEmail"
                      type="text"
                      className="form-control grey-field sm-field mb-0"
                      placeholder=""
                      disabled={emailData.sendMeACopy === true ? false : true}
                      onChange={(e) =>
                        setEmailData({ ...emailData, cc: e.target.value })
                      }
                    ></input>
                    <div
                      className={
                        isValidSecondEmail
                          ? 'invalid-feedback'
                          : 'invalid-feedback d-block'
                      }
                    >
                      <img alt="" src={errorRed} />{' '}
                      <span>Please enter a valid email.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 pl-lg-5">
              <div className="form-label-group">
                <label>report info</label>
                <div className="file-attch-link">
                  <Link to="#">
                    <i className="fa fa-paperclip"></i>{' '}
                    20200602_Raw_Material_Usage_(daily)_Q1_Proces...pdf
                  </Link>
                </div>
              </div>

              <div className="material-block">
                <div className="icon">
                  <i className="icon-report"></i>
                </div>
                { props.jobDetails ? <div className="info">
                  <h3 className="mb-4">Raw Material Usage (daily) - Q1</h3>
                  <ul>
                    <li>{props.jobDetails.jobId}</li>
                    <li>{props.jobDetails.submissionTime}</li>
                    <li>{props.jobDetails.user}</li>
                    <li>{props.jobDetails.company}</li>
                    <li>{props.jobDetails.mill}</li>
                    <li>{props.jobDetails.machine}</li>
                    <li>
                      <Link to="#">{props.jobDetails.fileName}</Link>
                    </li>
                  </ul>
                  <p>
                    {props.jobDetails.notes}
                  </p>
                </div> : ''}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="dark-shadow"
            type="button"
            color="primary"
            id="btnCancelSendEmail"
            onClick={props.toggle}
          >
            Cancel <i className="icon-cancel"></i>
          </Button>{' '}
          <Button
            type="button"
            className="active ml-3 dark-shadow"
            id="btnSendEmail"
            color="secondary"
            onClick={handelSendMail}
          >
            send email <i className="icon-email-light"></i>
          </Button>
        </ModalFooter>
      </Modal>

      <SuccessModal
        toggle={() => setSuccessModalVisible(!successModalVisible)}
        modal={successModalVisible}
      ></SuccessModal>
    </div>
  )
}

export default Index
