import { combineReducers } from 'redux'
import auth from './auth/'
import jobs from './jobs/'
import general from './general/'
import loader from './loader/'

const rootReducer = combineReducers({
  auth: auth,
  jobs: jobs,
  general: general,
  loader: loader
})

export default rootReducer
