import axiosInstance from '../../../config/axiosConfig'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getHeaderData = () => async (dispatch) => {
  let headerData = await axiosInstance.get('/common/get-header-data');
  if (headerData && headerData.data) {
    dispatch({
      type: 'HEADER_DATA',
      payload: {
        headerData: headerData.data,
      },
    })
  }
}

export const getFooterData = () => async (dispatch) => {
  let footerData = await axiosInstance.get('/common/get-footer-data');
  if (footerData && footerData.data) {
    dispatch({
      type: 'FOOTER_DATA',
      payload: {
        footerData: footerData.data,
      },
    })
    return await footerData
  }
}

export const getFilterData = (userdetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let filterData = await axiosInstance.post('/search/get-filter-table-data', userdetails);
  if (filterData && filterData.data) {
    dispatch({
      type: 'FILTER_DATA',
      payload: {
        filterData: filterData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(filterData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const sendEmail = (emailData) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let sendEmail = await axiosInstance.post('/email/send-email', emailData);
  if (sendEmail && sendEmail.data) {
    dispatch({
      type: 'SEND_EMAIL',
      payload: {
        sendEmailData: sendEmail.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.success('Email Send Successfully', {
      position: toast.POSITION.TOP_RIGHT,
    })
    return await {status: true}
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(sendEmail.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getMillFromSelectedCompany = (selectedCompany) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let getMillFromCompany = await axiosInstance.post('/search/get-mills-by-companies', selectedCompany);
  if (getMillFromCompany && getMillFromCompany.data) {
    dispatch({
      type: 'GET_MILLS_FROM_COMPANY',
      payload: {
        getMillFromCompanyData: getMillFromCompany.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    return await getMillFromCompany.data.mills
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(getMillFromCompany.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getMachineFromSelectedMills = (selectedCompany) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let getMachineFromMill = await axiosInstance.post('/search/get-machines-by-mills', selectedCompany);
  if (getMachineFromMill && getMachineFromMill.data) {
    dispatch({
      type: 'GET_MACHINES_FROM_COMPANY',
      payload: {
        getMachineFromMill: getMachineFromMill.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    return await getMachineFromMill.data.machines
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(getMachineFromMill.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

