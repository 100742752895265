import React from 'react'
import { ToastContainer } from 'react-toastify'
import './App.css'
import AppRouter from './Router'
import { useSelector } from 'react-redux'
import  Loader  from './components/Loader'

function App() {
  const { isValidUser } = useSelector((state) => state.auth.login)
  const { isLoading } = useSelector((state) => state.loader.Loader)
  return (
    <div className={isValidUser === true ? 'App logged-in' : 'App'}>
      <AppRouter />
      <Loader show={isLoading} />
      <ToastContainer />
    </div>
  )
}

export default App
