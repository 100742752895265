const initialState = {
  isValidUser: false,
  userInfo: null,
}

export const login = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, state, {
        isValidUser: action.payload.isValidUser,
        userInfo: action.payload.userInfo
        //   userInfo: action.payload.userInfo,
        //   accessToken: action.payload.accessToken
      })
    case 'USER_LOGOUT':
      return { isValidUser: action.payload.isValidUser, userInfo: null }
    default:
      return state
  }
}
