import 'react-app-polyfill/ie11'; 
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './redux/storeConfig/store';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './public/css/scss/style.scss';
import './public/css/font.css';
import App from './App';
import 'font-awesome/scss/font-awesome.scss';
import 'react-datepicker/dist/react-datepicker.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={store.__PERSISTOR} loading={null}>
      <App />
    </PersistGate>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
