import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Table } from 'reactstrap'
import TableContext from './TableContext'
import classnames from 'classnames'
import { Th, Tr } from './tableStyle'
import useWindowDimensions from './hooks/windowDimensions'

const Tables = (props) => {

  const initialRecords = { columns: [], rows: [] }
  const context = useContext(TableContext)
  const [tableData, setTableData] = useState(initialRecords)
  const [sortField, setSortField] = useState(null)
  const [sortType, setSortType] = useState(null)
  const { width } = useWindowDimensions()

  const setTable = useCallback(() => {
    let data = { ...context.data }
    // let startPage =
    //   parseInt(context.showEntry) * (parseInt(context.paginationActive) - 1)
    // let endPage =
    //   startPage + parseInt(context.showEntry) + context.extended.length
    // if (data.rows.length > 0) data.rows = data.rows.slice(startPage, endPage)
    if (width < 767) {
      data.columns = data.columns.filter((item) => {
        return !item.mobileHide
      })
    }
    setSortField(context.sort.field)
    setSortType(context.sort.type)
    setTableData(data)
  }, [context, width])

  useEffect(() => setTable(), [setTable])

  const sorting = (field) => {
    let type = sortType === 'asc' ? 'desc' : 'asc'
    context.sortClick(type, field)
  }

  return (
    <Table className="mb-0 f-14">
      {width > 767 && (
        <thead>
          <tr className="no-hover">
            {tableData.columns.map((item, index) => {
              return (
                <Th
                  key={index}
                  theme={context.theme}
                  className={classnames({
                    sort: item.sort,
                    sort_asc: sortField === item.field && sortType === 'asc',
                    sort_desc: sortField === item.field && sortType === 'desc',
                  })}
                  style={{
                    width: `${item.width}`,
                    height: `${item.height}`,
                    textAlign: `${item.align}`,
                  }}
                  onClick={() => sorting(item.field)}
                >
                  {item.label}
                </Th>
              )
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {tableData.rows && tableData.rows.length > 0 ? tableData.rows.map((item, trIndex) => {
          if (item.expanded) {
            return (
              <Tr key={trIndex} theme={context.theme} className="no-hover">
                <td colSpan={tableData.columns.length}>
                  {item.customRow(item)}
                </td>
              </Tr>
            )
          }

          return (
            <Tr
              key={trIndex}
              theme={context.theme}
              className={classnames({ extended: item.collapsable })}
            >
              {tableData.columns.map((tdItem, tdIndex) => {
                return (
                  <td
                    key={tdIndex}
                    className={tdItem.class}
                    style={{
                      textAlign: `${tdItem.align}`,
                      height: `${tdItem.height}`,
                    }}
                  >
                    {tdItem.customRow
                      ? tdItem.customRow(item, trIndex, tdIndex)
                      : item[tdItem.field]}
                  </td>
                )
              })}
            </Tr>
          )
        }) : <Tr><td colSpan={tableData.columns.length} style={{textAlign: 'center'}}>No data found!</td></Tr> }
      </tbody>
    </Table>
  )
}
export default Tables
