import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DatePicker from 'react-datepicker'
import ReactSelect from 'react-select'

import general from '../../redux/actions/general'

import errorRed from '../../public/images/error-red.svg'

import { useDispatch, useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep';
import { isAdminRole } from '../../AutominerUtility'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

const Index = (props) => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState(cloneDeep(props.filterData))
  const [tempSearchValue, setTempSearchValue] = useState(cloneDeep(props.filterData))

  const [searchTages, setSearchTags] = useState(cloneDeep(props.searchTag))
  const [tempSearchTages, setTempSearchTags] = useState(cloneDeep(props.searchTag))

  const [isValidDate, setValidDate] = useState(true)

  const { filterData } = useSelector((state) => state.general.Filter)

  const [companyList] = useState(filterData.companies || [])
  const [millList, setMillList] = useState(null)
  const [machineList, setMachineList] = useState(null)
  const [userList] = useState(filterData.users || [])
  const [errorMessage, setErrorMessage] = useState('')

  const [validFromDate, setValidFromDate] = useState(true)
  const [validToDate, setValidToDate] = useState(true)
  const [validFromTime, setValidFromTime] = useState(true)
  const [validToTime, setValidToTime] = useState(true)
  const { userInfo } = useSelector((state) => state.auth.login)

  let handelSearchtagChange = (type, value, isChecked, option) => {
    if (type === 'keyword') {
      setTempSearchTags(tempSearchTages.filter((item) => item.key !== type))
      setTempSearchTags((oldArray) => [
        ...oldArray,
        { key: type, value: 'Keyword: ' + value },
      ])
    } else if (['fromDate', 'fromTime', 'toDate', 'toTime'].includes(type)) {
      if (type === 'toTime' || type === 'fromTime') {
        value = dayjs(value).format('hh:mm a')
      }
      let fromDate = type === 'fromDate' ? value : tempSearchValue.fromDate
      let toDate = type === 'toDate' ? value : tempSearchValue.toDate
      let fromTime =
        type === 'fromTime'
          ? value
          : tempSearchValue.fromTime !== ''
          ? dayjs(tempSearchValue.fromTime).format('hh:mm a')
          : ''
      let toTime =
        type === 'toTime'
          ? value
          : tempSearchValue.toTime !== ''
          ? dayjs(tempSearchValue.toTime).format('hh:mm a')
          : ''

      let tagString = ''
      tagString += fromDate !== '' ? dayjs(fromDate).format('MMM Do YYYY') : ''
      tagString += fromTime ? ' @ ' + fromTime : ''
      tagString +=
        toDate !== '' ? ' - ' + dayjs(toDate).format('MMM Do YY') : ''
      tagString += toTime ? ' @ ' + toTime : ''

      setTempSearchTags(tempSearchTages.filter((item) => item.key !== 'dateTime'))
      setTempSearchTags((oldArray) => [
        ...oldArray,
        { key: 'dateTime', value: 'Date Range: ' + tagString },
      ])
    } else if (type === 'user') {
      props.setJobType('allJob')
      setTempSearchTags(
        tempSearchTages.filter((item) => {
          return (![type, 'Specific User(s)'].includes(item.key))
        })
      )
      if (value === 'Only Me') {
        props.setJobType('myJob')
        setTempSearchTags((oldArray) => [
          ...oldArray,
          { key: type, value: 'Users: ' + value },
        ]) 
      }
    } else if (type === 'Specific User(s)') {
      props.setJobType('allJob')
      setTempSearchTags(tempSearchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setTempSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Users: ' + val.label, userId: val.value },
          ])
        })
      }
      
      
    } else if (type === 'company') {
      setTempSearchTags(tempSearchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setTempSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Company: ' + val.value, label: val.value },
          ])
        })

        let compArray = value.map((vl) => { return vl.value})
        dispatch(general.getMillFromSelectedCompany(compArray)).then((result) => {
          if(result) {
            setMillList(result)
          }
        })
      }
    } else if (type === 'mill') {
      setTempSearchTags(tempSearchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setTempSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Mill: ' + val.value, label: val.value },
          ])
        })
        
        let millArray = value.map((vl) => { return vl.value})
        dispatch(general.getMachineFromSelectedMills(millArray)).then((result) => {
          if(result) {
            setMachineList(result)
          }
        })
      }
      
    } else if (type === 'machine') {
      setTempSearchTags(tempSearchTages.filter((item) => item.key !== type))
      if (value) {
        value.forEach((val) => {
          setTempSearchTags((oldArray) => [
            ...oldArray,
            { key: type, value: 'Machine: ' + val.value, label: val.value },
          ])
        })
      }
    } else if (type === 'status' && value !== 'Any') {
      if (isChecked) {
        setTempSearchTags((oldArray) => [
          ...oldArray,
          { key: value, value: 'Status: ' + value, statusId: option.value },
        ])
      } else {
        setTempSearchTags(tempSearchTages.filter((item) => item.key !== value))
      }
    } else if (type === 'status' && value === 'Any') {
      setTempSearchTags(
        tempSearchTages.filter((item) => {
          return (!item.key === 'Any')
        })
      )
    }
  }

  useEffect(() => {
  }, [tempSearchTages])

  useEffect(() => {
    setTempSearchValue(cloneDeep(props.filterData));
  }, [props.filterData])

  useEffect(() => {
    setTempSearchTags(props.searchTag);
  }, [props.searchTag])

  useEffect(() => {
  }, [tempSearchValue.specificUsers])
  
  let handelUpdateTableClick = () => {
    let hadError = false;
    if (tempSearchValue.fromDate > new Date() || tempSearchValue.toDate > new Date()) {
      setErrorMessage('Future date in not allowed.')
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate !== '' &&
      tempSearchValue.toDate !== '' &&
      tempSearchValue.fromDate > tempSearchValue.toDate
    ) {
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(false)
      setErrorMessage('From Date cannot later than To Date.')
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate !== '' &&
      tempSearchValue.toDate !== '' &&
      (tempSearchValue.toTime === '' || tempSearchValue.fromTime === '')
    ) {
      setErrorMessage('Enter Time.')
      tempSearchValue.toTime === '' ? setValidToTime(false) : setValidToTime(true)
      tempSearchValue.fromTime === ''
        ? setValidFromTime(false)
        : setValidFromTime(true)
      setValidFromDate(true)
      setValidToDate(true)
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromTime !== '' &&
      tempSearchValue.toTime !== '' &&
      (tempSearchValue.fromDate === '' || tempSearchValue.toDate === '')
    ) {
      setErrorMessage('Enter Date.')
      tempSearchValue.fromDate === ''
        ? setValidFromDate(false)
        : setValidFromDate(true)
        tempSearchValue.toDate === '' ? setValidToDate(false) : setValidToDate(true)
      setValidFromTime(true)
      setValidToTime(true)
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate !== '' &&
      tempSearchValue.toDate === '' &&
      tempSearchValue.fromTime === '' &&
      tempSearchValue.toTime === ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(true)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate === '' &&
      tempSearchValue.toDate !== '' &&
      tempSearchValue.fromTime === '' &&
      tempSearchValue.toTime === ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(false)
      setValidToDate(true)
      setValidFromTime(false)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate === '' &&
      tempSearchValue.toDate === '' &&
      tempSearchValue.fromTime !== '' &&
      tempSearchValue.toTime === ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(true)
      setValidToTime(false)
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate === '' &&
      tempSearchValue.toDate === '' &&
      tempSearchValue.fromTime === '' &&
      tempSearchValue.toTime !== ''
    ) {
      setErrorMessage('Enter valid date-time.')
      setValidFromDate(false)
      setValidToDate(false)
      setValidFromTime(false)
      setValidToTime(true)
      setErrorMessage('Enter valid date-time.')
      setValidDate(false)
      hadError = true;
    } else if (
      tempSearchValue.fromDate !== '' ||
      tempSearchValue.toDate !== '' ||
      tempSearchValue.fromTime !== '' ||
      tempSearchValue.toTime !== ''
    ) {
      if (tempSearchValue.fromDate === '') setValidFromDate(false)
      else setValidFromDate(true)

      if (tempSearchValue.toDate === '') setValidToDate(false)
      else setValidToDate(true)

      if (tempSearchValue.fromTime === '') setValidFromTime(false)
      else setValidFromTime(true)

      if (tempSearchValue.toTime === '') setValidToTime(false)
      else setValidToTime(true)

      setErrorMessage('Enter valid date-time')
      setValidDate(false)

      if (
        tempSearchValue.fromDate !== '' &&
        tempSearchValue.toDate !== '' &&
        tempSearchValue.fromTime !== '' &&
        tempSearchValue.toTime !== '' &&
        tempSearchValue.fromDate <= new Date() &&
        tempSearchValue.toDate <= new Date() &&
        tempSearchValue.fromDate <= tempSearchValue.toDate
      ) {
        hadError = false
        setValidFromDate(true)
        setValidToDate(true)
        setValidFromTime(true)
        setValidToTime(true)
        setErrorMessage('')
        setValidDate(true)
      }
    } else {
      hadError = false
      setValidFromDate(true)
      setValidToDate(true)
      setValidFromTime(true)
      setValidToTime(true)
      setErrorMessage('')
      setValidDate(true)
    }

    if (hadError === false) {
      setTempSearchValue({
        ...tempSearchValue
      });
      setSearchValue({
        ...searchValue
      });
      setSearchValue(cloneDeep(tempSearchValue))
      setSearchTags(cloneDeep(tempSearchTages))
      props.appyFilterData(tempSearchValue, props.tableType, tempSearchTages)
      props.toggle();
      
    }
  }

  const cancelSelection = () => {
    setTempSearchValue(cloneDeep(searchValue))
    setTempSearchTags(cloneDeep(searchTages))
    props.cancelFilter(searchValue)
    props.toggle()
  }

  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-xl filter-modal"
        isOpen={props.modal}
        toggle={props.toggle}
      >
        <ModalHeader
          toggle={() => {
            props.toggle()
          }}
        >
          Filter table
        </ModalHeader>
        <ModalBody className="filter-table-modal">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="form-label-group pr-lg-5">
                <label>Date range</label>
                <div className="from-to">
                  <div className="from-row form-label-group">
                    <span>From</span>
                    <div
                      className={
                        validFromDate
                          ? 'select-custom select-sm select-grey'
                          : 'select-custom select-sm select-grey error-invalid'
                      }
                    >
                      <DatePicker
                          className="form-control"
                          id="selFromDate"
                          selected={tempSearchValue.fromDate}
                          onChange={(date) => {
                            setTempSearchValue({
                              ...tempSearchValue,
                              fromDate: date,
                            })
                            handelSearchtagChange('fromDate', date)
                          }}
                          placeholderText="Date"
                        />
                    </div>
                    <div
                      className={
                        validFromTime
                          ? 'select-custom select-sm to-range select-grey'
                          : 'select-custom select-sm to-range select-grey error-invalid'
                      }
                    >
                      <DatePicker
                          selected={tempSearchValue.fromTime}
                          placeholderText="Time"
                          value={tempSearchValue.fromTime || ''}
                          className="form-control"
                          onChange={(e) => {
                            setTempSearchValue({
                              ...tempSearchValue,
                              fromTime: e,
                            })
                            handelSearchtagChange('fromTime', e)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                    </div>
                  </div>
                  <div className="from-row form-label-group">
                    <span>To</span>
                    <div
                      className={
                        validToDate
                          ? 'select-custom select-sm select-grey'
                          : 'select-custom select-sm select-grey error-invalid'
                      }
                    >
                      <DatePicker
                          className="form-control"
                          id="selToDate"
                          selected={tempSearchValue.toDate}
                          onChange={(date) => {
                            setTempSearchValue({
                              ...tempSearchValue,
                              toDate: date,
                            })
                            handelSearchtagChange('toDate', date)
                          }}
                          placeholderText="Date"
                        />
                    </div>
                    <div
                      className={
                        validToTime
                          ? 'select-custom select-sm to-range select-grey'
                          : 'select-custom select-sm to-range select-grey error-invalid'
                      }
                    >
                      <DatePicker
                          selected={tempSearchValue.toTime}
                          placeholderText="Time"
                          value={tempSearchValue.toTime || ''}
                          className="form-control"
                          onChange={(e) => {
                            setTempSearchValue({
                              ...tempSearchValue,
                              toTime: e,
                            })
                            handelSearchtagChange('toTime', e)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isValidDate ? 'invalid-feedback' : 'invalid-feedback d-flex'
                  }
                >
                  <img alt="" src={errorRed} /> <span>{errorMessage}</span>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className={'form-label-group' +' ' + (isAdminRole(userInfo['role_name']) ? '' : 'hide')} id="radioUsers">
                <div className="user-select justify-content-between align-items-end">
                  <span
                    className="filter-table-user"
                    value={tempSearchValue.user}
                  >
                    <label>user</label>
                    {filterData && filterData.userOptions && filterData.userOptions.length > 0 && filterData.userOptions.map((options, index) => (
                      <div key={'optinKey-'+index} className="radiobox d-block mb-2">
                        <input
                          type="radio"
                          name="radio"
                          className="form-check-input"
                          value={options.label}
                          defaultChecked={tempSearchValue.user === options.label ? true : false}
                          onChange={(e) => {
                            handelSearchtagChange('user', e.target.value, '', options)
                            if (e.target.value === 'Everyone') {
                              setTempSearchValue({
                                ...tempSearchValue,
                                specificUsers: [],
                                user: e.target.value,
                                selectedUsers: []
                              })
                            } else if( e.target.value === 'Only Me') {
                              setTempSearchValue({
                                ...tempSearchValue,
                                specificUsers: [],
                                user: e.target.value,
                                selectedUsers: options.value
                              })
                            } else {
                              setTempSearchValue({ ...tempSearchValue, user: e.target.value })
                            }
                          }}
                        />
                        <label>{options.label}</label>
                      </div>
                    ))}
                  </span>
                  <div className="filter-table-user">
                    <div className="select-custom select-sm select-grey">
                    <ReactSelect
                      isClearable={false}
                      placeholder="Select user(s)"
                      value={tempSearchValue.specificUsers}
                      onChange={(e) => {
                        if(e) {
                          let specificUsersArray = e.map((vl) => { return vl.value})
                          setTempSearchValue({
                            ...tempSearchValue,
                            specificUsers: e || [],
                            selectedUsers: specificUsersArray
                          })
                          handelSearchtagChange('Specific User(s)', e)
                        } else {
                          setTempSearchValue({
                            ...tempSearchValue,
                            specificUsers: [],
                            selectedUsers: []
                          })
                          handelSearchtagChange('Specific User(s)', e)
                        }
                      }}
                      isDisabled={
                        tempSearchValue.user === 'Specific User(s)' ? false : true
                      }
                      classNamePrefix="form-control"
                      isMulti
                      options={userList}
                    ></ReactSelect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row filter-secound-row justify-content-between">
            <div className="col-lg-5">
              <div className="form-label-group pr-lg-5">
                <label>Company</label>
                <div className="select-custom select-sm select-grey">
                  <ReactSelect
                    isClearable={false}
                    placeholder="Select Company(ies)"
                    value={tempSearchValue.company}
                    onChange={(e) => {
                      if (!e) {
                        setTempSearchValue({
                          ...tempSearchValue,
                          company: [],
                          mill: [],
                          machine: [],
                        })
                        let tempSearchTags = tempSearchTages
                        for (let i = 0; i < tempSearchTags.length; i++) {
                          if (
                            tempSearchTags[i].key === 'machine' ||
                            tempSearchTags[i].key === 'mill'
                          ) {
                            tempSearchTags.splice(i, 1)
                          }
                        }
                        setTempSearchTags(tempSearchTags)
                      } else {
                        let selecetedCompayArray = e.map((vl) => { return vl.value})
                        setTempSearchValue({
                          ...tempSearchValue,
                          company: e || [],
                          selectedCompanies: selecetedCompayArray
                        })
                      }
                      handelSearchtagChange('company', e)
                    }}
                    classNamePrefix="form-control"
                    isMulti
                    options={companyList}
                  ></ReactSelect>
                </div>

                <label className={tempSearchValue.company.length === 0 ? 'unselect' : ''}>
                  Mill
                </label>
                <div className="select-custom select-sm select-grey">
                  <ReactSelect
                    isMulti
                    isClearable={false}
                    isDisabled={
                      tempSearchValue.company.length === 0 ? true : false
                    }
                    id="selMill"
                    options={millList}
                    className={
                      tempSearchValue.company.length === 0 ? 'unselect' : ''
                    }
                    classNamePrefix="form-control"
                    placeholder="Select Mill(s)"
                    value={tempSearchValue.mill}
                    onChange={(e) => {
                      if (!e) {
                        setTempSearchValue({
                          ...tempSearchValue,
                          mill: [],
                          machine: [],
                        })
                        let tempSearchTags = tempSearchTages
                        for (let i = 0; i < tempSearchTags.length; i++) {
                          if (tempSearchTags[i].key === 'machine') {
                            tempSearchTags.splice(i, 1)
                          }
                        }
                        setTempSearchTags(tempSearchTags)
                      } else {
                        let selecetedMillArray = e.map((vl) => { return vl.value})
                        setTempSearchValue({
                          ...tempSearchValue,
                          mill: e || [],
                          selectedMills: selecetedMillArray
                        })
                      }
                      handelSearchtagChange('mill', e)
                    }}
                  ></ReactSelect>
                </div>

                <label className={tempSearchValue.mill.length === 0 ? 'unselect' : ''}>
                  Machine
                </label>
                <div className="select-custom select-sm select-grey">
                  <ReactSelect
                    isMulti
                    isClearable={false}
                    isDisabled={
                      tempSearchValue.mill.length === 0 ? true : false
                    }
                    options={machineList}
                    id="selMachine"
                    className={
                      tempSearchValue.mill.length === 0 ? 'unselect' : ''
                    }
                    classNamePrefix="form-control"
                    placeholder="Select Machine(s)"
                    value={tempSearchValue.machine}
                    onChange={(e) => {
                      let selecetedMachineArray = e.map((vl) => { return vl.value})
                      setTempSearchValue({
                        ...tempSearchValue,
                        machine: e || [],
                        selectedMachines: selecetedMachineArray
                      })
                      handelSearchtagChange('machine', e)
                    }}
                  ></ReactSelect>
                </div>
              </div>
            </div>

            {props.tableType === 'inProgress' ? <div className="col-lg-6">
              <div className="form-label-group">
                <label>Status</label>
                {filterData && filterData.statusOptions && filterData.statusOptions.length > 0 && filterData.statusOptions.map((statusOptions, index) => (
                  <div key={'statusOption-'+index} className="checkbox d-block mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={statusOptions.label}
                      id={'chk'+statusOptions.label}
                      checked={tempSearchValue.status.indexOf(statusOptions.label) > -1 ? true : false}
                      onChange={(e) => {
                        let status = tempSearchValue.status
                        let selectedStatusOption = tempSearchValue.selectedStatusOption

                        if (tempSearchValue.status.indexOf('Any') !== -1) {
                          tempSearchValue.status.splice(
                            tempSearchValue.status.indexOf('Any'),
                            1
                          )
                        }

                        if(statusOptions.label !== 'Any') {
                          if(tempSearchValue.status.indexOf(statusOptions.label) === -1 ) {
                            status.push(statusOptions.label)
                            selectedStatusOption.push(statusOptions.value)
                          } else {
                            status.splice(status.indexOf(statusOptions.label), 1)
                            selectedStatusOption.splice(selectedStatusOption.indexOf(statusOptions.value), 1)
                          }
                        } else {
                          status = ['Any']
                          selectedStatusOption = [];
                        }
                        
                        setTempSearchValue({
                          ...tempSearchValue,
                          status: status,
                          selectedStatusOption: selectedStatusOption
                        })
                        handelSearchtagChange(
                          'status',
                          e.target.value,
                          e.target.checked,
                          statusOptions
                        )
                      }}
                    />
                  <label>{statusOptions.label}</label>
                </div>
                ))}
              </div>
            </div> : ''}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row align-items-center">
            <div className="col-12 d-flex justify-content-end text-right">
              <Button
                className="dark-shadow"
                type="button"
                color="primary"
                onClick={() => {
                  cancelSelection()
                }}
              >
                cancel <i className="icon-cancel"></i>
              </Button>{' '}
              <Button
                type="button"
                className="active ml-3 dark-shadow"
                color="secondary"
                onClick={() => {
                  handelUpdateTableClick()
                }}
              >
                Update table <i className="icon-apply"></i>
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Index
