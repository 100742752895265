import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ShareJobModal from '../../components/ShareJobModal'

import jobsActions from '../../redux/actions/jobs'

let Index = (props) => {
  const [shareJobModal, setShareJobModal] = useState(false)
  const shareJobModalToggle = () => setShareJobModal(!shareJobModal)

  const [company, setCompany] = useState(props.jobDetails.company)
  const [mill, setMill] = useState(props.jobDetails.mill)
  const [machine, setMachine] = useState(props.jobDetails.machine)
  const [notes, setNotes] = useState(props.jobDetails.notes || '')

  const [editCompany, setEditCompany] = useState(false)
  const [editMill, setEditMill] = useState(false)
  const [editMachine, setEditMachine] = useState(false)
  const [editNotes, setEditNotes] = useState(false)

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [jobTitle, setJobTitle] = useState('')
  const [jobDetails, setJobDetails] = useState('')
  const {userInfo} = useSelector((state) => state.auth.login)

  let handelShareJob = (jobTitle, jobDetails) => {
    return;
    setJobDetails(jobDetails)
    setJobTitle(jobTitle)
    shareJobModalToggle()
  }

  let stopJobConfirmation = () => {
    setConfirmationModalOpen(true)
  }

  const dispatch = useDispatch()

  let handelStopJob = () => {
    dispatch(jobsActions.stopJobProgress({'jobId': JSON.stringify(props.jobDetails.id), 'userName': userInfo.user_name})).then((result) => {
     if(result.status) {
       setConfirmationModalOpen(false)
     }
    })
  }

  let submitEditDetails = () => {
    const updateDetails = {
      'jobId': JSON.stringify(props.jobDetails.id),
      'company': company,
      'mill': mill,
      'machine': machine,
      'notes': notes
    }
    dispatch(jobsActions.updateJobDetails(updateDetails)).then((result) => {
      if(result.success) {
        dispatch(jobsActions.getJobsDetails({'jobId': props.jobDetails.id}))
        setEditCompany(false)
        setEditMill(false)
        setEditMachine(false)
        setEditNotes(false)
      }
     })
  }

  useEffect(() => {
    setCompany(props.jobDetails.company)
    setMill(props.jobDetails.mill)
    setMachine(props.jobDetails.machine)
    setNotes(props.jobDetails.notes)
  },[props.jobDetails])


  let handelDowloadPDF = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'True', fileName: ''})).then((result) => {
      window.open(result.file); 
    })
  }

  let handelInProgressDowloadPDF = (details) => {
    dispatch(jobsActions.downloadInProgressFile({ jobId: details.id})).then((result) => {
      window.open(result.file); 
    })
  }

  let handelDowloadFile = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'False', fileName: details.s3_file_path })).then((result) => {
      window.open(result.file);
    })
  }

  return (
    <div className="card job-process-bar mt-4">
      <div className="row justify-content-between mb-5">
        <div className="col-lg-6">
          <h2>{props.jobDetails.job_name}</h2>
        </div>
        { !props.canEdit ?  <div className="col-lg-5 text-right">
          <ul className="job-top-link">
            <li>
              <a className="disabled"  onClick={() => handelShareJob(props.jobDetails.job_name, props.jobDetails)}>
                Email <i className="icon-email"></i>
              </a>
            </li>
            <li>
              <Link onClick={() => handelDowloadPDF(props.jobDetails)}>
                Download <i className="icon-download"></i>
              </Link>
            </li>
          </ul>
        </div> :  <div className="col-lg-5 text-right">
          <ul className="job-top-link">
            <li>
              <Link className="disabled"  onClick={() => handelShareJob(props.jobDetails.job_name, props.jobDetails)}>
                Email <i className="icon-email"></i>
              </Link>
            </li>
            <li>
              <Link onClick={() => handelInProgressDowloadPDF(props.jobDetails)}>
                Download <i className="icon-download"></i>
              </Link>
            </li>
          </ul>
        </div>
        }
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div className="process-circle">
            {/* <CircularProgressbarWithChildren value={props.jobDetails.statusPercent}> */}
            <CircularProgressbarWithChildren
              value={props.jobDetails.percentage}
            >
              <div>
                {/* <span className="percentage">{props.jobDetails.statusPercent}%</span> */}
                {props.jobDetails.percentage === 100 ? (
                  <span className="percentage">
                    <i className="icon-completed"></i>
                  </span>
                ) : (
                  <span className="percentage">
                    {props.jobDetails.percentage}%
                  </span>
                )}
                <span className="text mb-4">{props.jobDetails.status_name}</span>
              </div>
              {props.jobDetails.status_name === 'Stopped' ? (
                <div className="icon-btn text-danger">
                <i className="fa fa-stop"></i>
              </div>
              ) : props.jobDetails.status_name === 'Completed' ? '' : (
                <div className="icon-btn" onClick={stopJobConfirmation}>
                  <i className="fa fa-stop"></i>
                </div>
              )}
            </CircularProgressbarWithChildren>
          </div>
        </div>
        <div className="col-lg-3">
          <ul className="process-list-info">
            <li>
              <span>job ID</span>{' '}
              <span className="data">
                <strong>{props.jobDetails.id}</strong>
              </span>
            </li>
            <li>
              <span>user</span>{' '}
              <span className="data active">
                <strong>{props.jobDetails.user_name}</strong>
              </span>
            </li>
            <li>
              <span>date</span>{' '}
              <span className="data">{props.jobDetails.submission_time}</span>
            </li>
            <li>
              <span>Company</span>{' '}
              <span className="data">
                {/* {company}{' '} */}
                {!editCompany ? (
                  company
                ) : (
                  <input
                    type="text"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value)
                    }}
                  ></input>
                )}
                {props.canEdit ? (
                  <i
                    className="edit fa fa-pencil"
                    onClick={() => {
                      setEditCompany(true)
                    }}
                  ></i>
                ) : (
                  ''
                )}
              </span>
            </li>
            <li>
              <span>Mill</span>{' '}
              <span className="data">
                {/* {props.jobDetails.mill}{' '} */}
                {!editMill ? (
                  mill
                ) : (
                  <input
                    type="text"
                    value={mill}
                    onChange={(e) => {
                      setMill(e.target.value)
                    }}
                  ></input>
                )}
                {props.canEdit ? (
                  <i
                    className="edit fa fa-pencil"
                    onClick={() => {
                      setEditMill(true)
                    }}
                  ></i>
                ) : (
                  ''
                )}
              </span>
            </li>
            <li>
              <span>machine</span>{' '}
              <span className="data">
                {/* {props.jobDetails.machine}{' '} */}
                {!editMachine ? (
                  machine
                ) : (
                  <input
                    type="text"
                    value={machine}
                    onChange={(e) => {
                      setMachine(e.target.value)
                    }}
                  ></input>
                )}
                {props.canEdit ? (
                  <i
                    className="edit fa fa-pencil"
                    onClick={() => {
                      setEditMachine(true)
                    }}
                  ></i>
                ) : (
                  ''
                )}
              </span>
            </li>
          </ul>
        </div>
        <div className="col-lg-5">
          <h5>file</h5>
          <div className="file-attch-link mb-5">
            <a
              onClick={() => handelDowloadFile(props.jobDetails)}
            >
              <i className="icon-source-file"></i>{props.jobDetails.file_name}
            </a>
          </div>
          <div className="notes">
          {props.canEdit ? (
                  <i
                  className="edit fa fa-pencil"  onClick={() => {
                    setEditNotes(true)
                  }}
                  ></i>
                ) : (
                  ''
                )}
            {/* <i className="edit fa fa-pencil"  onClick={() => {
                setEditNotes(true)
              }}></i> */}
            <h5>notes</h5>
            {!editNotes ? (
                  <p>{notes}</p>
                ) : (
                  <textarea 
                    className="text-area-edit"
                    rows="8"
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                  ></textarea >
                )}
                
            {/* <p>{props.jobDetails.notes}</p> */}
          </div>
        </div>
        {editCompany || editMachine || editMill || editNotes ? 
        <div className="col-12 text-right">
          <button type="button" id="btnSubmitJob" className="btn active btn-lg" onClick={() => submitEditDetails()}>
            Save
          </button>
        </div> : ''}
      </div>

      <ShareJobModal
        modal={shareJobModal}
        toggle={shareJobModalToggle}
        jobTitle={jobTitle}
        jobDetails={jobDetails}
      ></ShareJobModal>


      <Modal
        className="modal-dialog-centered modal-lg success-modal"
        isOpen={isConfirmationModalOpen}
        onClick={() => setConfirmationModalOpen(!isConfirmationModalOpen)}
      >
        <ModalHeader toggle={() => setConfirmationModalOpen(!isConfirmationModalOpen)}></ModalHeader>
        <ModalBody>
          {/* <h2>Success!</h2> */}
          <p>Are you sure you want to stop job {jobTitle}?</p>
          <span>Once Job is stopped you can't start this job again.</span>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            type="button"
            color="primary"
            id="btnCancelSendEmail"
            onClick={() => setConfirmationModalOpen(false)}
          >
            No <i className="icon-cancel"></i>
          </Button>
          <Button
            type="button"
            className="ml-3 disabled"
            id="btnSendEmail"
            color="secondary"
            disabled
            onClick={handelStopJob}
          >
            Yes <i className="icon-apply"></i>
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  )
}

export default Index
