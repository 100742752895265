import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import "./MultiGradeSelector.scss";

const MultiSelectDropdown = ({ options, selectedGrade, onSelectedGrade, gradeList, optionsUpdate }) => {
    const [dropdownOption, setDropdownOption] = useState([]);
  useEffect(()=>{
    let gradeOption = optionsUpdate == false ? options : options.filter(item => !gradeList.find(listItem => listItem.predictionGrades.find(listVal => listVal === item.value  ))) ;
    setDropdownOption(gradeOption);
  },[gradeList]);

  const onChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      default:
        break;
    }
    onSelectedGrade(value);
  };

  return (
    <>
      <div className="multi-grade-wrap">
        <div className="select-custom">
          <ReactSelect
            isMulti
            isClearable={false}
            id="selTargetGrade"
            classNamePrefix="s"
            onChange={onChange}
            options={dropdownOption}
            placeholder="Select Master Grade"
            value={selectedGrade}
          ></ReactSelect>
        </div>
      </div>
    </>
  );
};

export default MultiSelectDropdown;

