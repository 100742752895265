import React, { useEffect } from 'react'
import './Footer.scss'
import { useSelector, useDispatch } from 'react-redux'
import general from '../../redux/actions/general'

let Footer = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(general.getFooterData())
  }, [dispatch])

  const { footerData } = useSelector((state) => state.general.Footer)

  return (
    <footer>
      <div className="footer-copyright text-center py-3">
        <div className="container">
          <p>
            {footerData.copyrightText} 
            {/* &copy; {new Date().getFullYear()}
            <a title="Processminer" to="#!"> Processminer </a> all rights reserved */}
          </p>
          <p>{footerData.version}</p>
          
        </div>
      </div>
    </footer>
  )
}

export default Footer
