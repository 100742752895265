import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
} from 'reactstrap'
import './login.scss'
import logo from '../../public/images/logo.png'
import { useHistory, Link } from 'react-router-dom'

import loginAction from '../../redux/actions/auth'
import errorwhite from '../../public/images/error-white.svg'

const validEmailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/

let Index = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [validPassword, setValidPassword] = useState(true)
  const [errorMessageVisible, setErrorMessageVisible] = useState(false)

  let history = useHistory()
  const dispatch = useDispatch()

  // const { isValidUser } = useSelector((state) => state.auth.login)

  // if (isValidUser) {
  //   debugger;
  //   history.push('/dashboard')
  // }

  const handleSubmit = (event) => {
    event.preventDefault()
    let isError = false
    if (email.length > 0 && validEmailRegex.test(email)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
      isError = true
    }
    if (password.length > 5) {
      setValidPassword(true)
    } else {
      setValidPassword(false)
      isError = true
    }

    if (!isError && validEmail && validPassword) {
      dispatch(
        loginAction.login({ username: email, encryptedPassword: password })
      ).then((result) => {
        if (result.status) {
          history.push('/dashboard')
        } else {
          
          setErrorMessageVisible(true)
        }
      })
    }
  }

  return (
    <div className="login-screen">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-lg-6 col-sm-10">
            <div className="login-logo">
              <Link to="#!">
                <img alt="" src={logo} />
              </Link>
            </div>
            <Form className="form">
            {errorMessageVisible ? <div className="error-login"><img alt="" src={errorwhite} />{' '} <span>Incorrect username and password</span></div> : ''}
              <FormGroup>
                <Label>Username or email *</Label>
                <Input
                  className="m-0"
                  type="email"
                  value={email}
				  maxLength="50"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div
                  className={
                    validEmail
                      ? 'invalid-feedback'
                      : 'invalid-feedback d-block text-white'
                  }
                >
                  <img alt="" src={errorwhite} />{' '}
                  <span>Please enter a valid email.</span>
                </div>
              </FormGroup>

              <FormGroup>
                <Label for="examplePassword">Password *</Label>
                <Input
                  className="m-0"
                  type="password"
				  maxLength="50"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div className="password-error">
                  <div
                    className={
                      validPassword
                        ? 'invalid-feedback'
                        : 'invalid-feedback d-block text-white'
                    }
                  >
                    <img alt="" src={errorwhite} />{' '}
                    <span>Please enter a correct password.</span>
                  </div>
                  <div className="forgot-password">
                    <Link to="#!">forgot password?</Link>
                  </div>
                </div>
              </FormGroup>

              <Button type="submit" onClick={handleSubmit}>
                log in
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Index
