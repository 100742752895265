const initialState = {
  densityData: null,
  maxCorrPlotData: null,
  heatmapPlotData: null,
  isPredictionVSActualData: {
    predictionVsActualData: [],
    lastTimeStamp: ''
  },
  isPredictionVSActualNewData: {
    predictionVsActualNewData: [],
    newLastTimeStamp: ''
  },
  isErrorReductionPlotData: {
    errorReductionPlotData: [],
    lastTimeStamp: ''
  },
  frequencyChartData: {
    frequencyList: [],
    lastTimeStamp: ''
  },
  temporalChartData: {
    temporalList: [],
    lastTimeStamp: ''
  },
  explorationTableData : {
    explorationData: [],
    lastTagId: ''
  }
}
  export const completedJobsGraphData = (state = initialState, action) => {
    
    switch (action.type) {
      case 'COPLETED_JOB_DENSITY_DATA':
        return Object.assign({}, state, {
            densityData: action.payload.densityPlotData
          })
      case 'COPLETED_JOB_MAX_CORR_DATA':
        return Object.assign({}, state, {
            maxCorrPlotData: action.payload.maxCorrPlotData
          })
      case 'COPLETED_JOB_HEAT_MAP_DATA':
        return Object.assign({}, state, {
          heatmapPlotData: action.payload.heatmapPlotData
          })
      case 'COPLETED_JOB_PREDICTION_AND_ACTUAL_DATA':
        return Object.assign({}, state, {
          isPredictionVSActualData: {
            predictionVsActualData: action.payload.isPredictionVSActualData.predictionVsActualData,
            lastTimeStamp: action.payload.isPredictionVSActualData.lastTimeStamp
          }
        })
      case 'COPLETED_JOB_PREDICTION_AND_ACTUAL_EVERYMIN_DATA':
        let timeStamp = action.payload.newIsPredictionVSActualData.lastTimeStamp;
        let data = [...state.isPredictionVSActualData.predictionVsActualData, ...action.payload.newIsPredictionVSActualData.predictionVsActualData];
        return {
          ...state, 
          
          isPredictionVSActualData: {
            predictionVsActualData: data,
            lastTimeStamp: timeStamp
          }
        
        }
        case 'COPLETED_JOB_ERROR_REDUCTION_PLOT_DATA':
          return Object.assign({}, state, {
            isErrorReductionPlotData: {
              errorReductionPlotData: action.payload.isErrorReductionPlotData.errorReductionPlotData,
              lastTimeStamp: action.payload.isErrorReductionPlotData.lastTimeStamp
            }
          })
        case 'COPLETED_JOB_ERROR_REDUCTION_PLOT_EVERYMIN_DATA':
          let errorTimeStamp = action.payload.newIsErrorReductionPlotData.lastTimeStamp;
          let errordata = [...state.isErrorReductionPlotData.errorReductionPlotData, ...action.payload.newIsErrorReductionPlotData.errorReductionPlotData];
          return {
            ...state, 
            
            isErrorReductionPlotData: {
              errorReductionPlotData: errordata,
              lastTimeStamp: errorTimeStamp
            }
          
          }
      case 'COPLETED_JOB_EXPLORATION_TABLE_DATA':
        return Object.assign({}, state, {
          explorationTableData: {
            explorationData: action.payload.explorationTableData.explorationData,
            lastTagId: action.payload.explorationTableData.lastTagId,
            explorationTableSize: action.payload.explorationTableData.explorationTableSize
          }
        })
      case 'COPLETED_JOB_EXPLORATION_TABLE_DATA_NEXT_FIVE':
        return Object.assign({}, state, {
          explorationTableData: {
            explorationData: [...state.explorationTableData.explorationData, ...action.payload.nextFiveExplorationTableData.explorationData],
            lastTagId: action.payload.nextFiveExplorationTableData.lastTagId,
            explorationTableSize: action.payload.nextFiveExplorationTableData.explorationTableSize
          }
       })
      case 'COPLETED_JOB_FREQUENCY_DATA':
        return Object.assign({}, state, {
          frequencyChartData: {
            frequencyList: action.payload.frequencyChartData.frequencyList,
            lastTimeStamp: action.payload.frequencyChartData.lastTimeStamp
          }
        })
      case 'COPLETED_JOB_FREQUENCY_EVERYMIN_DATA':
        let frequencyTimeStamp = action.payload.newFrequencyChartData.lastTimeStamp;
        let frequencyData = [...state.frequencyChartData.frequencyList, ...action.payload.newFrequencyChartData.frequencyList];
        return Object.assign({}, state, {
          frequencyChartData: {
            frequencyList: frequencyData,
            lastTimeStamp: frequencyTimeStamp
          }
        })
      case 'COPLETED_JOB_TEMPORAL_DATA':
        return Object.assign({}, state, {
          temporalChartData: {
            temporalList: action.payload.temporalChartData.temporalList,
            lastTimeStamp: action.payload.temporalChartData.lastTimeStamp
          }
        })
      case 'COPLETED_JOB_TEMPORAL_EVERYMIN_DATA':
        let temporalData = [...state.temporalChartData.temporalList, ...action.payload.newTemporalChartData.temporalList];
        let temporalTimestamp = action.payload.newTemporalChartData.lastTimeStamp
        return Object.assign({}, state, {
          temporalChartData: {
            temporalList: temporalData,
            lastTimeStamp: temporalTimestamp
          }
        })
      default:
        return state
    }
  }