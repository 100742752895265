import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import general from '../../redux/actions/general'

import './Header.scss'
import logo from '../../public/images/autominer-logo-white.svg'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Nav,
  Collapse,
} from 'reactstrap'

import { useLocation, Link, useHistory } from 'react-router-dom'
import loginAction from '../../redux/actions/auth'

let Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [navbar, setNavbar] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const toggleNavbar = () => setNavbar((prevState) => !prevState)

  const location = useLocation()
  const currentPath = location.pathname

  const dispatch = useDispatch()

  let history = useHistory()

  const { headerData } = useSelector((state) => state.general.Header)
  const { userInfo } = useSelector((state) => state.auth.login)

  useEffect(() => {
    // if (headerData && headerData.menu && headerData.menu.length === 0) {
      dispatch(general.getHeaderData())
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

 

  let handelLogout = () => {
    dispatch(loginAction.logout()).then((result) => {
      if (result) {
        history.push('/')
      }
    })
  }

  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand" to="/dashboard">
            <img alt="Auto Miner" src={logo} />
          </Link>
          <NavbarToggler
            onClick={toggleNavbar}
            type="button"
            className={navbar ? 'active' : ''}
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </NavbarToggler>
          <Collapse isOpen={navbar} navbar>
            <Nav className="navbar-nav">
              {headerData && headerData.menus && headerData.menus.map((menu, index) => {
                return (
                  <li className={currentPath === menu.href || (currentPath === '/' && menu.menuName === 'DASHBOARD') ? 'nav-item active' : 'nav-item'} key={index}>
                  <Link title="Dashboard" className="nav-link" to={menu.href}>
                    <i className={menu.menuImage === 'dashboard.svg' ? 'icon icon-nav-dashboard' : menu.menuImage === 'search.svg' ? 'icon icon-nav-search' : menu.menuImage === 'jobs-in-progress.svg' ? 'icon icon-nav-progress' : menu.menuImage === 'completed-jobs.svg' ? 'icon icon-nav-completed' : ''} ></i>
                    {menu.menuName}
                  </Link>
                </li>
                )
              })
              }
              {/* <li
                className={
                  currentPath === '/dashboard' || currentPath === '/' ? 'nav-item active' : 'nav-item'
                }
              >
                <Link title="Dashboard" className="nav-link" to="/dashboard">
                  <i className="icon icon-nav-dashboard"></i>
                  dashboard
                </Link>
              </li>
              <li
                className={
                  currentPath === '/search' ? 'nav-item active' : 'nav-item'
                }
              >
                <Link title="Search" className="nav-link" to="/search">
                  <i className="icon icon-nav-search"></i>
                  Search
                </Link>
              </li>
              <li
                className={
                  currentPath === '/jobs-in-progress' ||
                  currentPath.split('/')[currentPath.split('/').length - 2] ===
                    'job-in-progress'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link
                  title="Jobs in progress"
                  className="nav-link"
                  to="/jobs-in-progress"
                >
                  <i className="icon icon-nav-progress"></i>
                  jobs in progress
                </Link>
              </li>
              <li
                className={
                  currentPath === '/completed-jobs' ||
                  currentPath.split('/')[currentPath.split('/').length - 2] ===
                    'completed-job'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link
                  title="Completed jobs"
                  className="nav-link"
                  to="/completed-jobs"
                >
                  <i className="icon icon-nav-completed"></i>
                  completed jobs
                </Link>
              </li> */}
            </Nav>
          </Collapse>

          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="dropdown header-login"
          >
            <DropdownToggle tag="a" className="dropdown-toggle">
            <span className="user-name">
              {userInfo && userInfo.user_name}
              </span>
              <span className="position">
                {userInfo && userInfo.role_name}
              </span>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu"
              aria-labelledby="dropdownMenuLink"
            >
              <DropdownItem className="dropdown-item" onClick={handelLogout}>
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </nav>
      </div>
    </header>
  )
}

export default Header
