const initialState = {
    createJobData: null,
  }
  
  export const createJob = (state = initialState, action) => {
    switch (action.type) {  
      case 'CREATE_JOB':
        return Object.assign({}, state, {
            createJobData: action.payload.createJob
          })
      default:
        return state
    }
  }
  