import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

let Index = (props) => {
  
  const data = props.chartData ? props.chartData.predictionVsActualData : [];
  let predictionData = [];
  let actualData = [];
  let xAxis = [];
  for(let j = 0;  j <= data.length -1; j++ ) {
    let obj = data[j];
    xAxis.push(obj.actualtime)
    predictionData.push([obj.predictiontime, obj.predictedvalue]);
    actualData.push([obj.actualtime, obj.actualvalue])
  }

  const chartOptions = {
    chart: {
      panning: {
        enabled: true,
        type: 'xy',
      },
      panKey: 'alt',
      zoomType: 'xy',
      height: 540
    },

    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: 'Value',
      },
    },

    xAxis: {
      title: {
        text: 'Date',
      },
      categories: xAxis,
      labels: {
        rotation: -90,
        useHTML: true,
        style: {
          textOverflow: 'clip'
        }
      
    }
  },

    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top'
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Prediction',
        type:'line',
        data: predictionData,
        color: '#5ccb70',
        turboThreshold: 0
      },
      {
        name: 'Actual',
        type:'line',
        data: actualData,
        color: '#000000',
        turboThreshold: 0

      },
    ],
  
  }
  return (
    <div className='prediction-system'>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
