import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import Datatable from '../../components/Datatable'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CompletedJobsFilterModal from '../../components/FilterModal'
import ShareJobModal from '../../components/ShareJobModal'

import jobsActions from '../../redux/actions/jobs'
import general from '../../redux/actions/general'
import { isAdminRole } from '../../AutominerUtility'

let Index = () => {
  const [searchValue, setSearchValue] = useState({
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
    user: 'Everyone',
    specificUsers: [],
    status: ['Any'],
    company: [],
    mill: [],
    machine: [],
    keyword: '',
    selectedUsers: [],
    selectedCompanies: [],
    selectedMills: [],
    selectedMachines: [],
    selectedStatusOption: [],
    sortColumn: 'id',
    sortType: 'desc',
    itemsPerPage: 10,
    selectedPageNumber: 1,
  })

  const dispatch = useDispatch()

  const [completedJobModal, setCompletedJobModal] = useState(false)
  const completedJobToggle = () => {
    setCompletedJobModal(!completedJobModal)  
  }

  const {userInfo} = useSelector((state) => state.auth.login)

  useEffect(() => {
    if(userInfo) {
      dispatch(general.getFilterData({userId: userInfo.id, listingType: 'Completed'}))
    }
  },[dispatch, userInfo])

  const [completedJobSearchTags, setCompletedJobSearchTags] = useState([])
  const [isTagExpanded, setTagExpanded] = useState(false)

  const [shareJobModal, setShareJobModal] = useState(false)
  const shareJobModalToggle = () => setShareJobModal(!shareJobModal)

  const filterSubmit = (filters) => {
    console.log(filters)
  }

  const [jobTitle, setJobTitle] = useState('')
  const [jobDetails, setJobDetails] = useState('')

  let handelShareJob = (jobTitle, jobDetails) => {
    return false;
    setJobDetails(jobDetails)
    setJobTitle(jobTitle)
    shareJobModalToggle()
  }

  let handelDowloadPDF = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'True', fileName: ''})).then((result) => {
      window.open(result.file); 
    })
  }

  let handelDowloadFile = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'False', fileName: details.s3_file_path })).then((result) => {
      window.open(result.file);
    })
  }

  const { searchedCompletedJobs } = useSelector((state) => state.jobs)
  const { filterData } = useSelector((state) => state.general.Filter)

  useEffect(() => {
    let searchRequest = {
      'userInfo' :{
        'userId' : userInfo.id
      },
      filterCriteria: {
        keyword: searchValue.keyword,
        fromDate: searchValue.fromDate,
        toDate: searchValue.toDate,
        selectedCompanies: searchValue.selectedCompanies,
        selectedMills: searchValue.selectedMills,
        selectedMachines: searchValue.selectedMachines,
        selectedUsers: searchValue.selectedUsers,
        selectedStatusOption: searchValue.selectedStatusOption,
        listingType: 'completed',
      },
      sortCriteria: {
        sortColumn: searchValue.sortColumn,
        sortType: searchValue.sortType,
      },
      pagingCriteria: {
        itemsPerPage: searchValue.itemsPerPage,
        selectedPageNumber: searchValue.selectedPageNumber
      },
    }
    
    dispatch(jobsActions.searchCompletedJobs(searchRequest))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchValue])

  const [tableData, setTableData] = useState([])
  const [extendedColumns, setExtendedColumns] = useState([])
  const [completedJobType, setCompletedJobType] = useState('allJob')

  useEffect(() => {}, [searchedCompletedJobs])

  const fetchMoreData = (page, showEntries, sortType, sortField) => {
    setSearchValue({
      ...searchValue,
      selectedPageNumber: page,
      itemsPerPage: JSON.parse(showEntries),
      sortColumn: sortField,
      sortType: sortType,
    })
  }

  const clearExpanded = () => {
    let updateTableData = [...tableData]
    updateTableData = updateTableData.filter((item) => {
      item.collapsable = false
      return !item.expanded
    })
    setExtendedColumns([])
    setTableData(updateTableData)
  }

  const filterCompletedJobTable = (filterRequest, type, searchTages) => {
    setCompletedJobSearchTags(searchTages)
    if (type === 'completed') {
      setSearchValue({
        fromDate: filterRequest.fromDate,
        fromTime: filterRequest.fromTime,
        toDate: filterRequest.toDate,
        toTime: filterRequest.toTime,
        user: filterRequest.user,
        specificUsers: filterRequest.specificUsers,
        status: filterRequest.status,
        company: filterRequest.company,
        mill: filterRequest.mill,
        machine: filterRequest.machine,
        keyword: filterRequest.keyword,
        selectedUsers: filterRequest.selectedUsers,
        selectedCompanies: filterRequest.selectedCompanies,
        selectedMills: filterRequest.selectedMills,
        selectedMachines: filterRequest.selectedMachines,
        selectedStatusOption: filterRequest.selectedStatusOption,
        sortColumn: filterRequest.sortColumn,
        sortType: filterRequest.sortType,
        itemsPerPage: filterRequest.itemsPerPage,
        selectedPageNumber: 1
      })
    }
  }

  const cancelFilter = (previousSelection) => {
    setSearchValue(previousSelection)
  }

  let removeTag = (index) => {
    let removeItem = completedJobSearchTags[index]
    setCompletedJobSearchTags(
      completedJobSearchTags.filter((item, i) => i !== index)
    )

    if (removeItem.key === 'keyword') {
      setSearchValue({
        ...searchValue,
        keyword: '',
      })
    } else if (removeItem.key === 'company') {
      let searchValueCompany = searchValue.company
      let selectedCompanies = searchValue.selectedCompanies
      setSearchValue({
        ...searchValue,
        company: searchValueCompany.filter((d) => d.label !== removeItem.label),
        selectedCompanies: selectedCompanies.filter(
          (d) => d !== removeItem.label
        ),
      })

      if (searchValueCompany.length === 1) {
        setSearchValue({
          ...searchValue,
          company: [],
          mill: [],
          machine: [],
          selectedCompanies: [],
          selectedMills: [],
          selectedMachines: [],
        })
        let tempSearchTags = completedJobSearchTags.filter(
          (d) => d.key !== 'company' && d.key !== 'mill' && d.key !== 'machine'
        )
        setCompletedJobSearchTags(tempSearchTags)
      }
    } else if (removeItem.key === 'mill') {
      let searchValueMills = searchValue.mill
      let searchSelectedMills = searchValue.selectedMills
      setSearchValue({
        ...searchValue,
        mill: searchValueMills.filter((d) => d.value !== removeItem.label),
        selectedMills: searchSelectedMills.filter(
          (d) => d !== removeItem.label
        ),
      })
      if (searchValueMills.length === 1) {
        setSearchValue({
          ...searchValue,
          mill: [],
          machine: [],
          selectedMills: [],
          selectedMachines: [],
        })
        let tempSearchTags = completedJobSearchTags.filter(
          (d) => d.key !== 'mill' && d.key !== 'machine'
        )
        setCompletedJobSearchTags(tempSearchTags)
      }
    } else if (removeItem.key === 'machine') {
      let searchValueMachine = searchValue.machine
      let selectedValueMachine = searchValue.selectedMachines
      setSearchValue({
        ...searchValue,
        machine: searchValueMachine.filter((d) => d.value !== removeItem.label),
        selectedMachines: selectedValueMachine.filter(
          (d) => d !== removeItem.label
        ),
      })
    } else if (removeItem.key === 'user') {
      setSearchValue({
        ...searchValue,
        user: 'Everyone',
        selectedUsers: [],
      })
      setCompletedJobType('allJob')
    } else if (removeItem.key === 'Specific User(s)') {
      let searchValueSpecificUser = searchValue.specificUsers
      let searchValueSelectedUser = searchValue.selectedUsers

      if (
        searchValueSpecificUser.length > 0 &&
        searchValueSelectedUser.length > 0
      ) {
        setSearchValue({
          ...searchValue,
          specificUsers: searchValueSpecificUser.filter(
            (d) => d.value !== removeItem.userId
          ),
          selectedUsers: searchValueSelectedUser.filter(
            (d) => d !== removeItem.userId
          ),
        })
      } else {
        setSearchValue({
          ...searchValue,
          specificUsers: [],
          selectedUsers: [],
        })
      }
    } else if (
      [
        'Any',
        'Running Exploration',
        'Running Prediction',
        'Submitted',
        'Completed',
        'Stopped',
      ].includes(removeItem.key)
    ) {
      let status = searchValue.status
      let selectedStatusOption = searchValue.selectedStatusOption
      status.splice(status.indexOf(removeItem.key), 1)
      selectedStatusOption.splice(
        selectedStatusOption.indexOf(removeItem.statusId),
        1
      )
      
      setSearchValue({
        ...searchValue,
        status: status,
        selectedStatusOption: selectedStatusOption,
      })
    } else if (removeItem.key === 'dateTime') {
      setSearchValue({
        ...searchValue,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
      })
    }
  }

  let handelClearAll = () => {
    setCompletedJobSearchTags([])
    setCompletedJobType('allJob')
    setSearchValue({
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      user: 'Everyone',
      specificUsers: [],
      status: ['Any'],
      company: [],
      mill: [],
      machine: [],
      keyword: '',
      selectedUsers: [],
      selectedCompanies: [],
      selectedMills: [],
      selectedMachines: [],
      selectedStatusOption: [],
      sortColumn: 'id',
      sortType: 'asc',
      itemsPerPage: 10,
      selectedPageNumber: 1,
    })
  }

  const completedJobsColumns = [
    {
      label: 'Id',
      field: 'id',
      sort: true,
      width: '90px',
      align: 'center',
      customRow: (row) => {
        return (
          <Link to={'/completed-job/' + row.id} alt="">
            <span className="td-id">{row.id}</span>
          </Link>
        )
      },
    },
    {
      label: 'Submitted',
      field: 'submission_time',
      sort: true,
      wrap: true,
      width: '140px',
    },
    {
      label: 'User',
      field: 'user_name',
      sort: true,
      width: '145px',
      customRow: (row) => {
        return (
          <>
            {row.user_name === 'Abc Def' ? (
              <span className="td-highLight">
                <b>{row.user_name}</b> (me)
              </span>
            ) : (
              row.user_name
            )}
          </>
        )
      },
    },
    {
      label: 'Job Name',
      field: 'job_name',
      sort: true,
      wrap: true,
      width: '294px',
      customRow: (row) => {
        return <span className="td-large">{row.job_name}</span>
      },
    },
    {
      label: 'File',
      field: 's3_file_path',
      wrap: true,
      width: '70px',
      align: 'center',
      customRow: (row) => {
        return (
          <span className="td-icon">
            <a onClick={() => handelDowloadFile(row)}>
              <i className="icon-source-file-sm"></i>
            </a>
          </span>
        )
      },
    },
    {
      label: 'Company',
      field: 'company',
      sort: true,
      wrap: true,
      width: '195px',
      customRow: (row) => {
        return <span className="td-large">{row.company}</span>
      },
    },
    {
      label: 'Mill',
      field: 'mill',
      sort: true,
      wrap: true,
      width: '150px',
      customRow: (row) => {
        return <span className="td-large">{row.mill}</span>
      },
    },
    {
      label: 'Machine',
      field: 'machine',
      sort: true,
      wrap: true,
      width: '130px',
      customRow: (row) => {
        return <span className="td-large">{row.machine}</span>
      },
    },
    {
      label: 'Notes',
      field: 'notes',
      wrap: true,
      width: '265px',
      customRow: (row) => {
        return <span className="td-light">{row.notes}</span>
      },
    },
    {
      label: 'Report',
      field: '',
      width: '320px',
      customRow: (row) => {
        return (
          <div className="td-btn-flex">
            <Link to={'/completed-job/' + row.id} alt="">
              <Button
                className="dark-shadow view-btn"
                type="button"
                id={'btnCompleted-' + row.id}
              >
                View
              </Button>
            </Link>
            <span className="small-icon ml-4"
            onClick={() => handelDowloadPDF(row)}>
              <i id={'btnDownload-' + row.id} className="icon-download"></i>
            </span>
            <span
              className="small-icon ml-4"
              onClick={() => handelShareJob(row.job_name, row)}
            >
              <i id={'btnEmail-' + row.id} className="icon-email"></i>
            </span>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Header></Header>
      <main>
        <section>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7">
                <h2>
                  <i className="icon icon-completed"></i>
                  <span>Completed Jobs</span>
                </h2>
              </div>
              <div className="col-lg-5 text-right">
                <div>
                  <Link to="/dashboard">
                    <Button
                      type="button"
                      className="btn-lg min-btn"
                      id="btnStartANewJob"
                    >
                      Start a new job
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <hr className="hr mt-4 mb-4"></hr>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {searchedCompletedJobs ? (
                  <div className="length-name">
                    <span className="lenght-nb">
                      {searchedCompletedJobs.totalCount}
                    </span>{' '}
                    Total Jobs Completed
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="col-lg-8">
                <div className="float-right">
                  <div className="jobs-btn mr-4 justify-content-center align-self-center"></div>
                  <div className={isAdminRole(userInfo['role_name']) ? 'jobs-btn' : 'hide'}>
                    <Button
                      type="button"
                      id="btnCompletedJobsAllJobs"
                      className={completedJobType === 'allJob' ? 'active' : ''}
                      onClick={() => {
                        setSearchValue({
                          ...searchValue,
                          user: 'Everyone',
                          selectedUsers: [],
                          specificUsers: []
                        })
                        setCompletedJobType('allJob')
                        let tempSearchTags = completedJobSearchTags.filter(
                          (d) => d.key !== 'user' 
                        )
                        setCompletedJobSearchTags(tempSearchTags)
                      }}
                    >
                      All Jobs
                    </Button>
                    <Button
                      type="button"
                      id="btnCompletedJobsMyJobs"
                      className={completedJobType === 'myJob' ? 'active' : ''}
                      onClick={() => {
                        setSearchValue({
                          ...searchValue,
                          specificUsers: [],
                          user: filterData.userOptions[1].label,
                          selectedUsers: filterData.userOptions[1].value,
                          selectedPageNumber : filterData.userOptions[1].selectedPageNumber,
                        })
                        
                        setCompletedJobSearchTags((oldArray) => [
                          ...oldArray,
                          { key: 'user', value: 'Users: ' + filterData.userOptions[1].label },
                        ])

                        setCompletedJobType('myJob')
                      }}
                    >
                      My Jobs
                    </Button>
                  </div>
                  <Button
                    type="button"
                    id="btnCompletedJobsFilterTable"
                    className="ml-3"
                    onClick={completedJobToggle}
                  >
                    Filter Table <i className="icon icon-filter"></i>
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="row align-items-center filters-add-main">
                <div className="col-lg-8 filters-add">
                  {completedJobSearchTags &&
                  completedJobSearchTags.length > 0 ? (
                    <span className="mr-2">Filters</span>
                  ) : (
                    ''
                  )}
                  {isTagExpanded
                    ? completedJobSearchTags &&
                      completedJobSearchTags.map((tag, index) => {
                        return (
                          <span
                            key={index.toString()}
                            className="mr-2 filter-tags"
                          >
                            {tag.value}{' '}
                            <span
                              aria-hidden="true"
                              key={index.toString()}
                              onClick={() => removeTag(index)}
                            >
                              &times;
                            </span>
                          </span>
                        )
                      })
                    : completedJobSearchTags &&
                      completedJobSearchTags.map((tag, index) => {
                        return index < 3 ? (
                          <span
                            key={index.toString()}
                            className="mr-2 filter-tags"
                          >
                            {tag.value}{' '}
                            <span
                              aria-hidden="true"
                              key={index.toString()}
                              onClick={() => removeTag(index)}
                            >
                              &times;
                            </span>
                          </span>
                        ) : (
                          ''
                        )
                      })}
                  {!isTagExpanded &&
                  completedJobSearchTags &&
                  completedJobSearchTags.length > 3 ? (
                    <span
                      className="mr-2 expand-tag filter-tags"
                      onClick={() => setTagExpanded(true)}
                    >
                      +{completedJobSearchTags.length - 3}
                    </span>
                  ) : (
                    ''
                  )}
                  {completedJobSearchTags &&
                  completedJobSearchTags.length > 0 ? (
                    <span className="clear-all-tag" onClick={handelClearAll}>
                      clear all
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <Datatable
                data={searchedCompletedJobs.jobs}
                columns={completedJobsColumns}
                filterData={{}}
                filterSubmit={filterSubmit}
                extendedColumns={extendedColumns}
                clearExpanded={clearExpanded}
                defaultSort={searchValue.sortColumn}
                defaultSortType={searchValue.sortType}
                itemsPerPage={searchValue.itemsPerPage}
                selectedPageNumber={searchValue.selectedPageNumber}
                totalCount={searchedCompletedJobs.totalCount}
                fetchMoreData={fetchMoreData}
                
              />
            </div>
          </div>
        </section>
      </main>
      <CompletedJobsFilterModal
        modal={completedJobModal}
        toggle={completedJobToggle}
        tableType={'completed'}
        appyFilterData={filterCompletedJobTable}
        filterData={searchValue}
        searchTag={completedJobSearchTags}
        setJobType={setCompletedJobType}
        cancelFilter={cancelFilter}
      ></CompletedJobsFilterModal>

      <ShareJobModal
        modal={shareJobModal}
        toggle={shareJobModalToggle}
        jobTitle={jobTitle}
        jobDetails={jobDetails}
      ></ShareJobModal>
      <Footer></Footer>
    </>
  )
}

export default Index
