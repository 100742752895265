import React from 'react'
import { Link } from 'react-router-dom'

let Index = (props) => {
  const getBreadcrumItem = () => {
    let items = []
    for (let i = 0; i < props.items.length; i++) {
      if (i < props.items.length - 1) {
        items.push(
          <li className="breadcrumb-item" key={i}>
            <Link to={props.items[i].link}>{props.items[i].item}</Link>
          </li>
        )
      } else {
        items.push(
          <li className="breadcrumb-item active" key={i}>
            {props.items[i].item}
          </li>
        )
      }
    }
    return items
  }
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">{getBreadcrumItem()}</ol>
    </nav>
  )
}

export default Index
