const initialState = {
    updateJobData: null,
  }
  
  export const updateJobData = (state = initialState, action) => {
    switch (action.type) {  
      case 'UPDATE_JOB_DATA':
        return Object.assign({}, state, {
            updateJobData: action.payload.updateJobData
          })
      default:
        return state
    }
  }
  