import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Progress, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import jobsActions from '../../redux/actions/jobs'
import general from '../../redux/actions/general'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FilterModal from '../../components/FilterModal'

import Datatable from '../../components/Datatable'
import { Link } from 'react-router-dom'
import { isAdminRole } from '../../AutominerUtility'

let Index = () => {
  const [searchValue, setSearchValue] = useState({
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
    user: 'Everyone',
    specificUsers: [],
    status: ['Submitted', 'Running Exploration', 'Running Prediction'],
    company: [],
    mill: [],
    machine: [],
    keyword: '',
    selectedUsers: [],
    selectedCompanies: [],
    selectedMills:[],
    selectedMachines: [],
    selectedStatusOption: [2, 3, 1],
    sortColumn: 'id',
    sortType: 'desc',
    itemsPerPage: 10,
    selectedPageNumber: 1
  })

  const filterSubmit = (filters) => {
    console.log(filters)
  }
  const [jobsInProgressFilterModal, setJobsInProgressFilterModal] = useState(false)
  const [progressJobSearchTags, setProgressJobSearchTags] = useState([{key: 'Submitted', statusId: 1, value: 'Status: Submitted'}, {key: 'Running Exploration',
  statusId: 2, value: 'Status: Running Exploration'}, {key: 'Running Prediction', statusId: 3, value: 'Status: Running Prediction'}])
  const [isTagExpanded, setTagExpanded] = useState(false)
  const [tableData, setTableData] = useState([])
  const [extendedColumns, setExtendedColumns] = useState([])
  const [jobProgressType, setJobProgressType] = useState('allJob')
  const [includeStopJobs, setIncludeStopJobs] = useState(false)

  const jobInProgressTimerRef = React.useRef(null);
  const {userInfo} = useSelector((state) => state.auth.login)
  
  const jobsInProgressFilterModalToggle = () =>
    setJobsInProgressFilterModal(!jobsInProgressFilterModal)

  const dispatch = useDispatch()

  useEffect(() => {
    if(searchValue.status.includes('Stopped')) {
      setIncludeStopJobs(true)
    }

    let searchRequest = {
      'userInfo' :{
        'userId' : userInfo.id
      },
      'filterCriteria': {
        'keyword': searchValue.keyword,
        'fromDate': searchValue.fromDate,
        'toDate': searchValue.toDate,
        'selectedCompanies':searchValue.selectedCompanies,
        'selectedMills': searchValue.selectedMills,
        'selectedMachines': searchValue.selectedMachines,
        'selectedUsers': searchValue.selectedUsers,
        'selectedStatusOption': searchValue.selectedStatusOption,
        'listingType': 'inProgress'
      },
      'sortCriteria': {
        'sortColumn': searchValue.sortColumn,
        'sortType': searchValue.sortType
      },
      'pagingCriteria': {
        'itemsPerPage': searchValue.itemsPerPage,
        'selectedPageNumber': searchValue.selectedPageNumber
      }
    }
    
    dispatch(jobsActions.searchProgressJobs(searchRequest))
    jobInProgressTimerRef.current = setInterval(() => {
      dispatch(jobsActions.searchProgressEveryMinJobs(searchRequest))
    }, 10000);
    return () => {
      // componentWillUnmount events
      clearInterval(jobInProgressTimerRef.current)
    }
  }, [dispatch, searchValue])

  useEffect(() => {
    if(userInfo) {
      dispatch(general.getFilterData({userId: userInfo.id, listingType: 'InProgress'}))
    }
  }, [dispatch, userInfo])

  
  const { searchedJobInProgressJobs } = useSelector((state) => state.jobs)
  const { filterData } = useSelector((state) => state.general.Filter)

  useEffect(() => {
  }, [searchedJobInProgressJobs])

  const clearExpanded = () => {
    let updateTableData = [...tableData]
    updateTableData = updateTableData.filter((item) => {
      item.collapsable = false
      return !item.expanded
    })
    setExtendedColumns([])
    setTableData(updateTableData)
  }

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [jobTitle, setJobTitle] = useState('')
  const [jobIdForStop, setJobIdForStop] = useState(-1)

  let stopJobConfirmation = (jobTitle, jobId) => {
    setJobTitle(jobTitle)
    setJobIdForStop(JSON.stringify(jobId))
    setConfirmationModalOpen(true)
  }

  let handelStopJob = () => {
    dispatch(jobsActions.stopJobProgress({'jobId': jobIdForStop, 'userName': userInfo.user_name})).then((result) => {
     if(result.status) {
       setConfirmationModalOpen(false)
       let searchRequest = {
        'userInfo' :{
          'userId' : userInfo.id
        },
        'filterCriteria': {
          'keyword': searchValue.keyword,
          'fromDate': searchValue.fromDate,
          'toDate': searchValue.toDate,
          'selectedCompanies':searchValue.selectedCompanies,
          'selectedMills': searchValue.selectedMills,
          'selectedMachines': searchValue.selectedMachines,
          'selectedUsers': searchValue.selectedUsers,
          'selectedStatusOption': searchValue.selectedStatusOption,
          'listingType': 'inProgress'
        },
        'sortCriteria': {
          'sortColumn': searchValue.sortColumn,
          'sortType': searchValue.sortType
        },
        'pagingCriteria': {
          'itemsPerPage': searchValue.itemsPerPage,
          'selectedPageNumber': searchValue.selectedPageNumber 
        }
      }
      dispatch(jobsActions.searchProgressJobs(searchRequest))
     }
    })
  }

  const fetchMoreData = (page, showEntries, sortType, sortField) => {
    setSearchValue({
      ...searchValue,
      selectedPageNumber: page,
      itemsPerPage: JSON.parse(showEntries),
      sortColumn: sortField,
      sortType: sortType,
    })
  }

  const filterJobInProgressJobTable = (filterRequest, type, searchTages) => {
    setProgressJobSearchTags(searchTages)
    if(type === 'inProgress' && filterRequest.selectedStatusOption.length !== 0) { 
      setSearchValue({
        fromDate: filterRequest.fromDate,
        fromTime: filterRequest.fromTime,
        toDate: filterRequest.toDate,
        toTime: filterRequest.toTime,
        user: filterRequest.user,
        specificUsers: filterRequest.specificUsers,
        status: filterRequest.status,
        company: filterRequest.company,
        mill: filterRequest.mill,
        machine: filterRequest.machine,
        keyword: filterRequest.keyword,
        selectedUsers: filterRequest.selectedUsers,
        selectedCompanies: filterRequest.selectedCompanies,
        selectedMills: filterRequest.selectedMills,
        selectedMachines: filterRequest.selectedMachines,
        selectedStatusOption: filterRequest.selectedStatusOption,
        sortColumn: filterRequest.sortColumn,
        sortType: filterRequest.sortType,
        itemsPerPage: filterRequest.itemsPerPage,
        selectedPageNumber: 1
      })
      if(!filterRequest.status.includes('Stopped')) {
        setIncludeStopJobs(false)
      }
    } else {
      setSearchValue({
        fromDate: filterRequest.fromDate,
        fromTime: filterRequest.fromTime,
        toDate: filterRequest.toDate,
        toTime: filterRequest.toTime,
        user: filterRequest.user,
        specificUsers: filterRequest.specificUsers,
        status: ['Submitted', 'Running Exploration', 'Running Prediction', 'Stopped'],
        company: filterRequest.company,
        mill: filterRequest.mill,
        machine: filterRequest.machine,
        keyword: filterRequest.keyword,
        selectedUsers: filterRequest.selectedUsers,
        selectedCompanies: filterRequest.selectedCompanies,
        selectedMills: filterRequest.selectedMills,
        selectedMachines: filterRequest.selectedMachines,
        selectedStatusOption: [2, 3, 1, 5],
        sortColumn: filterRequest.sortColumn,
        sortType: filterRequest.sortType,
        itemsPerPage: filterRequest.itemsPerPage,
        selectedPageNumber: filterRequest.selectedPageNumber 
      })
      setProgressJobSearchTags([{key: 'Submitted', statusId: 1, value: 'Status: Submitted'}, {key: 'Running Exploration',
    statusId: 2, value: 'Status: Running Exploration'}, {key: 'Running Prediction', statusId: 3, value: 'Status: Running Prediction'}, {key: 'Stopped', statusId: 5, value: 'Status: Stopped'}])
    }
  }

  const cancelFilter = (previousSelection) => {
    setSearchValue(previousSelection)
  }

  let handelIncludeStopJob = () => {
    setIncludeStopJobs(!includeStopJobs)
    if(!includeStopJobs) {
      let selectedStatusOption = searchValue.selectedStatusOption
      let selectedStatus = searchValue.status
      selectedStatusOption.push(5)
      selectedStatus.push('Stopped')

      if(selectedStatus.includes('Any')) {
        selectedStatus = selectedStatus.filter((item) =>  item !== 'Any')
      }

      setProgressJobSearchTags((oldArray) => [
        ...oldArray,
        { key: 'Stopped', value: 'Status: ' + filterData.statusOptions[4].label, statusId: filterData.statusOptions[4].value },
      ])

      setSearchValue({
        ...searchValue,
        selectedStatusOption: selectedStatusOption,
        status: selectedStatus
      })
    } else {
      let status = searchValue.status
      let selectedStatusOption = searchValue.selectedStatusOption

      status.splice(status.indexOf('Stopped'), 1)
      selectedStatusOption.splice(selectedStatusOption.indexOf(5), 1)
      setSearchValue({
        ...searchValue,
        status: status,
        selectedStatusOption: selectedStatusOption
      })

      let tempSearchTags = progressJobSearchTags.filter(
        (d) => d.key !== 'Stopped' 
      )
      setProgressJobSearchTags(tempSearchTags)
    }
  }

  let removeTag = (index) => {
    let removeItem = progressJobSearchTags[index]
    setProgressJobSearchTags(progressJobSearchTags.filter((item, i) => i !== index))

    if (removeItem.key === 'keyword') {
      setSearchValue({
        ...searchValue,
        keyword: '',
      })
    } else if (removeItem.key === 'company') {
      let searchValueCompany = searchValue.company
      let selectedCompanies = searchValue.selectedCompanies
      setSearchValue({
        ...searchValue,
        company: searchValueCompany.filter((d) => d.label !== removeItem.label),
        selectedCompanies: selectedCompanies.filter((d) => d !== removeItem.label),
      })

      if (searchValueCompany.length === 1) {
        setSearchValue({
          ...searchValue,
          company: [],
          mill: [],
          machine: [],
          selectedCompanies: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = progressJobSearchTags.filter((d) => d.key !== 'company' && d.key !== 'mill' && d.key !== 'machine' )
        setProgressJobSearchTags(
          tempSearchTags
        )
        // let tempSearchTags = progressJobSearchTags
        // setProgressJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'mill'),
        //     tempSearchTags.length
        //   )
        // )
        // setProgressJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'machine'),
        //     tempSearchTags.length
        //   )
        // )
      }
    } else if (removeItem.key === 'mill') {
      let searchValueMills = searchValue.mill
      let searchSelectedMills = searchValue.selectedMills
      setSearchValue({
        ...searchValue,
        mill: searchValueMills.filter((d) => d.value !== removeItem.label),
        selectedMills: searchSelectedMills.filter((d) => d !== removeItem.label),
      })
      if (searchValueMills.length === 1) {
        setSearchValue({
          ...searchValue,
          mill: [],
          machine: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = progressJobSearchTags.filter((d) => d.key !== 'mill' && d.key !== 'machine' )
        setProgressJobSearchTags(
          tempSearchTags
        )
        // let tempSearchTags = progressJobSearchTags
        // setProgressJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'machine'),
        //     1
        //   )
        // )
      }
    } else if (removeItem.key === 'machine') {
      let searchValueMachine = searchValue.machine
      let selectedValueMachine = searchValue.selectedMachines
      setSearchValue({
        ...searchValue,
        machine: searchValueMachine.filter((d) => d.value !== removeItem.label),
        selectedMachines: selectedValueMachine.filter((d) => d !== removeItem.label)
      })
    } else if(removeItem.key === 'user'){
      setSearchValue({
        ...searchValue,
        user: 'Everyone',
        selectedUsers: []
      })
      setJobProgressType('allJob')
    } else if(removeItem.key === 'Specific User(s)'){
      let searchValueSpecificUser = searchValue.specificUsers
      let searchValueSelectedUser = searchValue.selectedUsers

      if(searchValueSpecificUser.length > 0 && searchValueSelectedUser.length > 0) {
        setSearchValue({
          ...searchValue,
          specificUsers: searchValueSpecificUser.filter((d) => d.value !== removeItem.userId),
          selectedUsers: searchValueSelectedUser.filter((d) => d !== removeItem.userId),
        })
      } else {
        setSearchValue({
          ...searchValue,
          specificUsers: [],
          selectedUsers: []
        })
      }
     } else if (
      [
        'Any',
        'Running Exploration',
        'Running Prediction',
        'Submitted',
        'Completed',
        'Stopped',
      ].includes(removeItem.key)
    ) {
      let status = searchValue.status
      let selectedStatusOption = searchValue.selectedStatusOption

      if(searchValue.status.includes('Stopped')) {
        setIncludeStopJobs(false)
      }
      status.splice(status.indexOf(removeItem.key), 1)
      selectedStatusOption.splice(selectedStatusOption.indexOf(removeItem.statusId), 1)
      setSearchValue({
        ...searchValue,
        status: status,
        selectedStatusOption: selectedStatusOption
      })

      

    } else if (removeItem.key === 'dateTime') {
      setSearchValue({
        ...searchValue,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
      })
    }
  }
  
  let handelClearAll = () => {
    setJobProgressType('allJob')
    setProgressJobSearchTags([])
    setIncludeStopJobs(false)
    setSearchValue({
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      user: 'Everyone',
      specificUsers: [],
      status: ['Any'],
      company: [],
      mill: [],
      machine: [],
      keyword: '',
      selectedUsers: [],
      selectedCompanies: [],
      selectedMills:[],
      selectedMachines: [],
      selectedStatusOption: [],
      sortColumn: 'id',
      sortType: 'asc',
      itemsPerPage: 10,
      selectedPageNumber: 1
    })
  }

  let handelDowloadFile = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'False', fileName: details.s3_file_path })).then((result) => {
      window.open(result.file);
    })
  }

  const columns = [
    {
      label: 'Id',
      field: 'id',
      sort: true,
      width: '90px',
      align: 'center',
      customRow: (row) => {
        return (
          <Link to={'/job-in-progress/' + row.id} alt="">
            <span className={row.status_name === 'Stopped' ? 'td-id td-stopped' : 'td-id'}>
              {row.id}
            </span>
          </Link>
        )
      },
    },
    {
      label: 'Submitted',
      field: 'submission_time',
      sort: true,
      wrap: true,
      width: '140px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped' : ''}>
            {row.submission_time}
          </span>
        )
      },
    },
    {
      label: 'User',
      field: 'user_name',
      sort: true,
      width: '145px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped' : ''}>
            {row.user_name === 'Abc Def' ? (
              <span className="td-highLight">
                <b>{row.user_name}</b> (me)
              </span>
            ) : (
              row.user_name
            )}
          </span>
        )
      },
    },
    {
      label: 'Job Name',
      field: 'job_name',
      sort: true,
      wrap: true,
      width: '290px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.job_name}
          </span>
        )
      },
    },
    {
      label: 'File',
      field: 's3_file_path',
      wrap: true,
      width: '70px',
      align: 'center',
      customRow: (row) => {
        return (
          <span className="td-icon">
            <a onClick={() => handelDowloadFile(row)}>
              <i className="icon-source-file-sm"></i>
            </a>
          </span>
        )
      },
    },
    {
      label: 'Company',
      field: 'company',
      sort: true,
      wrap: true,
      width: '195px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.company}
          </span>
        )
      },
    },
    {
      label: 'Mill',
      field: 'mill',
      sort: true,
      wrap: true,
      width: '150px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.mill}
          </span>
        )
      },
    },
    {
      label: 'Machine',
      field: 'machine',
      sort: true,
      wrap: true,
      width: '130px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.machine}
          </span>
        )
      },
    },
    {
      label: 'Status',
      field: 'status_name',
      sort: true,
      wrap: true,
      width: '230px',
      customRow: (row) => {
        return (
          <>
            {row.status_name === 'Stopped' ? (
              <span className="text-danger">{row.status_name}</span>
            ) : (
              <span className="text-strong">{row.status_name}</span>
            )}
            <Progress className="td-stopped" value={row.percentage} />
          </>
        )
      },
    },
    {
      label: 'Notes',
      field: 'notes',
      wrap: true,
      width: '225px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-light' : 'td-light'}>
            {row.notes}
          </span>
        )
      },
    },
    {
      label: 'Stop Job',
      field: 'stop_job',
      width: '125px',
      align: 'center',
      customRow: (row) => {
        return row.status_name === 'Stopped' ? (
          <div className="td-btn-flex">
            <span className="small-icon danger-icon">
              <i id={'btnStopJob-' + row.id} className="fa fa-stop"></i>
            </span>
          </div>
        ) : (
          <div
            className="td-btn-flex"
            onClick={() => stopJobConfirmation(row.job_name, row.id)}
          >
            <span className="small-icon">
              <i id={'btnStopJob-' + row.id} className="fa fa-stop"></i>
            </span>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Header></Header>
      <main>
        <section>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7">
                <h2>
                  <i className="icon icon-progress"></i>
                  <span>jobs in progress</span>
                </h2>
              </div>
              <div className="col-lg-5 text-right">
                <div>
                  <Link to="/dashboard">
                    <Button
                      type="button"
                      className="btn-lg min-btn"
                      id="btnStartANewJob"
                    >
                      Start a new job
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <hr className="hr mt-4 mb-4"></hr>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                { searchedJobInProgressJobs ? <div className="length-name">
                  <span className="lenght-nb">{searchedJobInProgressJobs.totalCount}</span>{' '}
                  Total Jobs In Progress
                </div> : '' }
              </div>
              <div className="col-lg-8">
                <div className="float-right">
                  <div className="jobs-btn mr-4 justify-content-center align-self-center">
                    <div className="checkbox">
                      <Input type="checkbox" 
                      checked={includeStopJobs}
                      onChange={() => {handelIncludeStopJob()}}/>{' '}
                      <label className="f-sm">Include Stopped Jobs</label>
                    </div>
                  </div>
                  <div className={isAdminRole(userInfo['role_name']) ? 'jobs-btn' : 'hide'}>
                    <Button
                      type="button"
                      id="btnJobsInProgresAllJobs"
                      className={jobProgressType === 'allJob' ? 'active' : ''}
                      onClick={() => {
                        setSearchValue({
                          ...searchValue,
                          user: 'Everyone',
                          selectedUsers: [],
                          specificUsers: []
                        })
                        setJobProgressType('allJob')
                        let tempSearchTags = progressJobSearchTags.filter(
                          (d) => d.key !== 'user' 
                        )
                        setProgressJobSearchTags(tempSearchTags)
                      }}
                    >
                      All Jobs
                    </Button>
                    <Button
                      type="button"
                      id="btnJobsInProgresMyJobs"
                      className={jobProgressType === 'myJob' ? 'active' : ''}
                      onClick={() => {
                        setSearchValue({
                          ...searchValue,
                          specificUsers: [],
                          user: filterData.userOptions[1].label,
                          selectedUsers: filterData.userOptions[1].value
                        })
                        
                        setProgressJobSearchTags((oldArray) => [
                          ...oldArray,
                          { key: 'user', value: 'Users: ' + filterData.userOptions[1].label, userId: filterData.userOptions[1].value },
                        ])

                        setJobProgressType('myJob')
                      }}
                    >
                      My Jobs
                    </Button>
                  </div>
                  <Button
                    type="button"
                    id="btnJobsInProgresFilterTable"
                    className="ml-3"
                    onClick={jobsInProgressFilterModalToggle}
                  >
                    Filter Table <i className="icon icon-filter"></i>
                  </Button>
                </div>
              </div>
            </div>

            <div>
              <div className="row align-items-center filters-add-main">
                <div className="col-lg-8 filters-add">
                    { progressJobSearchTags  && progressJobSearchTags.length > 0 ? (
                      <span className="mr-2">Filters</span>
                    ) : (
                      ''
                    )}
                    {isTagExpanded
                      ? progressJobSearchTags &&
                      progressJobSearchTags.map((tag, index) => {
                          return (
                            <span
                              key={index.toString()}
                              className="mr-2 filter-tags"
                            >
                              {tag.value}{' '}
                              <span
                                aria-hidden="true"
                                key={index.toString()}
                                onClick={() => removeTag(index)}
                              >
                                &times;
                              </span>
                            </span>
                          )
                        })
                      : progressJobSearchTags &&
                      progressJobSearchTags.map((tag, index) => {
                          return index < 3 ? (
                            <span
                              key={index.toString()}
                              className="mr-2 filter-tags"
                            >
                              {tag.value}{' '}
                              <span
                                aria-hidden="true"
                                key={index.toString()}
                                onClick={() => removeTag(index)}
                              >
                                &times;
                              </span>
                            </span>
                          ) : (
                            ''
                          )
                        })}
                    {!isTagExpanded && progressJobSearchTags && progressJobSearchTags.length > 3 ? (
                      <span
                        className="mr-2 expand-tag filter-tags"
                        onClick={() => setTagExpanded(true)}
                      >
                        +{progressJobSearchTags.length - 3}
                      </span>
                    ) : (
                      ''
                    )}
                    {progressJobSearchTags && progressJobSearchTags.length > 0 ? (
                      <span className="clear-all-tag" 
                      onClick={handelClearAll}
                      >
                        clear all
                      </span>
                    ) : (
                      ''
                    )}
                </div>
              </div>
              <Datatable
                data={searchedJobInProgressJobs.jobs}
                columns={columns}
                filterData={{}}
                filterSubmit={filterSubmit}
                extendedColumns={extendedColumns}
                clearExpanded={clearExpanded}
                defaultSort={searchValue.sortColumn}
                defaultSortType={searchValue.sortType}
                itemsPerPage={searchValue.itemsPerPage}
                selectedPageNumber={searchValue.selectedPageNumber}
                totalCount={searchedJobInProgressJobs.totalCount}
                fetchMoreData={fetchMoreData}
              />
              {/* <Datatable data={jobsInProgress}></Datatable> */}
            </div>
          </div>
        </section>
      </main>
      <FilterModal
        modal={jobsInProgressFilterModal}
        toggle={jobsInProgressFilterModalToggle}
        tableType={'inProgress'}
        appyFilterData={filterJobInProgressJobTable}
        filterData={searchValue}
        searchTag={progressJobSearchTags}
        setJobType={setJobProgressType}
        cancelFilter={cancelFilter}
      ></FilterModal>

      <Modal
        className="modal-dialog-centered modal-lg success-modal"
        isOpen={isConfirmationModalOpen}
        onClick={() => setConfirmationModalOpen(!isConfirmationModalOpen)}
      >
        <ModalHeader toggle={() => setConfirmationModalOpen(!isConfirmationModalOpen)}></ModalHeader>
        <ModalBody>
          {/* <h2>Success!</h2> */}
          <p>Are you sure you want to stop job {jobTitle}?</p>
          <span>Once Job is stopped you can't start this job again.</span>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            type="button"
            color="primary"
            id="btnCancelSendEmail"
            onClick={() => setConfirmationModalOpen(false)}
          >
            No <i className="icon-cancel"></i>
          </Button>
          <Button
            type="button"
            className="active ml-3"
            id="btnSendEmail"
            color="secondary"
            onClick={handelStopJob}
          >
            Yes <i className="icon-apply"></i>
          </Button>
        </ModalFooter>
      </Modal>

      <Footer></Footer>
    </>
  )
}

export default Index
