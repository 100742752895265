import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/heatmap')(Highcharts)
require('highcharts/modules/heatmap.src')(Highcharts)
// highcharts-legend-navigation
let Index = (props) => {

  let data = props.chartData ? props.chartData : []

  var xAxisData = []
  var yAxisData = []
  var seriesData = []

  data.forEach((obj) => {
    if (yAxisData.indexOf(obj.variable2) === -1) {
      yAxisData.push(obj.variable2)
    }
    if (xAxisData.indexOf(obj.variable1) === -1) {
      xAxisData.push(obj.variable1)
    }
    seriesData.push(obj.heatMapData)
  })

  var height = (yAxisData.length * 25);
  var symbolHeight = (yAxisData.length * 25) - 300;
  if(height < 540){
    height = 540;
    symbolHeight = 520;
  }

  const chartOptions = {
    chart: {
      type: 'heatmap',
      marginTop: 50,
      marginBottom: 125,
      plotBorderWidth: 1,
      height: height
    },
    title: {
      text: '',
    },

    xAxis: {
      categories: xAxisData,
      endOnTick: false,
        startOnTick: false,
        labels: {
          rotation: -90,
          useHTML: true,
          width: xAxisData.length * 30,
          style: {
            textOverflow: 'clip',
            width: xAxisData.length * 30
          }
        },
    },

    yAxis: {
      categories: yAxisData,
      title: null,
      reversed: true,
      endOnTick: false,
      startOnTick: false
    },

    // xAxis: {
    //   categories: xAxisData,
    // },
    credits: {
      enabled: false,
    },
    // yAxis: {
    //   categories: yAxisData,
    //   title: null,
    //   reversed: true,
    // },

  //   colorAxis: {
  //     min: 0,
  //     minColor: '#FFFFFF',
  //     maxColor: Highcharts.getOptions().colors[0]
  // },

    colorAxis: {
      min : -1, 
      max : 1,
      stops: [
        [0, '#37c249'],
        [0.5, '#ffffff'], 
        [1, '#e41243']
    ]
    },
     

    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: symbolHeight,
    },

    tooltip: {
      formatter: function () {
        return (
          ' Tags in Rank : <b>' +
          this.series.xAxis.categories[this.point.x] +
          '</b><br>' +
          ' Tags : <b>' +
          this.series.yAxis.categories[this.point.y] +
          '</b><br>' +
          ' Value : <b>' +
          this.point.value +
          '</b>'
        )
      },
    },
    series: [
      {
        borderWidth: 1,
        data: seriesData,
        turboThreshold: 0,
        dataLabels: {
          enabled: false
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0)
                },
              },
            },
          },
        },
      ],
    },
  }

  return (
    <div className="correlation-heatmap">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
