import axiosInstance from '../../../config/axiosConfig'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const searchJobs = (filter) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let searchedJobsData = await axiosInstance.post('/search/get-jobs', filter)
  if (searchedJobsData && searchedJobsData.data) {
    dispatch({
      type: 'SEARCHED_JOBS',
      payload: {
        searchedJobs: searchedJobsData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(searchedJobsData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const searchCompletedJobs = (filter) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let searchCompletedJobsData = await axiosInstance.post('/search/get-jobs', filter)
  if (searchCompletedJobsData && searchCompletedJobsData.data) {
    dispatch({
      type: 'SEARCHED_COMPLETED_JOBS',
      payload: {
        searchedCompletedJobs: searchCompletedJobsData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(searchCompletedJobsData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const searchProgressJobs = (filter) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let searchJobInProgressData = await axiosInstance.post('/search/get-jobs', filter)
  if (searchJobInProgressData && searchJobInProgressData.data) {
    dispatch({
      type: 'SEARCHED_PROGRESS_JOBS',
      payload: {
        searchedJobInProgressJobs: searchJobInProgressData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(searchJobInProgressData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const searchProgressEveryMinJobs = (filter) => async (dispatch) => {
  let searchJobInProgressData = await axiosInstance.post('/search/get-jobs', filter)
  if (searchJobInProgressData && searchJobInProgressData.data) {
    dispatch({
      type: 'SEARCHED_PROGRESS_JOBS',
      payload: {
        searchedJobInProgressJobs: searchJobInProgressData.data,
      },
    })
  } else {
    await toast.dismiss()
    toast.error(searchJobInProgressData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const createJob = (jobDetails) => async (dispatch) => {
  const formData = new FormData();
  formData.append('submittedBy', jobDetails.submittedBy);
  formData.append('submittedOn', jobDetails.submittedOn);
  formData.append('jobName', jobDetails.jobName);
  formData.append('company', jobDetails.company);
  formData.append('mill', jobDetails.mill);
  formData.append('machine', jobDetails.machine);
  formData.append('targetVariable', jobDetails.targetVariable);
  formData.append('timeColumn', jobDetails.timeColumn);
  formData.append('gradeColumn', jobDetails.gradeColumn);
  formData.append('masterGradeImplementation', jobDetails.masterGradeImplementation);
  formData.append('grade_mapping_details', JSON.stringify(jobDetails.masterGrades));
  formData.append('notes', jobDetails.notes);
  formData.append('datafile', jobDetails.datafile)
  formData.append('env', process.env.REACT_APP_ENV)
  
  
  let jobData = await axiosInstance.post('/dashboard/submit-job', formData);
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  if (jobData && jobData.data && jobData.data.success) {
    dispatch({
      type: 'CREATE_JOB',
      payload: {
        createJob: jobData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.success('Job Created Successfully', {
      position: toast.POSITION.TOP_RIGHT,
    })
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(jobData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
  return await jobData.data
}

export const stopJobProgress = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let stopJobProgressData = await axiosInstance.post('/job/stop', jobDetails);
  if (stopJobProgressData && stopJobProgressData.data) {
    dispatch({
      type: 'STOP_JOB_PROGRESS',
      payload: {
        stopJobProgressDetails: stopJobProgressData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.success('Job Stop Successfully', {
      position: toast.POSITION.TOP_RIGHT,
    })
    return await {status: true}
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(stopJobProgressData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getJobsDetails = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let jobDetailsData = await axiosInstance.post('/job/job-details', jobDetails);
  if (jobDetailsData && jobDetailsData.data) {
    dispatch({
      type: 'JOB_DETAILS',
      payload: {
        jobDetails: jobDetailsData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    return await jobDetailsData.data;
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(jobDetailsData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getJobsDetailsEveryMin = (jobDetails) => async (dispatch) => {
  let jobDetailsData = await axiosInstance.post('/job/job-details', jobDetails);
  if (jobDetailsData && jobDetailsData.data) {
    dispatch({
      type: 'JOB_DETAILS',
      payload: {
        jobDetails: jobDetailsData.data,
      },
    })
    return await jobDetailsData.data;
  } else {
    await toast.dismiss()
    toast.error(jobDetailsData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getDensityPlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let densityPlotData = await axiosInstance.post('/job/get-density-plot', jobDetails);
  if (densityPlotData && densityPlotData.data) {
    dispatch({
      type: 'COPLETED_JOB_DENSITY_DATA',
      payload: {
        densityPlotData: densityPlotData.data.densityPlot,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(densityPlotData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getMaxCorrPlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let maxCorrData = await axiosInstance.post('/job/get-max-corr-plot', jobDetails);
  if (maxCorrData && maxCorrData.data) {
    dispatch({
      type: 'COPLETED_JOB_MAX_CORR_DATA',
      payload: {
        maxCorrPlotData: maxCorrData.data.scatterPlotData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(maxCorrData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getHeatmapPlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let heatmapData = await axiosInstance.post('/job/get-heatmap-plot', jobDetails);
  if (heatmapData && heatmapData.data) {
    dispatch({
      type: 'COPLETED_JOB_HEAT_MAP_DATA',
      payload: {
        heatmapPlotData: heatmapData.data.heatmapData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(heatmapData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getExplorationTablePlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let explorationTableData = await axiosInstance.post('/job/get-exploration-table-data', jobDetails);
  if (explorationTableData && explorationTableData.data && explorationTableData.data.explorationData) {
    dispatch({
      type: 'COPLETED_JOB_EXPLORATION_TABLE_DATA',
      payload: {
        explorationTableData: explorationTableData.data.explorationData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(explorationTableData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getExplorationTablePlotData = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let explorationTableData = await axiosInstance.post('/job/get-exploration-table-data', jobDetails);
  if (explorationTableData && explorationTableData.data && explorationTableData.data.explorationData) {
    dispatch({
      type: 'COPLETED_JOB_EXPLORATION_TABLE_DATA_NEXT_FIVE',
      payload: {
        nextFiveExplorationTableData: explorationTableData.data.explorationData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({
      type: 'COPLETED_JOB_EXPLORATION_TABLE_DATA_NEXT_FIVE',
      payload: {
        nextFiveExplorationTableData: {
          explorationData: [],
          lastTagId: null
        },
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  }
}

export const getisPredictionVSActualDataPlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let isPredictionVSActualData = await axiosInstance.post('/job/get-prediction-vs-actual-starter-data', jobDetails);
  if (isPredictionVSActualData && isPredictionVSActualData.data) {
    dispatch({
      type: 'COPLETED_JOB_PREDICTION_AND_ACTUAL_DATA',
      payload: {
        isPredictionVSActualData: isPredictionVSActualData.data.predictionVsActualChartData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(isPredictionVSActualData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getisPredictionVSActualDataPlotEveryMin = (jobDetails) => async (dispatch) => {
  let isPredictionVSActualData = await axiosInstance.post('/job/get-prediction-vs-actual-starter-data-new', jobDetails);
  if (isPredictionVSActualData && isPredictionVSActualData.data && isPredictionVSActualData.data.predictionVsActualChartData) {
    dispatch({
      type: 'COPLETED_JOB_PREDICTION_AND_ACTUAL_EVERYMIN_DATA',
      payload: {
        newIsPredictionVSActualData: isPredictionVSActualData.data.predictionVsActualChartData,
      },
    })
  }
}


export const getisErrorReductionPlotData = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let isErrorReductionPlotData = await axiosInstance.post('/job/get-error-reduction-plot', jobDetails);
  if (isErrorReductionPlotData && isErrorReductionPlotData.data) {
    dispatch({
      type: 'COPLETED_JOB_ERROR_REDUCTION_PLOT_DATA',
      payload: {
        isErrorReductionPlotData: isErrorReductionPlotData.data.errorReductionPlotData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(isErrorReductionPlotData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getisErrorReductionPlotDataEveryMin = (jobDetails) => async (dispatch) => {
let isErrorReductionPlotData = await axiosInstance.post('/job/get-error-reduction-plot-new', jobDetails);
if (isErrorReductionPlotData && isErrorReductionPlotData.data && isErrorReductionPlotData.data.errorReductionPlotData) {
  dispatch({
    type: 'COPLETED_JOB_ERROR_REDUCTION_PLOT_EVERYMIN_DATA',
    payload: {
      newIsErrorReductionPlotData: isErrorReductionPlotData.data.errorReductionPlotData,
    },
  })
}

}


export const getFrequencyChartPlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let frequencyChartData = await axiosInstance.post('/job/get-prediction-frequency-chart-starter-data', jobDetails);
  if (frequencyChartData && frequencyChartData.data) {
    dispatch({
      type: 'COPLETED_JOB_FREQUENCY_DATA',
      payload: {
        frequencyChartData: frequencyChartData.data.frequencyChartData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(frequencyChartData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getFrequencyChartPlotEveryMin = (jobDetails) => async (dispatch) => {
  let frequencyChartData = await axiosInstance.post('/job/get-prediction-frequency-chart-starter-data-new', jobDetails);
  if (frequencyChartData && frequencyChartData.data && frequencyChartData.data.frequencyChartData) {
    dispatch({
      type: 'COPLETED_JOB_FREQUENCY_EVERYMIN_DATA',
      payload: {
        newFrequencyChartData: frequencyChartData.data.frequencyChartData,
      },
    })
  }
}

export const getTemporalChartPlot = (jobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let temporalChartData = await axiosInstance.post('/job/get-prediction-temporal-chart-starter-data', jobDetails);
  if (temporalChartData && temporalChartData.data) {
    dispatch({
      type: 'COPLETED_JOB_TEMPORAL_DATA',
      payload: {
        temporalChartData: temporalChartData.data.scatterPlotData,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(temporalChartData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const getTemporalChartPlotEveryMin = (jobDetails) => async (dispatch) => {
  let temporalChartData = await axiosInstance.post('/job/get-prediction-temporal-chart-starter-data-new', jobDetails);
  if (temporalChartData && temporalChartData.data && temporalChartData.data.scatterPlotData) {
    dispatch({
      type: 'COPLETED_JOB_TEMPORAL_EVERYMIN_DATA',
      payload: {
        newTemporalChartData: temporalChartData.data.scatterPlotData,
      },
    })
  }
}

export const updateJobDetails = (updateJobDetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let updateJobData = await axiosInstance.post('/job/edit-job', updateJobDetails);
  if (updateJobData && updateJobData.data) {
    dispatch({
      type: 'UPDATE_JOB_DATA',
      payload: {
        updateJobData: updateJobData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.success('Job Details Update Successfully', {
      position: toast.POSITION.TOP_RIGHT,
    })
    return await updateJobData.data;
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(updateJobData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const downloadFile = (filedetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let fileData = await axiosInstance.post('/dashboard/download-file', filedetails);
  if (fileData && fileData.data) {
    dispatch({
      type: 'DOWNLOAD_FILE',
      payload: {
        fileData: fileData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    return await fileData.data;
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(fileData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const downloadInProgressFile = (filedetails) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER', payload: {isLoading: true }})
  let fileData = await axiosInstance.post('/download-pdf-report-file', filedetails);
  if (fileData && fileData.data) {
    dispatch({
      type: 'DOWNLOAD_PDF_FILE',
      payload: {
        fileData: fileData.data,
      },
    })
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    return await fileData.data;
  } else {
    dispatch({ type: 'SHOW_LOADER', payload: {isLoading: false }})
    await toast.dismiss()
    toast.error(fileData.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}