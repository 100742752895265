import React, { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Card, CardBody, Progress, Tooltip } from 'reactstrap'
import dayjs from 'dayjs'
import ReactSelect from 'react-select'

import jobsActions from '../../redux/actions/jobs'

import errorRed from '../../public/images/error-red.svg'

import getFile from '../../public/sampledata.csv'
import getRedmeFile from '../../public/README.txt'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { property } from 'lodash';
import MasterGradeSelect from './MasterGradeSelect';

let interval

let Index = () => {
  const [isFileSupported, setIsFileSupported] = useState(true)
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [fileName, setFileName] = useState('')
  const [scanFile, setScanFile] = useState(false)
  const [file, setFile] = useState('')
  const [dropdownData, setDropdownData] = useState([])
  const [gradeDropdownData, setGradeDropdownData] = useState([])
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isSubmit, setIsSubmit] = useState(false)
  const [masterGradeImplementation, setMasterGradeImplementation] = useState(false);
  const [masterGradeOption, setMasterGradeOption] = useState([]);
 

  const [jobName, setJobName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [mill, setMill] = useState('')
  const [machine, setMachine] = useState('')
  const [targetVariable, setTargetVariable] = useState('')
  const [timeColumn, setTimeColumn] = useState('')
  const [gradeColumn, setGradeColumn] = useState('');
  const [notes, setNotes] = useState('');
  const [masterGrades,setMasterGrades] = useState([]);

  const [isValidJobName, setIsValidJobName] = useState(true)
  const [isValidcompanyName, setIsValidcompanyName] = useState(true)
  const [isValidmill, setIsValidmill] = useState(true)
  const [isValidmachine, setIsValidmachine] = useState(true)
  const [isValidtargetVariable, setIsValidtargetVariable] = useState(true)
  const [isValidtimeColumn, setIsValidtimeColumn] = useState(true)


  const [isJobSuccessfullyPosted, setJobSuccessfullyPosted] = useState(false)

  let handelStartANewJobClick = () => {
    setJobSuccessfullyPosted(false)
  }

  const dispatch = useDispatch()

  const { createJob } = useSelector((state) => state.jobs)
  const { userInfo } = useSelector((state) => state.auth.login)
  const {searchedJobInProgressJobs} = useSelector((state) => state.jobs)

  let handelSubmit = () => {
    let hasError = true;

    if (jobName.length > 0) {
      hasError = false;
      setIsValidJobName(true)
    } else {
      hasError = true;
      setIsValidJobName(false)
    }

    if (companyName.length > 0) {
      hasError = false;
      setIsValidcompanyName(true)
    } else {
      hasError = true;
      setIsValidcompanyName(false)
    }

    if (mill.length > 0) {
      hasError = false;
      setIsValidmill(true)
    } else {
      hasError = true;
      setIsValidmill(false)
    }

    if (machine.length > 0) {
      hasError = false;
      setIsValidmachine(true)
    } else {
      hasError = true;
      setIsValidmachine(false)
    }
    if (targetVariable && targetVariable.value) {
      hasError = false;
      setIsValidtargetVariable(true)
    } else {
      hasError = true;
      setIsValidtargetVariable(false)
    }

    if (timeColumn && timeColumn.value) {
      hasError = false;
      setIsValidtimeColumn(true)
    } else {
      hasError = true;
      setIsValidtimeColumn(false)
    }

    if (
      !hasError &&
      isValidJobName &&
      isValidcompanyName &&
      isValidmill &&
      isValidmachine &&
      isValidtargetVariable &&
      isValidtimeColumn &&
      isFileSupported &&
      userInfo.user_name !== '' && 
      !isSubmit
    ) {
      setIsSubmit(true)
      let jobDetails = {
        datafile: file,
        submittedBy: userInfo.user_name,
        submittedOn: dayjs().format('YYYY-MM-DD HH:mm'),
        jobName: jobName,
        company: companyName,
        mill: mill,
        machine: machine,
        targetVariable: targetVariable.value,
        timeColumn: timeColumn.value,
        gradeColumn: gradeColumn.value,
        masterGradeImplementation: masterGradeImplementation,
        masterGrades: masterGrades,
        notes: notes,
      }

      dispatch(jobsActions.createJob(jobDetails)).then((result) => {
        if (result.success) {
          setIsSubmit(false);
          setJobName('');
          setMachine('');
          setMill('');
          setNotes('');
          setTimeColumn('');
          setGradeColumn('');
          setTargetVariable('');
          setFile('');
          setProgress(0);
          clearInterval(interval);
          setInProgress(false);
          setMasterGradeImplementation(false);
          setMasterGradeOption([]);
          interval = undefined;
          setIsFileSelected(false);
          setIsFileSupported(true);
          setScanFile(false);
          setJobSuccessfullyPosted(true);
          
          
          let searchRequest = {
            'userInfo' :{
              'userId' : userInfo.id
            },
            'filterCriteria': {
              'keyword': '',
              'fromDate': '',
              'toDate': '',
              'selectedCompanies': [],
              'selectedMills': [],
              'selectedMachines': [],
              'selectedUsers': [],
              'selectedStatusOption': [],
              'listingType': 'inProgress'
            },
            'sortCriteria': {
              'sortColumn': 'id',
              'sortType': 'desc'
            },
            'pagingCriteria': {
              'itemsPerPage': 10,
              'selectedPageNumber': 1
            }
          }
          dispatch(jobsActions.searchProgressJobs(searchRequest))
        }
      })
    }
  }

  let getFileType = (fileName) => {
    return fileName.split('.')[1]
  }

  let toggle = () => setTooltipOpen(!tooltipOpen)

  

  const startTimer = () => {
    setProgress((prevState) => prevState + 10)
  }

  const handelScanFileClick = () => {
    setInProgress(true)
    scanCSVFile()
    interval = setInterval(startTimer, 200)
  }
  
  const onDrop = useCallback((acceptedFiles) => {
    let browseBtn = document.getElementById("btnBrowse");
    if(browseBtn !== null && browseBtn.getAttribute("disabled") === 'disabled'){
      return false;
    }

    if (getFileType(acceptedFiles[0].name) === 'csv') {
      setFileName(acceptedFiles[0].name)
      setIsFileSelected(true)
      setIsFileSupported(true)
      setFile(acceptedFiles[0])
    } else {
      setIsFileSupported(false)
    }
  }, [])
  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })
  
  useEffect(() => {
    if(searchedJobInProgressJobs && searchedJobInProgressJobs.usersLast30DaysJobsCount){
        let noOfJobs = parseInt(searchedJobInProgressJobs.usersLast30DaysJobsCount);
        if(noOfJobs >= 10){
          let browseBtn = document.getElementById("btnBrowse");
          if(browseBtn !== null && browseBtn.getAttribute("disabled") !== 'disabled'){
            document.getElementById("btnBrowse").setAttribute("disabled","disabled");
            document.getElementById("inputFileUpload").setAttribute("disabled","disabled");
            document.getElementById("jobLimitMessage").classList.remove("hide")
          }
        }
    }
  })

  useEffect(() => {
    if (progress === 100) {
      clearInterval(interval)
      setInProgress(false)
      setScanFile(true)
      setProgress(0)
    }
  }, [progress])

  let scanCSVFile = () => {
    var reader = new FileReader()
    reader.readAsText(file)
    reader.onload = function (e) {
      let row = reader.result.split(/\r\n|\n/);
      if (row.length > 0) {
        let cell = row[0].split(',')
        let tempCellData = [];
        let tempGradeCellData = [];
        tempGradeCellData.push({ label: 'Select Grade', value: null });

       for (let i = 0; i < cell.length; i++) {
          tempCellData.push({ label: cell[i], value: cell[i] }); 
        }
        tempGradeCellData = tempGradeCellData.concat(tempCellData)
        setDropdownData(tempCellData);
        setGradeDropdownData(tempGradeCellData);

        let masterGradeData = [];
        let gradeColNum = cell.findIndex(col => col.replace(/(\r\n|\n|\r)/gm, "").toLowerCase() === "grade");

        for(let i = 1; i < row.length - 1; i++){
          let cell = row[i].split(',');
          let column = cell[gradeColNum].replace(/(\r\n|\n|\r)/gm, "");
          if(!masterGradeData.some(masterGradeData => masterGradeData.label === column )) {
            masterGradeData.push({ label: column, value: column });
          }
        }
        setMasterGradeOption(masterGradeData);
      }
    }
  }

  return (
    <>
      {isJobSuccessfullyPosted ? (
        <Card>
          <CardBody className="successfully">
            <h3>SUCCESS !</h3>
            <span className="file-type">
              <b>Job ID: {createJob.createJobData.jobId}</b> was submitted successfully
            </span>
            <div className="row">
              <div className="col px-2">
                <Link  to={'/job-in-progress/' + createJob.createJobData.jobId}>
                  <Button type="button" className="btn-lg" id="btnViewJobs">
                    View Job
                  </Button>
                </Link>
              </div>
              <div className="col px-2">
                {/* <Link to="/dashboard"> */}
                <Button
                  type="button"
                  className="active btn-lg"
                  id="btnViewJobs"
                  onClick={handelStartANewJobClick}
                    > Start A New Job
                  </Button>
                {/* </Link> */}
              </div>
            </div>
          </CardBody>
        </Card>
      ) : !scanFile ? (
        <Card>
          <CardBody>
            <div   {...getRootProps()}>
              <div id="dragDropDiv"
                className="drag-drop-middle" 
                onDrop={(acceptedFiles) => console.log(acceptedFiles)}
              >
                {/* <input/> */}
                {isFileSupported && !isFileSelected ? (
                  <>
                    <h3>START A JOB BY DRAG AND DROP THE FILE</h3>
                    <span className="file-type">Accepted file type: CSV</span>
                  </>
                ) : !isFileSelected && !isFileSupported ? (
                  <span className="file-type text-danger">
                    <img alt="" className="error-icon" src={errorRed}></img>
                    File type not supported. Accepted file type: CSV
                  </span>
                ) : (
                  ''
                )}

                {!isFileSelected ? (
                  <div>
                    <Button type="button" id="btnBrowse">
                      <input id="inputFileUpload" {...getInputProps()} />
                      Browse
                    </Button>
                  </div>
                ) : !inProgress ? (
                  <>
                    <div className="file-progress">
                      <span className="file-icon">
                        <i className="icon-source-file"></i>
                      </span>
                      <span className="file-name">
                        {fileName}
                        <i
                          className="icon-cancel"
                          onClick={() => {
                            setIsFileSelected(false)
                            setIsFileSupported(true)
                          }}
                        ></i>
                      </span>
                    </div>
                    <div>
                      <Button
                        type="button"
                        id="btnScanFile"
                        className="active"
                        onClick={() => {
                          handelScanFileClick(true)
                        }}
                      >
                        Scan File
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="file-progress">
                      <span className="file-icon">
                        <i className="icon-source-file"></i>
                      </span>
                      <span className="file-name">
                        {fileName}
                        <i
                          className="icon-cancel"
                          onClick={(event) => {
                            event.preventDefault()
                            setProgress(0)
                            clearInterval(interval)
                            setInProgress(false)
                            interval = undefined
                            setIsFileSelected(false)
                            setIsFileSupported(true)
                            setScanFile(false)
                          }}
                        ></i>
                      </span>
                      <Progress value={progress} />
                    </div>
                    <div>
                      <Button
                        type="button"
                        id="btnCancelScan"
                        onClick={(event) => {
                          event.preventDefault()
                          setProgress(0)
                          clearInterval(interval)
                          setInProgress(false)
                          interval = undefined
                          setIsFileSelected(false)
                          setIsFileSupported(true)
                          setScanFile(false)
                        }}
                      >
                        Cancel Scan
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <span className="down-link">
                    Download <a href={getFile} download="sampleData.csv"><strong>sample</strong></a><span> or </span>
                    <a href={getRedmeFile} download="README.txt"><strong>readme</strong></a> file
            </span>
            <br />
            <div>              
              <span id="jobLimitMessage" className="hide">
                <h3 className="job-limit-message-color">You have reached your monthly job limit</h3></span>
            </div>
          </CardBody>
        </Card>
      ) : (
        <div className="row">
          <div className="col-md-5 pr-lg-5">
            <Card>
              <CardBody>
                <div className="file-progress">
                  <span className="file-icon file-scanned-icon">
                    <i className="icon-file-scanned"></i>
                  </span>
                  <span className="file-name">
                    {fileName}
                    <i
                      className="icon-cancel"
                      onClick={() => {
                        setIsFileSelected(false)
                        setIsFileSupported(true)
                        setScanFile(false)
                      }}
                    ></i>
                  </span>
                </div>
                <div>
                  <Button
                    type="button"
                    id="btnCancelJob"
                    onClick={() => {
                      setIsFileSelected(false)
                      setIsFileSupported(true)
                      setScanFile(false)
                    }}
                  >
                    Cancel Job
                  </Button>
                </div>
                <span className="down-link">
                    Download <a href={getFile} download="sampleData.csv"><strong>sample</strong></a> or  
                    <a href={getRedmeFile} download="README.txt"><strong> readme</strong></a> file
                </span>
              </CardBody>
            </Card>
          </div>
          <div className="col-md-7 create-job-form">
            <div className="row g-lg-5">
              <div className="col-md-4">
                <ul className="user-fill-name">
                { userInfo ? <li>
                     <span>Submitted by:</span> <span>{userInfo.user_name}</span> 
                  </li> : ''}
                  <li>
                    <span>Submitted on:</span>{' '}
                    <span>{dayjs().format('YYYY-MM-DD HH:mm')}</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-8">
                <div
                  className={
                    isValidJobName
                      ? 'form-label-group position-relative'
                      : 'form-label-group position-relative error-invalid'
                  }
                >
                  <label>
                    Job Name <sup>*</sup>
                  </label>
                  <input
                    id="txtJobName"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => setJobName(e.target.value)}
                  />
                  <div
                    className={
                      isValidJobName
                        ? 'invalid-feedback'
                        : 'invalid-feedback d-flex'
                    }
                  >
                    <img alt="" src={errorRed} /> <span>Enter Job Name.</span>
                  </div>

                  <div className="info">
                    <i
                      onClick={toggle}
                      className="icon-help"
                      id="TooltipExample"
                    ></i>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen}
                      target="TooltipExample"
                      // toggle={toggle}
                    >
                      Give your Job an easily distinguishable and descriptive
                      name that you and others can easily refer to.
                      <br />
                      <h4>Ex: Raw Material Usage (daily) - Q1</h4>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={
                    isValidcompanyName
                      ? 'form-label-group'
                      : 'form-label-group  error-invalid'
                  }
                >
                  <label>
                    Company <sup>*</sup>
                  </label>
                  <input
                    id="txtCompany"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <div
                    className={
                      isValidcompanyName
                        ? 'invalid-feedback'
                        : 'invalid-feedback d-flex'
                    }
                  >
                    <img alt="" src={errorRed} />{' '}
                    <span>Enter Company Name.</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={
                    isValidmill
                      ? 'form-label-group'
                      : 'form-label-group  error-invalid'
                  }
                >
                  <label>
                    Mill <sup>*</sup>
                  </label>
                  <input
                    id="txtMill"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => setMill(e.target.value)}
                  />
                  <div
                    className={
                      isValidmill
                        ? 'invalid-feedback'
                        : 'invalid-feedback d-flex'
                    }
                  >
                    <img alt="" src={errorRed} /> <span>Enter Mill Name.</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={
                    isValidmachine
                      ? 'form-label-group'
                      : 'form-label-group  error-invalid'
                  }
                >
                  <label>
                    Machine <sup>*</sup>
                  </label>
                  <input
                    id="txtMachine"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => setMachine(e.target.value)}
                  />
                  <div
                    className={
                      isValidmachine
                        ? 'invalid-feedback'
                        : 'invalid-feedback d-flex'
                    }
                  >
                    <img alt="" src={errorRed} />{' '}
                    <span>Enter Machine Name.</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={
                    isValidtargetVariable
                      ? 'form-label-group'
                      : 'form-label-group  error-invalid'
                  }
                >
                  <label>
                    Target Variable <sup>*</sup>
                  </label>
                  <div className="select-custom">
                    <ReactSelect
                      isClearable={false}
                      id="selTargetVariable"
                      classNamePrefix="form-control"
                      onChange={(e) => setTargetVariable(e)}
                      options={dropdownData}
                      placeholder="Select Variable"
                      value={targetVariable}
                    ></ReactSelect>
                    <div
                      className={
                        isValidtargetVariable
                          ? 'invalid-feedback'
                          : 'invalid-feedback d-flex'
                      }
                    >
                      <img alt="" src={errorRed} />{' '}
                      <span>Select Target Variable.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={
                    isValidtimeColumn
                      ? 'form-label-group'
                      : 'form-label-group  error-invalid'
                  }
                >
                  <label>
                    time column <sup>*</sup>
                  </label>
                  <div className="select-custom">
                    <ReactSelect
                      isClearable={false}
                      id="selTargetVariable"
                      classNamePrefix="form-control"
                      onChange={(e) => setTimeColumn(e)}
                      options={dropdownData}
                      placeholder="Select Variable"
                      value={timeColumn}
                    ></ReactSelect>
                    {/* <select
                      id="selTimeColumn"
                      className="form-control"
                      onChange={(e) => setTimeColumn(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Variable
                      </option>
                      {dropdownData.map((data) => {
                        return <option value={data.value}>{data.value}</option>
                      })}
                    </select> */}
                    <div
                      className={
                        isValidtimeColumn
                          ? 'invalid-feedback'
                          : 'invalid-feedback d-flex'
                      }
                    >
                      <img alt="" src={errorRed} />{' '}
                      <span>Select Time Column.</span>
                    </div>
                  </div>
                </div>
              </div>

             {/*  Grade Column    */}
             <div className="col-md-4">
               <div
               className='form-label-group'>
               <label>
                    Grade column 
                  </label>
                  <div className="select-custom">
                  <ReactSelect
                  isClearable={false}
                  id="selTargetGrade"
                  classNamePrefix="form-control"
                  onChange={(e) => setGradeColumn(e)}
                  options={gradeDropdownData}
                  placeholder="Select Grade"
                  value={gradeColumn}
                  ></ReactSelect>

                  </div>
               </div>
             </div>
                {/*  Grade Column End    */}

                 {/*  Master Grade Implementation    */}
             <div className="col-md-6 col-lg-4 ">
               <div
               className='form-label-group grade-check-label'>
                  <label  className="form-check-label" htmlFor="masterGradeCheckbox">
                    Master Grade Implementation
                  </label>
                 <input id="masterGradeCheckbox" type="checkbox" className="form-check-input" 
                      checked={masterGradeImplementation}
                      onChange={() => setMasterGradeImplementation(!masterGradeImplementation)}/>
                
                      
               </div>
             </div>
              {/*  Master Grade Implementation End    */}
       
        {masterGradeImplementation &&  <MasterGradeSelect
          options={masterGradeOption}
          setMasterGrades = {setMasterGrades}
          /> 
          }
              <div className="col-lg-4 ">
                <div className="form-label-group">
                  <label>Notes</label>
                  <textarea
                    style={{minHeight:"100px"}}
                    id="txtNote"
                    className="form-control textarea"
                    onChange={(e) => setNotes(e.target.value)}
                  ></textarea>
                </div>
              </div>
              
              <div className="col-12 text-right">
                <button
                  type="button"
                  id="btnSubmitJob"
                  className="btn active btn-lg"
                  onClick={() => handelSubmit()}
                >
                  Submit Job
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Index
