import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ScrollIntoView from './components/ScrollIntoView';

// import history from './history'

import Login from './views/login'
import Dashboard from './views/Dashboard'
import JobsInProgress from './views/JobsInProgress'
import Search from './views/Search'
import CompletedJobs from './views/CompletedJobs'
// import JobInProgressByJobId from './views/JobInProgressByJobId'
import JobByJobId from './views/JobByJobId'

// import Layout from './components/Layout'

let PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

let AppRouter = () => {
  const { isValidUser } = useSelector((state) => state.auth.login)

  const ScrollToTop = () => {
    window.scrollTo(0, 0)
    return null
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH} onUpdate={() => window.scrollTo(0, 0)}>
      {/* <Route component={ScrollToTop} /> */}
      <ScrollIntoView>
        <Switch>
          <Route exact path="/" component={isValidUser ? Dashboard : Login} />
          <PrivateRoute
            exact
            authed={isValidUser}
            path="/dashboard"
            component={Dashboard}
          />
          <PrivateRoute
            exact
            authed={isValidUser}
            path="/search"
            component={Search}
          />
          <PrivateRoute
            exact
            authed={isValidUser}
            path="/jobs-in-progress"
            component={JobsInProgress}
          />
          <PrivateRoute
            exact
            authed={isValidUser}
            path="/completed-jobs"
            component={CompletedJobs}
          />
          <PrivateRoute
            exact
            authed={isValidUser}
            path="/job-in-progress/:jobId"
            component={JobByJobId}
          />
          <PrivateRoute
            exact
            authed={isValidUser}
            path="/completed-job/:jobId"
            component={JobByJobId}
          />
          {/* <Route path="/dashboard"  component={Dashboard} />
          <Route path="/search"  component={Search} />
          <Route path="/jobs-in-progress"  component={JobsInProgress} />
          <Route path="/completed-jobs"  component={CompletedJobs} /> */}
        </Switch>
      </ScrollIntoView>
    </BrowserRouter>
  )
}

export default AppRouter
