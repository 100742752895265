import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

let Index = (props) => {
  
  const data = props.chartData ? props.chartData.errorReductionPlotData : [];
  
  let shortErrorReductionData = [];
  let longErrorReductionData = [];
  let xAxis = [];
  for(let j = 0;  j <= data.length -1; j++ ) {
    let obj = data[j];
    xAxis.push(obj.numberOfRecords)
    if(obj.timeRange == "short_run"){
      shortErrorReductionData.push([obj.numberOfRecords, obj.metricValue]);
    } else {
      longErrorReductionData.push([obj.numberOfRecords, obj.metricValue]);
    }
  }

  const chartOptions = {
    chart: {
      panning: {
        enabled: true,
        type: 'xy',
      },
      panKey: 'alt',
      zoomType: 'xy',
      height: 540
    },

    title: {
      text: '',
    },
    yAxis: {
      min: -0.5,
      max : 1,
      title: {
        text: 'Error Reduction',
      },
    },

    xAxis: {
      title: {
        text: 'No of Records',
      },
      categories: xAxis,
      labels: {
        rotation: -90,
        useHTML: true,
        style: {
          textOverflow: 'clip'
        }
      
    }
  },

    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top'
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Short Error Reduction',
        type:'line',
        data: shortErrorReductionData,
        color: '#FFA500',
        turboThreshold: 0
      },
      {
        name: 'Long Error Reduction',
        type:'line',
        data: longErrorReductionData,
        color: '#800080',
        turboThreshold: 0

      },
    ],
  
  }
  return (
    <div className='prediction-system'>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
