import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const Index = (props) => {
  setTimeout(() => {
    if (props.modal === true) props.toggle()
  }, 3000)

  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-lg success-modal"
        isOpen={props.modal}
        toggle={props.toggle}
      >
        <ModalHeader toggle={props.toggle}></ModalHeader>
        <ModalBody>
          <h2>Success!</h2>
          <p>Email was sent succeessfully.</p>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Index
