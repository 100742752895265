import React, { useState, useEffect } from 'react'
import Tables from './Table'
import Paginations from './Pagination'
// import { getThemeColor } from '../../actions'
import TableContext from './TableContext'
import ShowEntries from './ShowEntries'
// import AppContext from './context/AppContext'
// import { Form, Button } from 'reactstrap';
// import useWindowDimensions from './hooks/windowDimensions'
// import { DatatableFilter } from './DatatableFilter'
// import FilterModal from './FilterModal';

let Index = (props) => {

  // const initFilters = {
  //   selectedTagNames: [],
  //   selectedTagLocations: [],
  //   showImportantTagsOnly: false,
  // }
  const [visibleResult] = useState(props.isResultVisible || false)

  const [paginationActive, setPaginationActive] = useState(1)
  const [records, setRecords] = useState({ rows: [], columns: [] })
  const [extendedColumns, setExtendedColumns] = useState(
    props.extendedColumns || []
  )
  const [entriesOptions] = useState(props.entriesOptions || ['10', '25', '50'])
  const [showEntry, setShowEntry] = useState(
    props.showEntry || entriesOptions[0]
  )
  const [theme, setTheme] = useState(null)
  const [sortData, setSortData] = useState({ type: props.defaultSortType, field: props.defaultSort })
  // const [filterData, setFilterData] = useState(props.filterData)
  // const [filterModal, setFilterModal] = useState(false)
  // let [filterListCount, setFilterListCount] = useState(1)
  // const [selectedFilters, setSelectedFilters] = useState(initFilters)
  // const [selectedFilterLength, setSelectedFilterLength] = useState(0)
  // const context = useContext(AppContext)
  // const { width } = useWindowDimensions()

  // useEffect(() => {
  //   let count = 0
  //   Object.keys(selectedFilters).forEach((items) => {
  //     if (selectedFilters[items].length) {
  //       return (count = count + selectedFilters[items].length)
  //     }
  //     if (selectedFilters['showImportantTagsOnly']) {
  //       return (count = count + 1)
  //     }
  //   })
  //   setSelectedFilterLength(count)
  //   setFilterListCount(1)
  // }, [selectedFilters])

  // const [postObj, setPostObj] = useState({})
  useEffect(() => {
    const getTheme = async () => {
      const theme = {
        appColor: '#0054BB',
        rootGradColor1: '#E6EEF8',
        rootGradColor2: '#FFEAF0',
        actualLineColor: '#5E92D3',
        expandLineColor: '#3A7BCA',
        actualGradColor: '#CCDDF1',
        expandGradColor: '#1763C1',
        notifyButtonColor: '#1A65C2',
      }
      setTheme(theme)
    }
    // setFilterData(props.filterData)
    getTheme()
  }, [props])

  useEffect(() => {
    setPaginationActive(props.selectedPageNumber);
  },[props.selectedPageNumber])

  useEffect(() => {
    let rows = props.data || []
    let columns = props.columns || []
    setRecords({ rows, columns })
    setExtendedColumns(props.extendedColumns)
    if (props.defaultSort) {
      setSortData({
        type: props.defaultSortType ? props.defaultSortType : '',
        field: props.defaultSort,
      })
    }
  }, [
    props.data,
    props.columns,
    props.defaultSort,
    props.defaultSortType,
    props.extendedColumns,
  ])

  // const handleChange = (e) => {
  //   setPostObj({
  //     ...postObj,
  //     [e.target.name]:
  //       e.target.type === 'checkbox' ? e.target.checked : e.target.value,
  //   })
  // }

  const entryChange = (e) => {
    
    setShowEntry(e.target.value)
    props.fetchMoreData(paginationActive, e.target.value, sortData.type, sortData.field)
    // setPaginationActive(1)
  }

  const paginationClick = (page) => {
    props.clearExpanded()
    setPaginationActive(page)
    props.fetchMoreData(page, showEntry, sortData.type, sortData.field)
    if (props.sectionRef) {
      window.scrollTo({
        top: props.sectionRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const sortClick = (type, field) => {
    setSortData({ type, field })
    props.fetchMoreData(paginationActive, showEntry, type, field)
  }

  // const filterSubmit = (filters) => {
  //   props.filterSubmit(filters)
  //   setSelectedFilters(filters)
  //   setFilterModal(false)
  // }

  // const clearFilters = () => {
  //   setSelectedFilters(initFilters)
  //   setFilterData(props.filterData)
  // }

  // const removeFilter = (field, value) => {
  //   let updateFilter = { ...selectedFilters }
  //   if (field === 'showImportantTagsOnly') {
  //     updateFilter[field] = value
  //     return setSelectedFilters(updateFilter)
  //   }
  //   let index = updateFilter[field].indexOf(value)
  //   updateFilter[field].splice(index, 1)
  //   return setSelectedFilters(updateFilter)
  // }

  // const updateRecords = (e) => {
  //   console.log(postObj)
  // }

  return (
    <TableContext.Provider
      value={{
        data: records,
        extended: extendedColumns,
        theme: theme,
        showEntry,
        entriesOptions,
        paginationActive,
        sort: sortData,
        sortClick,
        paginationClick,
        entryChange,
      }}
    >
      <div className="mt-4 mb-4 text-right">
        {visibleResult ? (
          <div className="row">
            <div className="col-lg-5 text-left">
              <span className="table-showing-result">
                {records.rows.length} Results
              </span>
            </div>
            <div className="col-lg-7">
              <ul className="showEntries">
                <li>
                  {'showing'}{' '}
                  <b>
                    {records.rows.length > showEntry
                      ? showEntry
                      : records.rows.length}{' '}
                  </b>
                  {'of'}{' '}
                  <b>
                    {' '}
                    {props.totalCount} {'jobs'}
                  </b>
                </li>
                <li className="dropdown">
                  <ShowEntries />
                </li>
                <li>{'Items per page'}</li>
              </ul>
            </div>
          </div>
        ) : (
          <ul className="showEntries">
            <li>
              {'showing'}{' '}
              <b>
                {records.rows.length > showEntry
                  ? showEntry
                  : records.rows.length}{' '}
              </b>
              {'of'}{' '}
              <b>
                {' '}
                {props.totalCount} {'jobs'}
              </b>
            </li>
            <li className="dropdown">
              <ShowEntries />
            </li>
            <li>{'Items per page'}</li>
          </ul>
        )}
      </div>

      <div className="tableCard">
        <Tables />
      </div>

      <div className="mt-4 mb-4 d-flex align-items-center">
        <Paginations totalCount={props.totalCount}/>
        <div className="float-right ml-auto">
          <ul className="showEntries">
            <li>
              {'showing'}{' '}
              <b>
                {records.rows.length > showEntry
                  ? showEntry
                  : records.rows.length}{' '}
              </b>
              {'of'}{' '}
              <b>
                {' '}
                {props.totalCount} {'jobs'}
              </b>
            </li>
            <li className="dropdown">
              <ShowEntries />
            </li>
            <li>{'Items per page'}</li>
          </ul>
        </div>
      </div>
    </TableContext.Provider>
  )
}

export default Index
