// import history from '../../../history'
import { AES } from 'crypto-js'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axiosInstance from '../../../config/axiosConfig'

var CryptoJS = require('crypto-js');

export const login = (user) => async (dispatch) => {

  let password = user.encryptedPassword;
  let salt = 'salt';
  let iterations = 128;
  let bytes = CryptoJS.PBKDF2('kSv3dxTMqe', salt, { keySize: 48, iterations: iterations });
  let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
  let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

  let ciphertext = AES.encrypt(password, key, { iv: iv });

  let bodyData = {
    username: user.username,
    encryptedPassword: ciphertext.toString(),
  }
  
  let userLogin = await axiosInstance.post('/common/login', bodyData);
  if (userLogin && userLogin.data && userLogin.data.success) {
    localStorage.setItem("access_token", userLogin.data.access_token)
    dispatch({
      type: 'LOGIN',
      payload: {
        isValidUser: true,
        userInfo: userLogin.data.user_details[0]
      },
    })
    await toast.dismiss()
    toast.success('Login Successfully', {
      position: toast.POSITION.TOP_RIGHT,
    })
    return await {status: true}
  } else {
    dispatch({
      type: 'LOGIN',
      payload: {
        isValidUser: false,
      },
    })
    await toast.dismiss()
    toast.error(userLogin.data.errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
    return await {status: false}
  }
}


export const logout = () => async (dispatch) => {
  
  let userLogout = await axiosInstance.delete('/common/logout');
  localStorage.clear()
  dispatch({
    type: 'USER_LOGOUT',
    payload: {
      isValidUser: false,
    },
  })
  await toast.dismiss()
  toast.success('Logout Successfully', {
    position: toast.POSITION.TOP_RIGHT,
  })
  return true
}
