import React from 'react'
import classNames from 'classnames'
import { LoaderBlock } from './loaderStyle'

import loaderGif from '../../public/images/gear.gif'

const Loader = props => {
    return (
        <LoaderBlock className={ classNames({'show': props.show}) }>
            <img src={loaderGif} alt="loading" />
        </LoaderBlock>
    )
}

export default Loader