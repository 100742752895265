import axios from 'axios'
import {STATUS_CODE_401} from '../constants/errorConstants'
require('dotenv').config()

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH, 
})

axiosInstance.interceptors.request.use(
  
  config => {
    const token = localStorage.getItem("access_token")
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
  },
  error => {

      Promise.reject(error)
  });

  axiosInstance.interceptors.response.use((response) => {
   if (response.config.parse) {
     
        //perform the manipulation here and change the response object
    }
    return response;
}, (error) => {
  if (STATUS_CODE_401 === error.response.status) {
    axiosInstance.delete('/common/logout');
    localStorage.clear()
    window.location = "/";
  }
    return Promise.reject(error.message);
});

export default axiosInstance
