const initialState = {
    headerData: {},
  }
  
  export const Header = (state = initialState, action) => {
    switch (action.type) {
      case 'HEADER_DATA':
        return Object.assign({}, state, {
            headerData: action.payload.headerData,
        })
      default:
        return state
    }
  }
  