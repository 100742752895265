import { combineReducers } from 'redux'
import { Header } from './headerReducer'
import { Footer } from './footerReducer'
import { Filter } from './filterReducer'

const generalReducer = combineReducers({
    Header,
    Footer,
    Filter
})

export default generalReducer
