import React, { useState, useEffect } from 'react'
import {
  Button,
  Collapse,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import jobsActions from '../../redux/actions/jobs'
import general from '../../redux/actions/general'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Datatable from '../../components/Datatable'
import CreateJob from '../../components/CreateJob'
import ShareJobModal from '../../components/ShareJobModal'
import JobInProgressFilterModal from '../../components/FilterModal'
import CompletedJobsFilterModal from '../../components/FilterModal'
import { Link } from 'react-router-dom'
import { isAdminRole } from '../../AutominerUtility'

let Index = () => {

  const dispatch = useDispatch();

  const [completedJobSearchValue, setCompletedJobSearchValue] = useState({
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
    user: 'Everyone',
    specificUsers: [],
    status: ['Any'],
    company: [],
    mill: [],
    machine: [],
    keyword: '',
    selectedUsers: [],
    selectedCompanies: [],
    selectedMills:[],
    selectedMachines: [],
    selectedStatusOption: [],
    sortColumn: 'id',
    sortType: 'desc',
    itemsPerPage: 10,
    selectedPageNumber: 1
  })
  const [jobInProgressSearchValue, setJobInProgressSearchValue] = useState({
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
    user: 'Everyone',
    specificUsers: [],
    status: ['Any'],
    company: [],
    mill: [],
    machine: [],
    keyword: '',
    selectedUsers: [],
    selectedCompanies: [],
    selectedMills:[],
    selectedMachines: [],
    selectedStatusOption: [],
    sortColumn: 'id',
    sortType: 'desc',
    itemsPerPage: 10,
    selectedPageNumber: 1
  })
  const [completedJobSearchTags, setCompletedJobSearchTags] = useState([])
  const [isCompletedJogTagExpanded, setCompletedJogTagTagExpanded] = useState(false)
  const [progressJobSearchTags, setProgressJobSearchTags] = useState([])
  const [isProgressJobTagExpanded, setProgressJobTagExpanded] = useState(false)

  const {searchedCompletedJobs} = useSelector((state) => state.jobs)
  const {searchedJobInProgressJobs} = useSelector((state) => state.jobs)
  const {filterData} = useSelector((state) => state.general.Filter)
  const {userInfo} = useSelector((state) => state.auth.login)

  const [tableData, setTableData] = useState([])
  const [startANewJobOpen, setStartANewJobOpen] = useState(true)
  const [shareJobModal, setShareJobModal] = useState(false)
  const [jobInProgressModal, setJobInProgressModal] = useState(false)
  const [completedJobModal, setCompletedJobModal] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [jobProgressType, setJobProgressType] = useState('allJob')
  const [completedJobType, setCompletedJobType] = useState('allJob')
  const [extendedColumns, setExtendedColumns] = useState([])
  const [jobTitle, setJobTitle] = useState('')
  const [jobDetails, setJobDetails] = useState('')
  const [jobIdForStop, setJobIdForStop] = useState(-1)

  const jobInProgressTimerRef = React.useRef(null);
  const jobsInProgressRef = React.useRef()
  const completedJobsRef = React.useRef()

  const shareJobModalToggle = () => setShareJobModal(!shareJobModal)
  
  const jobInProgressToggle = () => {
    setJobInProgressModal(!jobInProgressModal)
    if(!jobInProgressModal) {
      dispatch(general.getFilterData({userId: userInfo.id, listingType: 'InProgress'}))
    }
  }
  const completedJobToggle = () => {
    setCompletedJobModal(!completedJobModal)
    if(!completedJobModal) {
      dispatch(general.getFilterData({userId: userInfo.id, listingType: 'Completed'}))
    }
  }

  useEffect(() => {
  },[dispatch, userInfo])
  
  useEffect(() => {
    
    let searchRequest = {
      'userInfo' :{
        'userId' : userInfo.id
      },
      'filterCriteria': {
        'keyword': completedJobSearchValue.keyword,
        'fromDate': completedJobSearchValue.fromDate,
        'toDate': completedJobSearchValue.toDate,
        'selectedCompanies':completedJobSearchValue.selectedCompanies,
        'selectedMills': completedJobSearchValue.selectedMills,
        'selectedMachines': completedJobSearchValue.selectedMachines,
        'selectedUsers': completedJobSearchValue.selectedUsers,
        'selectedStatusOption': completedJobSearchValue.selectedStatusOption,
        'listingType': 'completed'
      },
      'sortCriteria': {
        'sortColumn': completedJobSearchValue.sortColumn,
        'sortType': completedJobSearchValue.sortType
      },
      'pagingCriteria': {
        'itemsPerPage': completedJobSearchValue.itemsPerPage,
        'selectedPageNumber': completedJobSearchValue.selectedPageNumber
      }
    }
    dispatch(jobsActions.searchCompletedJobs(searchRequest))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, completedJobSearchValue])

  useEffect(() => {
    let searchRequest = {
      'userInfo' :{
        'userId' : userInfo.id
      },
      'filterCriteria': {
        'keyword': jobInProgressSearchValue.keyword,
        'fromDate': jobInProgressSearchValue.fromDate,
        'toDate': jobInProgressSearchValue.toDate,
        'selectedCompanies':jobInProgressSearchValue.selectedCompanies,
        'selectedMills': jobInProgressSearchValue.selectedMills,
        'selectedMachines': jobInProgressSearchValue.selectedMachines,
        'selectedUsers': jobInProgressSearchValue.selectedUsers,
        'selectedStatusOption': jobInProgressSearchValue.selectedStatusOption,
        'listingType': 'inProgress'
      },
      'sortCriteria': {
        'sortColumn': jobInProgressSearchValue.sortColumn,
        'sortType': jobInProgressSearchValue.sortType
      },
      'pagingCriteria': {
        'itemsPerPage': jobInProgressSearchValue.itemsPerPage,
        'selectedPageNumber': jobInProgressSearchValue.selectedPageNumber
      }
    }
    dispatch(jobsActions.searchProgressJobs(searchRequest))
    jobInProgressTimerRef.current = setInterval(() => {
      dispatch(jobsActions.searchProgressEveryMinJobs(searchRequest))
    }, 10000);
    return () => {
      // componentWillUnmount events
      clearInterval(jobInProgressTimerRef.current)
    }
  }, [dispatch, jobInProgressSearchValue])

  const filterSubmit = (filters) => {
    console.log(filters)
  }

  const clearExpanded = () => {
    let updateTableData = [...tableData]
    updateTableData = updateTableData.filter((item) => {
      item.collapsable = false
      return !item.expanded
    })
    setExtendedColumns([])
    setTableData(updateTableData)
  }

  let handelShareJob = (jobTitle, jobDetails) => {
    return;
    setJobDetails(jobDetails)
    setJobTitle(jobTitle)
    shareJobModalToggle()
  }

  let stopJobConfirmation = (jobTitle, jobId) => {
    setJobTitle(jobTitle)
    setJobIdForStop(JSON.stringify(jobId))
    setConfirmationModalOpen(true)
  }

  let handelStopJob = () => {
    dispatch(jobsActions.stopJobProgress({'jobId': jobIdForStop, 'userName': userInfo.user_name})).then((result) => {
     if(result.status) {
       setConfirmationModalOpen(false)
       let searchRequest = {
        'userInfo' :{
          'userId' : userInfo.id
        },
        'filterCriteria': {
          'keyword': jobInProgressSearchValue.keyword,
          'fromDate': jobInProgressSearchValue.fromDate,
          'toDate': jobInProgressSearchValue.toDate,
          'selectedCompanies':jobInProgressSearchValue.selectedCompanies,
          'selectedMills': jobInProgressSearchValue.selectedMills,
          'selectedMachines': jobInProgressSearchValue.selectedMachines,
          'selectedUsers': jobInProgressSearchValue.selectedUsers,
          'selectedStatusOption': jobInProgressSearchValue.selectedStatusOption,
          'listingType': 'inProgress'
        },
        'sortCriteria': {
          'sortColumn': jobInProgressSearchValue.sortColumn,
          'sortType': jobInProgressSearchValue.sortType
        },
        'pagingCriteria': {
          'itemsPerPage': jobInProgressSearchValue.itemsPerPage,
          'selectedPageNumber': jobInProgressSearchValue.selectedPageNumber
        }
      }
      dispatch(jobsActions.searchProgressJobs(searchRequest))
     }
    })
  }

  const fetchMoreCompletedJobData = (page, showEntries, sortType, sortField) => {
    setCompletedJobSearchValue({
      ...completedJobSearchValue,
      selectedPageNumber: page,
      itemsPerPage: JSON.parse(showEntries),
      sortColumn: sortField,
      sortType: sortType,
    })
  }

  const cancelCompletedJobFilter = (previousSelection) => {
    setCompletedJobSearchValue(previousSelection)
  }

  const filterCompletedJobTable = (filterRequest, type, searchTages) => {
    setCompletedJobSearchTags(searchTages)
    if(type === 'completed') {
      setCompletedJobSearchValue({
        fromDate: filterRequest.fromDate,
        fromTime: filterRequest.fromTime,
        toDate: filterRequest.toDate,
        toTime: filterRequest.toTime,
        user: filterRequest.user,
        specificUsers: filterRequest.specificUsers,
        status: filterRequest.status,
        company: filterRequest.company,
        mill: filterRequest.mill,
        machine: filterRequest.machine,
        keyword: filterRequest.keyword,
        selectedUsers: filterRequest.selectedUsers,
        selectedCompanies: filterRequest.selectedCompanies,
        selectedMills: filterRequest.selectedMills,
        selectedMachines: filterRequest.selectedMachines,
        selectedStatusOption: filterRequest.selectedStatusOption,
        sortColumn: filterRequest.sortColumn,
        sortType: filterRequest.sortType,
        itemsPerPage: filterRequest.itemsPerPage,
        selectedPageNumber: 1
      })
    }
  }

  const fetchMoreProgressdJobData = (page, showEntries, sortType, sortField) => {
    setJobInProgressSearchValue({
      ...jobInProgressSearchValue,
      selectedPageNumber: page,
      itemsPerPage: JSON.parse(showEntries),
      sortColumn: sortField,
      sortType: sortType,
    })
  }

  const cancelProgressJobFilter = (previousSelection) => {
    setJobInProgressSearchValue(previousSelection)
  }
  
  const filterJobInProgressJobTable = (filterRequest, type, searchTages) => {
    setProgressJobSearchTags(searchTages)
    if(type === 'inProgress') { 
      setJobInProgressSearchValue({
        fromDate: filterRequest.fromDate,
        fromTime: filterRequest.fromTime,
        toDate: filterRequest.toDate,
        toTime: filterRequest.toTime,
        user: filterRequest.user,
        specificUsers: filterRequest.specificUsers,
        status: filterRequest.status,
        company: filterRequest.company,
        mill: filterRequest.mill,
        machine: filterRequest.machine,
        keyword: filterRequest.keyword,
        selectedUsers: filterRequest.selectedUsers,
        selectedCompanies: filterRequest.selectedCompanies,
        selectedMills: filterRequest.selectedMills,
        selectedMachines: filterRequest.selectedMachines,
        selectedStatusOption: filterRequest.selectedStatusOption,
        sortColumn: filterRequest.sortColumn,
        sortType: filterRequest.sortType,
        itemsPerPage: filterRequest.itemsPerPage,
        selectedPageNumber: 1
      })
    }
  }

  let removeCompletedJobTag = (index) => {
    let removeItem = completedJobSearchTags[index]
    setCompletedJobSearchTags(completedJobSearchTags.filter((item, i) => i !== index))

    if (removeItem.key === 'keyword') {
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        keyword: '',
      })
    } else if (removeItem.key === 'company') {
      let searchValueCompany = completedJobSearchValue.company
      let selectedCompanies = completedJobSearchValue.selectedCompanies
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        company: searchValueCompany.filter((d) => d.label !== removeItem.label),
        selectedCompanies: selectedCompanies.filter((d) => d !== removeItem.label),
      })

      if (searchValueCompany.length === 1) {
        setCompletedJobSearchValue({
          ...completedJobSearchValue,
          company: [],
          mill: [],
          machine: [],
          selectedCompanies: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = completedJobSearchTags.filter((d) => d.key !== 'company' && d.key !== 'mill' && d.key !== 'machine' )
        setCompletedJobSearchTags(
          tempSearchTags
        )
        // let tempSearchTags = completedJobSearchTags
        // setCompletedJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'mill'),
        //     tempSearchTags.length
        //   )
        // )
        // setCompletedJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'machine'),
        //     tempSearchTags.length
        //   )
        // )
      }
    } else if (removeItem.key === 'mill') {
      let searchValueMills = completedJobSearchValue.mill
      let searchSelectedMills = completedJobSearchValue.selectedMills
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        mill: searchValueMills.filter((d) => d.value !== removeItem.label),
        selectedMills: searchSelectedMills.filter((d) => d !== removeItem.label),
      })
      if (searchValueMills.length === 1) {
        setCompletedJobSearchValue({
          ...completedJobSearchValue,
          mill: [],
          machine: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = completedJobSearchTags.filter((d) => d.key !== 'mill' && d.key !== 'machine' )
        setCompletedJobSearchTags(
          tempSearchTags
        )
        // let tempSearchTags = completedJobSearchTags
        // setCompletedJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'machine'),
        //     1
        //   )
        // )
      }
    } else if (removeItem.key === 'machine') {
      let searchValueMachine = completedJobSearchValue.machine
      let selectedValueMachine = completedJobSearchValue.selectedMachines
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        machine: searchValueMachine.filter((d) => d.value !== removeItem.label),
        selectedMachines: selectedValueMachine.filter((d) => d !== removeItem.label)
      })
    } else if(removeItem.key === 'user'){
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        user: 'Everyone',
        selectedUsers: []
      })
      setCompletedJobType('allJob')
    } else if(removeItem.key === 'Specific User(s)'){
      let searchValueSpecificUser = completedJobSearchValue.specificUsers
      let searchValueSelectedUser = completedJobSearchValue.selectedUsers

      if(searchValueSpecificUser.length > 0 && searchValueSelectedUser.length > 0) {
        setCompletedJobSearchValue({
          ...completedJobSearchValue,
          specificUsers: searchValueSpecificUser.filter((d) => d.value !== removeItem.userId),
          selectedUsers: searchValueSelectedUser.filter((d) => d !== removeItem.userId),
        })
      } else {
        setCompletedJobSearchValue({
          ...completedJobSearchValue,
          specificUsers: [],
          selectedUsers: []
        })
      }
     } else if (
      [
        'Any',
        'Running Exploration',
        'Running Prediction',
        'Submitted',
        'Completed',
        'Stopped',
      ].includes(removeItem.key)
    ) {
      let status = completedJobSearchValue.status
      let selectedStatusOption = completedJobSearchValue.selectedStatusOption
      status.splice(status.indexOf(removeItem.key), 1)
      selectedStatusOption.splice(selectedStatusOption.indexOf(removeItem.statusId), 1)
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        status: status,
        selectedStatusOption: selectedStatusOption
      })

    } else if (removeItem.key === 'dateTime') {
      setCompletedJobSearchValue({
        ...completedJobSearchValue,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
      })
    }
  }
  
  let handelCompletedJobTagClearAll = () => {
    setCompletedJobSearchTags([])
    setCompletedJobType('allJob')
    setCompletedJobSearchValue({
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      user: 'Everyone',
      specificUsers: [],
      status: ['Any'],
      company: [],
      mill: [],
      machine: [],
      keyword: '',
      selectedUsers: [],
      selectedCompanies: [],
      selectedMills:[],
      selectedMachines: [],
      selectedStatusOption: [],
      sortColumn: 'id',
      sortType: 'desc',
      itemsPerPage: 10,
      selectedPageNumber: 1
    })
  }

  let removeProgressJobTag = (index) => {
    let removeItem = progressJobSearchTags[index]
    setProgressJobSearchTags(progressJobSearchTags.filter((item, i) => i !== index))

    if (removeItem.key === 'keyword') {
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        keyword: '',
      })
    } else if (removeItem.key === 'company') {
      let searchValueCompany = jobInProgressSearchValue.company
      let selectedCompanies = jobInProgressSearchValue.selectedCompanies
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        company: searchValueCompany.filter((d) => d.label !== removeItem.label),
        selectedCompanies: selectedCompanies.filter((d) => d !== removeItem.label),
      })

      if (searchValueCompany.length === 1) {
        setJobInProgressSearchValue({
          ...jobInProgressSearchValue,
          company: [],
          mill: [],
          machine: [],
          selectedCompanies: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = progressJobSearchTags.filter((d) => d.key !== 'company' && d.key !== 'mill' && d.key !== 'machine' )
        setProgressJobSearchTags(
          tempSearchTags
        )
        // let tempSearchTags = progressJobSearchTags
        // setProgressJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'mill'),
        //     tempSearchTags.length
        //   )
        // )
        // setProgressJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'machine'),
        //     tempSearchTags.length
        //   )
        // )
      }
    } else if (removeItem.key === 'mill') {
      let searchValueMills = jobInProgressSearchValue.mill
      let searchSelectedMills = jobInProgressSearchValue.selectedMills
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        mill: searchValueMills.filter((d) => d.value !== removeItem.label),
        selectedMills: searchSelectedMills.filter((d) => d !== removeItem.label),
      })
      if (searchValueMills.length === 1) {
        setJobInProgressSearchValue({
          ...jobInProgressSearchValue,
          mill: [],
          machine: [],
          selectedMills:[],
          selectedMachines: [],
        })
        let tempSearchTags = progressJobSearchTags.filter((d) => d.key !== 'mill' && d.key !== 'machine' )
        setProgressJobSearchTags(
          tempSearchTags
        )
        // let tempSearchTags = progressJobSearchTags
        // setProgressJobSearchTags(
        //   tempSearchTags.splice(
        //     tempSearchTags.map((d) => d.key !== 'machine'),
        //     1
        //   )
        // )
      }
    } else if (removeItem.key === 'machine') {
      let searchValueMachine = jobInProgressSearchValue.machine
      let selectedValueMachine = jobInProgressSearchValue.selectedMachines
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        machine: searchValueMachine.filter((d) => d.value !== removeItem.label),
        selectedMachines: selectedValueMachine.filter((d) => d !== removeItem.label)
      })
    } else if(removeItem.key === 'user_name'){
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        user: 'Everyone',
        selectedUsers: []
      })
      setJobProgressType('allJob')
    } else if(removeItem.key === 'Specific User(s)'){
      let searchValueSpecificUser = jobInProgressSearchValue.specificUsers
      let searchValueSelectedUser = jobInProgressSearchValue.selectedUsers

      if(searchValueSpecificUser.length > 0 && searchValueSelectedUser.length > 0) {
        setJobInProgressSearchValue({
          ...jobInProgressSearchValue,
          specificUsers: searchValueSpecificUser.filter((d) => d.value !== removeItem.userId),
          selectedUsers: searchValueSelectedUser.filter((d) => d !== removeItem.userId),
        })
      } else {
        setJobInProgressSearchValue({
          ...jobInProgressSearchValue,
          specificUsers: [],
          selectedUsers: []
        })
      }
     } else if (
      [
        'Any',
        'Running Exploration',
        'Running Prediction',
        'Submitted',
        'Completed',
        'Stopped',
      ].includes(removeItem.key)
    ) {
      let status = jobInProgressSearchValue.status
      let selectedStatusOption = jobInProgressSearchValue.selectedStatusOption
      status.splice(status.indexOf(removeItem.key), 1)
      selectedStatusOption.splice(selectedStatusOption.indexOf(removeItem.statusId), 1)
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        status: status,
        selectedStatusOption: selectedStatusOption
      })

    } else if (removeItem.key === 'dateTime') {
      setJobInProgressSearchValue({
        ...jobInProgressSearchValue,
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
      })
    }

    // setSearchValue({
    //   ...searchValue,
    //   itemsPerPage: 10,
    //   selectedPageNumber: 1
    // })
  }
  
  let handelProgressJobClearAll = () => {
    setProgressJobSearchTags([])
    setJobProgressType('allJob')
    setJobInProgressSearchValue({
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      user: 'Everyone',
      specificUsers: [],
      status: ['Any'],
      company: [],
      mill: [],
      machine: [],
      keyword: '',
      selectedUsers: [],
      selectedCompanies: [],
      selectedMills:[],
      selectedMachines: [],
      selectedStatusOption: [],
      sortColumn: 'id',
      sortType: 'desc',
      itemsPerPage: 10,
      selectedPageNumber: 1
    })
  }

  let handelDowloadPDF = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'True', fileName: ''})).then((result) => {
      window.open(result.file); 
    })
  }

  let handelDowloadFile = (details) => {
    dispatch(jobsActions.downloadFile({ jobId: details.id, isPdfFile: 'False', fileName: details.s3_file_path })).then((result) => {
      window.open(result.file);
    })
  }

  const columns = [
    {
      label: 'Id',
      field: 'id',
      sort: true,
      width: '90px',
      align: 'center',
      customRow: (row) => {
        return (
          <Link to={'/job-in-progress/' + row.id} alt="">
            <span className={row.status_name === 'Stopped' ? 'td-id td-stopped' : 'td-id'}>
              {row.id}
            </span>
          </Link>
        )
      },
    },
    {
      label: 'Submitted',
      field: 'submission_time',
      sort: true,
      wrap: true,
      width: '140px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped' : ''}>
            {row.submission_time}
          </span>
        )
      },
    },
    {
      label: 'User',
      field: 'user_name',
      sort: true,
      width: '145px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped' : ''}>
            {row.user_name === 'Abc Def' ? (
              <span className="td-highLight">
                <b>{row.user_name}</b> (me)
              </span>
            ) : (
              row.user_name
            )}
          </span>
        )
      },
    },
    {
      label: 'Job Name',
      field: 'job_name',
      sort: true,
      wrap: true,
      width: '290px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.job_name}
          </span>
        )
      },
    },
    {
      label: 'File',
      field: 's3_file_path',
      wrap: true,
      width: '70px',
      align: 'center',
      customRow: (row) => {
        return (
          <span className="td-icon">
            <a onClick={() => handelDowloadFile(row)}>
              <i className="icon-source-file-sm"></i>
            </a>
          </span>
        )
      },
    },
    {
      label: 'Company',
      field: 'company',
      sort: true,
      wrap: true,
      width: '195px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.company}
          </span>
        )
      },
    },
    {
      label: 'Mill',
      field: 'mill',
      sort: true,
      wrap: true,
      width: '150px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.mill}
          </span>
        )
      },
    },
    {
      label: 'Machine',
      field: 'machine',
      sort: true,
      wrap: true,
      width: '130px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-large' : 'td-large'}>
            {row.machine}
          </span>
        )
      },
    },
    {
      label: 'Status',
      field: 'status_name',
      sort: true,
      wrap: true,
      width: '230px',
      customRow: (row) => {
        return (
          <>
            {row.status_name === 'Stopped' ? (
              <>
                <span className="text-danger">{row.status_name}</span>
                <Progress className="td-stopped" value={row.percentage} />
              </>
            ) : (
              <>
                <span className="text-strong">{row.status_name}</span>
                <Progress value={row.percentage} />
              </>
            )}
          </>
        )
      },
    },
    {
      label: 'Notes',
      field: 'notes',
      wrap: true,
      width: '225px',
      customRow: (row) => {
        return (
          <span className={row.status_name === 'Stopped' ? 'td-stopped td-light' : 'td-light'}>
            {row.notes}
          </span>
        )
      },
    },
    {
      label: 'Stop Job',
      field: 'stop_job',
      width: '125px',
      align: 'center',
      customRow: (row) => {
        return row.status_name === 'Stopped' ? (
          <div className="td-btn-flex">
            <span className="small-icon danger-icon">
              <i id={'btnStopJob-' + row.id} className="fa fa-stop"></i>
            </span>
          </div>
        ) : (
          <div
            className="td-btn-flex"
            onClick={() => stopJobConfirmation(row.job_name, row.id)}
          >
            <span className="small-icon">
              <i id={'btnStopJob-' + row.job_id} className="fa fa-stop"></i>
            </span>
          </div>
        )
      },
    },
  ]

  const completedJobsColumns = [
    {
      label: 'Id',
      field: 'id',
      sort: true,
      width: '90px',
      align: 'center',
      customRow: (row) => {
        return (
          <Link to={'/completed-job/' + row.id} alt="">
            <span className="td-id">{row.id}</span>
          </Link>
        )
      },
    },
    {
      label: 'Submitted',
      field: 'submission_time',
      sort: true,
      wrap: true,
      width: '140px',
    },
    {
      label: 'User',
      field: 'user_name',
      sort: true,
      width: '145px',
      customRow: (row) => {
        return (
          <>
            {row.user_name === 'Abc Def' ? (
              <span className="td-highLight">
                <b>{row.user_name}</b> (me)
              </span>
            ) : (
              row.user_name
            )}
          </>
        )
      },
    },
    {
      label: 'Job Name',
      field: 'job_name',
      sort: true,
      wrap: true,
      width: '294px',
      customRow: (row) => {
        return <span className="td-large">{row.job_name}</span>
      },
    },
    {
      label: 'File',
      field: 's3_file_path',
      wrap: true,
      width: '70px',
      align: 'center',
      customRow: (row) => {
        return (
          <span className="td-icon">
            <a onClick={() => handelDowloadFile(row)}>
              <i className="icon-source-file-sm"></i>
            </a>
          </span>
        )
      },
    },
    {
      label: 'Company',
      field: 'company',
      sort: true,
      wrap: true,
      width: '195px',
      customRow: (row) => {
        return <span className="td-large">{row.company}</span>
      },
    },
    {
      label: 'Mill',
      field: 'mill',
      sort: true,
      wrap: true,
      width: '150px',
      customRow: (row) => {
        return <span className="td-large">{row.mill}</span>
      },
    },
    {
      label: 'Machine',
      field: 'machine',
      sort: true,
      wrap: true,
      width: '130px',
      customRow: (row) => {
        return <span className="td-large">{row.machine}</span>
      },
    },
    {
      label: 'Notes',
      field: 'notes',
      wrap: true,
      width: '265px',
      customRow: (row) => {
        return <span className="td-light">{row.notes}</span>
      },
    },
    {
      label: 'Report',
      field: '',
      width: '320px',
      customRow: (row) => {
        return (
          <div className="td-btn-flex">
            <Link to={'/completed-job/' + row.id} alt="">
              <Button
                className="dark-shadow view-btn"
                type="button"
                id={'btnCompleted-' + row.id}
              >
                View
              </Button>
            </Link>
            <span className="small-icon ml-4" onClick={() => handelDowloadPDF(row)}>
              <i id={'btnDownload-' + row.id} className="icon-download"></i>
            </span>
            <span
              className="small-icon ml-4"
              onClick={() => handelShareJob(row.job_name, row)}
            >
              <i id={'btnEmail-' + row.id} className="icon-email"></i>
            </span>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Header></Header>
      <main>
        <section>
          <div className="container">
            {/* <button type="button" onClick={successModalToggle}>Success Modal</button> */}
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-4">
                <h2>
                  <i className="icon-new-job icon"></i>
                </h2>
              </div>
              <div className="col-lg-4 text-right">
                {startANewJobOpen ? (
                  <div
                    className="expand-toggle"
                    onClick={() => setStartANewJobOpen(false)}
                  >
                    <span>collapse</span>
                  </div>
                ) : (
                  <div
                    className="expand-toggle active"
                    onClick={() => setStartANewJobOpen(true)}
                  >
                    <span>expand</span>
                  </div>
                )}
              </div>
            </div>

            <Collapse className="drag-drop-file mt-4" isOpen={startANewJobOpen}>
              <CreateJob></CreateJob>
            </Collapse>
          </div>
        </section>

        <section>
          <div className="container">
            <hr className="hr"></hr>
          </div>
        </section>

        <section className="mb-5" ref={jobsInProgressRef}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-4">
                <h2>
                  <i className="icon icon-progress"></i>
                  <span>jobs in progress</span>
                </h2>
              </div>
              <div className="col-lg-8">
                <div className="float-right">
                  <div className={isAdminRole(userInfo['role_name']) ? 'jobs-btn' : 'hide'}>
                    <Button
                      type="button"
                      id="btnJobsInProgresAllJobs"
                      className={jobProgressType === 'allJob' ? 'active' : ''}
                      onClick={() => {
                        setJobInProgressSearchValue({
                          ...jobInProgressSearchValue,
                          user: 'Everyone',
                          selectedUsers: [],
                          specificUsers: []
                        })
                        setJobProgressType('allJob')
                        let tempSearchTags = progressJobSearchTags.filter(
                          (d) => d.key !== 'user' 
                        )
                        setProgressJobSearchTags(tempSearchTags)
                      }}
                    >
                      All Jobs
                    </Button>
                    <Button
                      type="button"
                      id="btnJobsInProgresMyJobs"
                      className={jobProgressType === 'myJob' ? 'active' : ''}
                      onClick={() => {
                        setJobInProgressSearchValue({
                          ...jobInProgressSearchValue,
                          specificUsers: [],
                          user: filterData.userOptions[1].label,
                          selectedUsers: filterData.userOptions[1].value,
                          selectedPageNumber: 1
                        })
                        
                        setProgressJobSearchTags((oldArray) => [
                          ...oldArray,
                          { key: 'user', value: 'Users: ' + filterData.userOptions[1].label },
                        ])

                        setJobProgressType('myJob')
                      }}
                    >
                      My Jobs
                    </Button>
                  </div>
                  <Button
                    type="button"
                    className="ml-3"
                    id="btnJobsInProgresFilterTable"
                    onClick={jobInProgressToggle}
                  >
                    Filter Table <i className="icon icon-filter"></i>
                  </Button>
                  <Link to="/jobs-in-progress" alt="">
                    <Button
                      type="button"
                      className="ml-3"
                      id="btnViewAllJobsInProgres"
                    >
                      View All Jobs in process{' '}
                      <i className="icon icon-double-arrows"></i>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            <div>
            <div className="row align-items-center filters-add-main">
                <div className="col-lg-8 filters-add">
                    { progressJobSearchTags  && progressJobSearchTags.length > 0 ? (
                      <span className="mr-2">Filters</span>
                    ) : (
                      ''
                    )}
                    {isProgressJobTagExpanded
                      ? progressJobSearchTags &&
                      progressJobSearchTags.map((tag, index) => {
                          return (
                            <span
                              key={index.toString()}
                              className="mr-2 filter-tags"
                            >
                              {tag.value}{' '}
                              <span
                                aria-hidden="true"
                                key={index.toString()}
                                onClick={() => removeProgressJobTag(index)}
                              >
                                &times;
                              </span>
                            </span>
                          )
                        })
                      : progressJobSearchTags &&
                      progressJobSearchTags.map((tag, index) => {
                          return index < 3 ? (
                            <span
                              key={index.toString()}
                              className="mr-2 filter-tags"
                            >
                              {tag.value}{' '}
                              <span
                                aria-hidden="true"
                                key={index.toString()}
                                onClick={() => removeProgressJobTag(index)}
                              >
                                &times;
                              </span>
                            </span>
                          ) : (
                            ''
                          )
                        })}
                    {!isProgressJobTagExpanded && progressJobSearchTags && progressJobSearchTags.length > 3 ? (
                      <span
                        className="mr-2 expand-tag filter-tags"
                        onClick={() => setProgressJobTagExpanded(true)}
                      >
                        +{progressJobSearchTags.length - 3}
                      </span>
                    ) : (
                      ''
                    )}
                    {progressJobSearchTags && progressJobSearchTags.length > 0 ? (
                      <span className="clear-all-tag" 
                      onClick={handelProgressJobClearAll}
                      >
                        clear all
                      </span>
                    ) : (
                      ''
                    )}
                </div>
              </div>
              <Datatable
                data={searchedJobInProgressJobs.jobs}
                columns={columns}
                filterData={{}}
                filterSubmit={filterSubmit}
                extendedColumns={extendedColumns}
                clearExpanded={clearExpanded}
                defaultSort={jobInProgressSearchValue.sortColumn}
                defaultSortType={jobInProgressSearchValue.sortType}
                itemsPerPage={jobInProgressSearchValue.itemsPerPage}
                selectedPageNumber={jobInProgressSearchValue.selectedPageNumber}
                totalCount={searchedJobInProgressJobs.totalCount}
                sectionRef={jobsInProgressRef}
                fetchMoreData={fetchMoreProgressdJobData}
              />
              {/* <Datatable data={jobsInProgress}></Datatable> */}
            </div>
          </div>
        </section>

        <section className="mt-5" ref={completedJobsRef}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-4">
                <h2>
                  <i className="icon icon-completed"></i>
                  <span>Completed Jobs</span>
                </h2>
              </div>
              <div className="col-lg-8">
                <div className="float-right">
                  <div className={isAdminRole(userInfo['role_name']) ? 'jobs-btn' : 'hide'}>
                    <Button
                      type="button"
                      id="btnCompletedJobsAllJobs"
                      className={completedJobType === 'allJob' ? 'active' : ''}
                      onClick={() => {
                        setCompletedJobSearchValue({
                          ...completedJobSearchValue,
                          user: 'Everyone',
                          selectedUsers: [],
                          specificUsers: []
                        })
                        setCompletedJobType('allJob')
                        let tempSearchTags = completedJobSearchTags.filter(
                          (d) => d.key !== 'user' 
                        )
                        setCompletedJobSearchTags(tempSearchTags)
                      }}
                    >
                      All Jobs
                    </Button>
                    <Button
                      type="button"
                      id="btnCompletedJobsMyJobs"
                      className={completedJobType === 'myJob' ? 'active' : ''}
                      onClick={() => {
                        setCompletedJobSearchValue({
                          ...completedJobSearchValue,
                          specificUsers: [],
                          user: filterData.userOptions[1].label,
                          selectedUsers: filterData.userOptions[1].value,
                          selectedPageNumber: 1
                          
                        })
                        
                        setCompletedJobSearchTags((oldArray) => [
                          ...oldArray,
                          { key: 'user', value: 'Users: ' + filterData.userOptions[1].label },
                        ])

                        setCompletedJobType('myJob')
                      }}
                    >
                      My Jobs
                    </Button>
                  </div>
                  <Button
                    type="button"
                    id="btnCompletedJobsFilterTable"
                    className="ml-3"
                    onClick={completedJobToggle}
                  >
                    Filter Table <i className="icon icon-filter"></i>
                  </Button>
                  <Link to="/completed-jobs" alt="">
                    <Button
                      type="button"
                      id="btnViewAllCompletedJobs"
                      className="ml-3"
                    >
                      View All Completed Jobs
                      <i className="icon icon-double-arrows"></i>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className="row align-items-center filters-add-main">
                <div className="col-lg-8 filters-add">
                    { completedJobSearchTags  && completedJobSearchTags.length > 0 ? (
                      <span className="mr-2">Filters</span>
                    ) : (
                      ''
                    )}
                    {isCompletedJogTagExpanded
                      ? completedJobSearchTags &&
                      completedJobSearchTags.map((tag, index) => {
                          return (
                            <span
                              key={index.toString()}
                              className="mr-2 filter-tags"
                            >
                              {tag.value}{' '}
                              <span
                                aria-hidden="true"
                                key={index.toString()}
                                onClick={() => removeCompletedJobTag(index)}
                              >
                                &times;
                              </span>
                            </span>
                          )
                        })
                      : completedJobSearchTags &&
                      completedJobSearchTags.map((tag, index) => {
                          return index < 3 ? (
                            <span
                              key={index.toString()}
                              className="mr-2 filter-tags"
                            >
                              {tag.value}{' '}
                              <span
                                aria-hidden="true"
                                key={index.toString()}
                                onClick={() => removeCompletedJobTag(index)}
                              >
                                &times;
                              </span>
                            </span>
                          ) : (
                            ''
                          )
                        })}
                    {!isCompletedJogTagExpanded && completedJobSearchTags && completedJobSearchTags.length > 3 ? (
                      <span
                        className="mr-2 expand-tag filter-tags"
                        onClick={() => setCompletedJogTagTagExpanded(true)}
                      >
                        +{completedJobSearchTags.length - 3}
                      </span>
                    ) : (
                      ''
                    )}
                    {completedJobSearchTags && completedJobSearchTags.length > 0 ? (
                      <span className="clear-all-tag" 
                      onClick={handelCompletedJobTagClearAll}
                      >
                        clear all
                      </span>
                    ) : (
                      ''
                    )}
                </div>
              </div>
              <Datatable
                data={searchedCompletedJobs.jobs}
                columns={completedJobsColumns}
                filterData={{}}
                filterSubmit={filterSubmit}
                extendedColumns={extendedColumns}
                clearExpanded={clearExpanded}
                defaultSort={completedJobSearchValue.sortColumn}
                defaultSortType={completedJobSearchValue.sortType}
                itemsPerPage={completedJobSearchValue.itemsPerPage}
                selectedPageNumber={completedJobSearchValue.selectedPageNumber}
                totalCount={searchedCompletedJobs.totalCount}
                sectionRef={completedJobsRef}
                fetchMoreData={fetchMoreCompletedJobData}
              />
              {/* <Datatable data={jobsInProgress}></Datatable> */}
            </div>
          </div>
        </section>
      </main>

      <ShareJobModal
        modal={shareJobModal}
        toggle={shareJobModalToggle}
        jobTitle={jobTitle}
        jobDetails={jobDetails}
      ></ShareJobModal>

      <JobInProgressFilterModal
        modal={jobInProgressModal}
        toggle={jobInProgressToggle}
        tableType={'inProgress'}
        appyFilterData={filterJobInProgressJobTable}
        filterData={jobInProgressSearchValue}
        searchTag={progressJobSearchTags}
        setJobType={setJobProgressType}
        cancelFilter={cancelProgressJobFilter}
      ></JobInProgressFilterModal>

      <CompletedJobsFilterModal
        modal={completedJobModal}
        toggle={completedJobToggle}
        tableType={'completed'}
        appyFilterData={filterCompletedJobTable}
        filterData={completedJobSearchValue}
        searchTag={completedJobSearchTags}
        setJobType={setCompletedJobType}
        cancelFilter={cancelCompletedJobFilter}
      ></CompletedJobsFilterModal>

      {/* <SuccesssModal
        modal={successModal}
        toggle={successModalToggle}
      ></SuccesssModal> */}

      <Modal
        className="modal-dialog-centered modal-lg success-modal"
        isOpen={isConfirmationModalOpen}
        onClick={() => setConfirmationModalOpen(!isConfirmationModalOpen)}
      >
        <ModalHeader toggle={() => setConfirmationModalOpen(!isConfirmationModalOpen)}></ModalHeader>
        <ModalBody>
          {/* <h2>Success!</h2> */}
          <p>Are you sure you want to stop job {jobTitle}?</p>
          <span>Once Job is stopped you can't start this job again.</span>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            type="button"
            color="primary"
            id="btnCancelSendEmail"
            onClick={() => setConfirmationModalOpen(false)}
          >
            No <i className="icon-cancel"></i>
          </Button>
          <Button
            type="button"
            className="active ml-3"
            id="btnSendEmail"
            color="secondary"
            onClick={handelStopJob}
          >
            Yes <i className="icon-apply"></i>
          </Button>
        </ModalFooter>
      </Modal>

      <Footer></Footer>
    </>
  )
}

export default Index
