const initialState = {
    footerData: {},
  }
  
  export const Footer = (state = initialState, action) => {
    switch (action.type) {
      case 'FOOTER_DATA':
        return Object.assign({}, state, {
          footerData: action.payload.footerData,
        })
      default:
        return state
    }
  }
  