import { combineReducers } from 'redux'
// import { jobsInProgress } from './jobsInProgressReducer'
// import { completedJobs } from './completedJobsReducer'
import { searchedJobs } from './searchedJobsReducer'
import { completedJobsGraphData } from './completedJobsGraphDataReducer'
import { jobDetails } from './jobDetailsReducer'
import { updateJobData } from './updateJobReducer'
import { createJob } from './createJobReducer'
import { searchedCompletedJobs } from './searchedCompletedJobsReducer'
import { searchedJobInProgressJobs } from './searchedJobInProgressJobsReducer'

const jobsReducers = combineReducers({
  // jobsInProgress,
  // completedJobs,
  jobDetails,
  searchedJobs,
  searchedCompletedJobs,
  searchedJobInProgressJobs,
  completedJobsGraphData,
  updateJobData,
  createJob
})

export default jobsReducers
