import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import MultiSelectDropdown from "./MultiSelectDropdown";

const MasterGradeSelect = ({ options, setMasterGrades }) => {
  const [masterGradeList, setMasterGradeList] = useState([]);

  const [predictionSelectedGrade, setPredictionSelectGrade] = useState([]);
  const [modelSelectGrade, setModelSelectGrade] = useState([]);
  const convertToSimpleArray = (arr) => {
    let predictionPostData = arr.map((item) => item.label);
    return predictionPostData;
  };

  const handleAddGrade = () => {
    const masterGrades = {
      predictionGrades: convertToSimpleArray(predictionSelectedGrade),
      modelGrades: convertToSimpleArray(modelSelectGrade),
    };
    if (predictionSelectedGrade.length > 0 || modelSelectGrade.length > 0) {
      setMasterGradeList([...masterGradeList, masterGrades]);
      setMasterGrades((oldGrades) => [...oldGrades, masterGrades]);
      setPredictionSelectGrade([]);
      setModelSelectGrade([]);
    }
  };

  const handleRemoveGrade = (id) => {
    let masterGradeListUpdate = masterGradeList.filter((item, i) => id !== i);
    setMasterGradeList(masterGradeListUpdate);
  };

  useEffect(() => {
    let modelFixedGrades = predictionSelectedGrade.map((val)=>{
      return {...val, isFixed: true} 
    } );
    setModelSelectGrade(modelFixedGrades);
  }, [predictionSelectedGrade]);

  useEffect(() => {
    setMasterGrades(masterGradeList);
  }, [masterGradeList]);

  return (
    <>
     <div className=" col-lg-8 mb-4">
      <div className="master-grade-select">
        <div className="row grade-label-wrap">
          <div className="col-6 mpr-2 ">
            <label>Grades for Prediction</label>
          </div>
          <div className="col-6 mpl-2">
            <label>Grades For Model</label>
          </div>
        </div>
        {masterGradeList.length > 0 ? (
          <div className="list-group-wrap overflow-auto">
            <div className="list-group-wrapper">
              {masterGradeList.map((item, i) => {
                return (
                  <div className="grade-group-item" key={`gListItem-${i}`}>
                    <div className="grade-items-wrap ">
                      <ul className="list-group ">
                        <li className="list-group-item">
                          {item.predictionGrades.map((item) => {
                            return (
                              <span key={`pg-item-${uuidv4()}`}> {item} </span>
                            );
                          })}
                        </li>
                      </ul>

                      <ul className="list-group list-group-model">
                        <li className="list-group-item">
                          {item.modelGrades.map((item) => {
                            return (
                              <span key={`mg-item-${uuidv4()}`}> {item} </span>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                    <span
                      onClick={() => handleRemoveGrade(i)}
                      className="remove-item"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-6 mpr-2">
            <MultiSelectDropdown
              label="Grades for Prediction"
              options={options}
              gradeList={masterGradeList}
              onSelectedGrade={(val) => setPredictionSelectGrade(val)}
              selectedGrade={predictionSelectedGrade}
              handleRemoveGrade={handleRemoveGrade}
            />
          </div>

          <div className="col-6 mpl-2 ">
           {predictionSelectedGrade.length > 0 && <MultiSelectDropdown
              label="Grades For Model"
              options={options}
              optionsUpdate={false}
              onSelectedGrade={(val) => setModelSelectGrade(val)}
              selectedGrade={modelSelectGrade}
              handleRemoveGrade={handleRemoveGrade}
            />}
          </div>
        </div>
        <button
          className="btn mt-2"
          type="button"
          onClick={() => handleAddGrade()}
        >
          Add Master Grade
        </button>
      </div>
      </div>
    </>
  );
};

export default MasterGradeSelect;
