import React, { useContext } from 'react'
import TableContext from './TableContext'

const ShowEntries = props => {
    const context = useContext(TableContext);
    return (
        <select value={context.showEntry ? context.showEntry : context.entriesOptions[0] }
            onChange={(e) => context.entryChange(e)}>
            {
                context.entriesOptions.map(entry => {
                    return (
                        <option key={entry}>{entry}</option>
                    )
                })
            }
        </select>
    )
}

export default ShowEntries