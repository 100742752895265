import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

let Index = (props) => {
  const data = props.chartData ? props.chartData.frequencyList : [];
  // const data = [
  //   {
  //     tagDescription: ' Lorem ipsum',
  //     frequency: 10,
  //   },
  //   {
  //     tagDescription: 'lorem ipsum dolor',
  //     frequency: 15,
  //   },
  //   {
  //     tagDescription: 'lorem ipsum dolor sit amet',
  //     frequency: 20,
  //   },
  //   {
  //     tagDescription: 'Lorem ipsum',
  //     frequency: 25,
  //   },
  //   {
  //     tagDescription: 'lorem ipsum dolor sit amet lorem ipsum dolor',
  //     frequency: 40,
  //   },
  // ]

  let height = (30 * data.length);

  let seriesArr = []
  data.forEach((obj) => {
    seriesArr.push({
      name: obj.tagDescription,
      y: obj.frequency,
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, '#5fcf6e'],
          [1, '#aee7b6'],
        ],
      },
      showInLegend: false,
    })
  })

  const chartOptions = {
    chart: {
      type: 'bar',
      height: height
    },
    title: {
      text: '',
    },

    xAxis: {
      type: 'category',
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          inside: false,
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
        align: 'high',
      },
    },
    tooltip: {
      headerFormat: '<table>',
      pointFormat:
        '<tr><td style="color:{point.color};padding:0">{point.name}</td><td>: <b>{point.y} frequency</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: seriesArr,
        showInLegend: false,
        turboThreshold: 0,
        height: 15
      },
    ],
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
