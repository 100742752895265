import styled from 'styled-components'

export const LoaderBlock = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1200;
    background-color: rgba(255,255,255,0.9);
    display: none;
    align-items: center;
    justify-content: center;
    &.show {
        display: flex;
    }
    img {
        max-width: 80px;
    }
`