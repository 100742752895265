import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import JobDetails from '../../components/JobDetails'
import Breadcrums from '../../components/Breadcrums'
import DensityPlotChart from '../../components/Charts/DensityPlotChart'
import MaxCorrelationChart from '../../components/Charts/MaxCorrelationChart'
import HeatMap from '../../components/Charts/HeatMap'
import BoxPlot from '../../components/Charts/BoxPlot'
import PredictionVsActual from '../../components/Charts/PredictionVsActual'
import ErrorReductionPlot from '../../components/Charts/ErrorReductionPlot'
import Frequeny from '../../components/Charts/Frequeny'
import Temporal from '../../components/Charts/Temporal'
import ShareJobModal from '../../components/ShareJobModal'

import chart1 from '../../public/images/chart1.png'
import chart2 from '../../public/images/chart2.png'
import chart3 from '../../public/images/chart3.png'
import chart4 from '../../public/images/chart4.png'
import chart5 from '../../public/images/chart5.png'
import chart6 from '../../public/images/chart6.png'
import chart7 from '../../public/images/chart7.png'

import jobs from '../../redux/actions/jobs'

import { Link } from 'react-router-dom'
import {
  Collapse,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

let Index = (props) => {
  const [isDensityPlotChartOpen, setDensityPlotChartOpen] = useState(false)
  const [isMaxCorrelationChartOpen, setMaxCorrelationChartOpen] = useState(false)
  const [isHeatMapChartOpen, setHeatMapChartOpen] = useState(false)
  const [
    isPredictionVSActualDataOpen,
    setPredictionVSActualDataOpen,
  ] = useState(false)
  const [isErrorReductionPlotDataOpen, setErrorReductionPlotDataOpen] = useState(false)
  const [isFrequencyChartOpen, setFrequencyChartOpen] = useState(false)
  const [isTemporalChartOpen, setTemporalChartOpen] = useState(false)
  const [isBoxPlotOpen, setBoxPlotOpen] = useState(false)
  const [isZoomModalOpen, setZoomModalOpen] = useState(false)
  const [zoomModalType, setzoomModalType] = useState('')
  const [zoomGraphData, setzoomModalData] = useState('')

  const [shareJobModal, setShareJobModal] = useState(false)
  const [isExpandRow, setExpandRow] = useState(false)

  const frequencyChartTimerRef = React.useRef(null);
  const temporalChartTimerRef = React.useRef(null);
  const predictionVsActualChartTimerRef = React.useRef(null);
  const errorReductionPlotChartTimerRef = React.useRef(null);
  const jobInProgressTimerRef = React.useRef(null);
  const {jobDetails, completedJobsGraphData} = useSelector((state) => state.jobs)
  const dispatch = useDispatch();
  useEffect(() => {

    clearInterval(predictionVsActualChartTimerRef.current)
    if (props.match.path === '/job-in-progress/:jobId' && isPredictionVSActualDataOpen && completedJobsGraphData.isPredictionVSActualData) {

      predictionVsActualChartTimerRef.current = setInterval(() => {
        dispatch(jobs.getisPredictionVSActualDataPlotEveryMin({ 'jobId': props.match.params.jobId, 'lastPointTime': completedJobsGraphData.isPredictionVSActualData.lastTimeStamp }));

      }, 60000);

    }
  }, [completedJobsGraphData.isPredictionVSActualData.lastTimeStamp, isPredictionVSActualDataOpen])

  useEffect(() => {
    clearInterval(errorReductionPlotChartTimerRef.current)
    if (props.match.path === '/job-in-progress/:jobId' && isErrorReductionPlotDataOpen && completedJobsGraphData.isErrorReductionPlotData) {
      errorReductionPlotChartTimerRef.current = setInterval(() => {
        dispatch(jobs.getisErrorReductionPlotDataEveryMin({ 'jobId': props.match.params.jobId, 'lastPointTime': completedJobsGraphData.isErrorReductionPlotData.lastTimeStamp }));
      }, 60000);
    }
  }, [isErrorReductionPlotDataOpen, completedJobsGraphData.isErrorReductionPlotData.lastTimeStamp])


  useEffect(() => {
    
    clearInterval(frequencyChartTimerRef.current)
    if (props.match.path === '/job-in-progress/:jobId' && isFrequencyChartOpen && completedJobsGraphData.frequencyChartData) {

      frequencyChartTimerRef.current = setInterval(() => {
        dispatch(jobs.getFrequencyChartPlotEveryMin({ 'jobId': props.match.params.jobId, 'lastPointTime': completedJobsGraphData.frequencyChartData.lastTimeStamp }));

      }, 60000);
    }
  }, [completedJobsGraphData.frequencyChartData.lastTimeStamp, isFrequencyChartOpen])

  useEffect(() => {
    
    clearInterval(temporalChartTimerRef.current)
    if (props.match.path === '/job-in-progress/:jobId' && isTemporalChartOpen && completedJobsGraphData.temporalChartData) {

      temporalChartTimerRef.current = setInterval(() => {
        dispatch(jobs.getTemporalChartPlotEveryMin({ 'jobId': props.match.params.jobId, 'lastPointTime': completedJobsGraphData.temporalChartData.lastTimeStamp }));

      }, 60000);
    }
  }, [completedJobsGraphData.temporalChartData.lastTimeStamp, isTemporalChartOpen])


  useEffect(() => {
    if(props.match.params.jobId) {
      dispatch(jobs.getJobsDetails({'jobId': props.match.params.jobId}))
      if(props.match.path === '/job-in-progress/:jobId') {
        jobInProgressTimerRef.current = setInterval(() => { 
          dispatch(jobs.getJobsDetailsEveryMin({'jobId': props.match.params.jobId}))
        }, 60000);
      }
    }
    return () => {
      // componentWillUnmount events
      clearInterval(temporalChartTimerRef.current)
      clearInterval(predictionVsActualChartTimerRef.current)
      clearInterval(errorReductionPlotChartTimerRef.current)
      clearInterval(frequencyChartTimerRef.current)
      clearInterval(jobInProgressTimerRef.current)
    }
  }, [dispatch, props.match.params.jobId])

  let getDensityPortChartData = () => {
    setDensityPlotChartOpen(!isDensityPlotChartOpen)
    if(!isDensityPlotChartOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getDensityPlot({'jobId': props.match.params.jobId}));
      }
    }
  }

  let getMaxCorrelationChart = () => {
    setMaxCorrelationChartOpen(!isMaxCorrelationChartOpen)
    if(!isMaxCorrelationChartOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getMaxCorrPlot({'jobId': props.match.params.jobId}));
      }
    }
  }

  let getHeatMapChart = () => {
    setHeatMapChartOpen(!isHeatMapChartOpen)
    if(!isHeatMapChartOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getHeatmapPlot({'jobId': props.match.params.jobId}));
      }
    }
  }

  let getPredictionVSActualData = () => {
    setPredictionVSActualDataOpen(!isPredictionVSActualDataOpen)
    if(!isPredictionVSActualDataOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getisPredictionVSActualDataPlot({'jobId': props.match.params.jobId}));
      }
    } 
    
  }

  let getErrorReductionPlotData = () => {
    setErrorReductionPlotDataOpen(!isErrorReductionPlotDataOpen)
    if(!isErrorReductionPlotDataOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getisErrorReductionPlotData({'jobId': props.match.params.jobId}));
      }
    } 
    
  }


  let getFrequencyChart = () => {
    setFrequencyChartOpen(!isFrequencyChartOpen)
    if(!isFrequencyChartOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getFrequencyChartPlot({'jobId': props.match.params.jobId}));   
      }
    }
  }

  let getTemporalChart = () => {
    setTemporalChartOpen(!isTemporalChartOpen)
    if(!isTemporalChartOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getTemporalChartPlot({'jobId': props.match.params.jobId}))
      }
    } 
  }

  let getBoxPlotChart = () => {
    setBoxPlotOpen(!isBoxPlotOpen)
    if(!isBoxPlotOpen) {
      if(jobDetails.jobDetailsData) {
        dispatch(jobs.getExplorationTablePlot({'jobId': props.match.params.jobId, 'lastTagId': 0, 'isExpandAll': false}));
      }
    }
  }

  const shareJobModalToggle = () => setShareJobModal(!shareJobModal)

  let allExpandExploration = (expandRow, lastTagId ) => {
    setExpandRow(expandRow)
    dispatch(jobs.getExplorationTablePlotData({'jobId': props.match.params.jobId, 'lastTagId': parseInt(lastTagId), 'isExpandAll': true}));
  }

  let handelDowloadPDF = () => {
    dispatch(jobs.downloadFile({ jobId: props.match.params.jobId, isPdfFile: 'True', fileName: '' })).then((result) => {
      window.open(result.file); 
    })
  }

  let handelInProgressDowloadPDF = () => {
    dispatch(jobs.downloadInProgressFile({ jobId: props.match.params.jobId })).then((result) => {
      window.open(result.file); 
    })
  }

  return (
    <>
      <Header></Header>
      <main>
        <section>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              { props.match.path === '/completed-job/:jobId' && jobDetails && jobDetails.jobDetailsData ? <div className="col-lg-5">
                <Breadcrums
                  items={[
                    { item: 'All Completed Jobs', link: '/completed-jobs' },
                    {
                      item: 'Job Id: ' + jobDetails.jobDetailsData.jobDetail[0].id,
                    },
                  ]}
                ></Breadcrums>
              </div> : ''}

              { props.match.path === '/job-in-progress/:jobId' && jobDetails && jobDetails.jobDetailsData ? <div className="col-lg-5">
                <Breadcrums
                  items={[
                    { item: 'All Jobs In Progress', link: '/jobs-in-progress' },
                    {
                      item: 'Job Id: ' + jobDetails.jobDetailsData.jobDetail[0].id,
                    },
                  ]}
                ></Breadcrums>
              </div> : ''}

              <div className="col-lg-5 text-right">
                <Link
                  id="btnStartANewJob"
                  className="btn btn-lg min-btn"
                  to="/dashboard"
                >
                  Start a new job
                </Link>
              </div>
            </div>

            { jobDetails && jobDetails.jobDetailsData ? <div className="row">
              <div className="col-12">
                <JobDetails jobDetails={jobDetails.jobDetailsData.jobDetail[0]} canEdit={props.match.path === '/job-in-progress/:jobId' ? true : false}></JobDetails>
              </div>
            </div> : '' }

            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showDensityPlot ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isDensityPlotChartOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isDensityPlotChartOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Density Plot</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>HIGHLIGHTS</h4>
                          <p>
                            The below histogram plot shows the distribution
                            pattern of the Target Variable. The chart helps us
                            to understand the range of the Target variable and
                            also helps us to identify outliers if they are
                            present.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart1} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Density Plot</h3>
                          <p>
                            The below histogram plot shows the distribution
                            pattern of the Target Variable. The chart helps us
                            to understand the range of the Target variable and
                            also helps us to identify outliers if they are
                            present.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-btn" onClick={getDensityPortChartData}>
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isDensityPlotChartOpen}
                >
                  <Card>
                    <CardBody>
                      <DensityPlotChart
                        spline
                        opposite={true}
                        chartData={completedJobsGraphData.densityData}
                      ></DensityPlotChart>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}

            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showMaxCorrPlot ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isMaxCorrelationChartOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isMaxCorrelationChartOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Maximum Correlated Variable</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                            The scatter plot below shows the shape of the
                            relationship between a selected variable and the
                            target. This variable is selected based on the
                            linear and nonlinear correlations of all the
                            variables with the target. The relationship could,
                            therefore, be of any shape.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart2} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Maximum Correlated Variable</h3>
                          <p>
                            The scatter plot below shows the shape of the
                            relationship between a selected variable and the
                            target. This variable is selected based on the
                            linear and nonlinear correlations of all the
                            variables with the target. The relationship could,
                            therefore, be of any shape.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-btn" onClick={getMaxCorrelationChart}>
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isMaxCorrelationChartOpen}
                >
                  <Card>
                    <CardBody>
                      <MaxCorrelationChart title chartData={completedJobsGraphData.maxCorrPlotData}></MaxCorrelationChart>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}

            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showHeatmapPlot ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isHeatMapChartOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isHeatMapChartOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Correlation Heatmap</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                            The correlations between the variables are computed
                            and shown in a heatmap below. The variable pairs
                            with low correlations are omitted. This chart shows
                            that the process variables also interact. This means
                            changing one variable will change another which
                            makes controling the target difficult.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart3} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Correlation Heatmap</h3>
                          <p>
                            The correlations between the variables are computed
                            and shown in a heatmap below. The variable pairs
                            with low correlations are omitted. This chart shows
                            that the process variables also interact. This means
                            changing one variable will change another which
                            makes controling the target difficult.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-btn" onClick={getHeatMapChart}>
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isHeatMapChartOpen}
                >
                  <Card>
                    <CardBody>
                      <HeatMap chartData={completedJobsGraphData.heatmapPlotData}></HeatMap>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}

            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showExplorationTable ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isBoxPlotOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isBoxPlotOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Exploration Table</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                            The following variable wise exploration shows few
                            basic statistics and visualizations. The scatter
                            plot between variable x and target y is shown to
                            indicate their relationship. Box plot and histogram
                            for x also shown for visualizing the distribution.
                            Both showing the overall distribution of variable x,
                            with former giving better visibility on outliers and
                            later on any skewness. Other descriptive statistics,
                            viz. Mean, median, standard deviation and #missing
                            values are also shown.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart4} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Exploration Table</h3>
                          <p>
                            The following variable wise exploration shows few
                            basic statistics and visualizations. The scatter
                            plot between variable x and target y is shown to
                            indicate their relationship. Box plot and histogram
                            for x also shown for visualizing the distribution.
                            Both showing the overall distribution of variable x,
                            with former giving better visibility on outliers and
                            later on any skewness. Other descriptive statistics,
                            viz. Mean, median, standard deviation and #missing
                            values are also shown.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-btn" onClick={getBoxPlotChart}>
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isBoxPlotOpen}
                >
                  <Card>
                    <CardBody className="py-0 px-0">
                      <table className="table table-chart">
                        <thead>
                          <tr>
                            <th className="text-center th-chart">
                              Scatterplot
                            </th>
                            <th className="text-center th-chart">Histogram</th>
                            <th className="text-center th-chart">BoxPlot</th>
                            <th className="justify-content-center align-self-center text-center">
                              Linear Cor
                            </th>
                            <th className="justify-content-center align-self-center text-center">
                              Non-Linear Cor
                            </th>
                            <th className="justify-content-center align-self-center text-center">
                              Mean
                            </th>
                            <th className="justify-content-center align-self-center text-center">
                              STD
                            </th>
                            <th className="justify-content-center align-self-center text-center">
                              Median
                            </th>
                            <th className="justify-content-center align-self-center text-center">
                              # Missing
                            </th>
                          </tr>
                        </thead>
                        <tbody className="mt-2">
                        {completedJobsGraphData.explorationTableData && completedJobsGraphData.explorationTableData.explorationData.length > 0 && completedJobsGraphData.explorationTableData.explorationData.map((explorationchart, index) => (
                          <>
                            <tr key={'header-'+index}>
                              <td colSpan="9">
                                <span className="stock-had-line">
                                  {explorationchart.explorationJsonData.variable}
                                </span>
                              </td>
                            </tr>
                            <tr key={'data-'+index}>
                              <td>
                                <i
                                  className="icon-zoom"
                                  onClick={() => {
                                    setZoomModalOpen(true)
                                    setzoomModalType('SCATTERPLOT')
                                    setzoomModalData(explorationchart.explorationJsonData.scatterplot)
                                  }}
                                ></i>
                                <MaxCorrelationChart height="215" chartData={explorationchart.explorationJsonData.scatterplot}></MaxCorrelationChart>
                              </td>
                              <td>
                                <i
                                  className="icon-zoom"
                                  onClick={() => {
                                    setZoomModalOpen(true)
                                    setzoomModalType('HISTOGRAM')
                                    setzoomModalData(explorationchart.explorationJsonData.histogram)
                                  }}
                                ></i>
                                <DensityPlotChart
                                  height="215"
                                  opposite={false}
                                  chartData={explorationchart.explorationJsonData.histogram}
                                ></DensityPlotChart>
                              </td>
                              <td>
                                <i
                                  className="icon-zoom"
                                  onClick={() => {
                                    setZoomModalOpen(true)
                                    setzoomModalType('BOXPLOT')
                                    setzoomModalData(explorationchart.explorationJsonData.boxPlot)
                                  }}
                                ></i>
                                <BoxPlot height="215" chartData={explorationchart.explorationJsonData.boxPlot}></BoxPlot>
                              </td>
                              <td className="justify-content-center align-self-center text-center" >
                                {explorationchart.explorationJsonData.linearcor}
                              </td>
                              <td className="justify-content-center align-self-center text-center" >
                                {explorationchart.explorationJsonData.nonlinearcor}
                              </td>
                              <td className="justify-content-center align-self-center text-center" >
                                {explorationchart.explorationJsonData.mu}
                              </td>
                              <td className="justify-content-center align-self-center text-center" >
                                {explorationchart.explorationJsonData.sigma}
                              </td>
                              <td className="justify-content-center align-self-center text-center" >
                                {explorationchart.explorationJsonData.median}
                              </td>
                              <td className="justify-content-center align-self-center text-center" >
                                {explorationchart.explorationJsonData.num_missing}
                              </td>
                            </tr>
                          </>
                        ))}
                        </tbody>
                        {completedJobsGraphData.explorationTableData && completedJobsGraphData.explorationTableData.lastTagId && completedJobsGraphData.explorationTableData.explorationTableSize > 5 ?  <tfoot>
                          <tr>
                            <td className="text-center" colSpan="9">
                              <a
                                id="btnStartANewJob"
                                className="btn btn-lg min-btn"
                                onClick={() => allExpandExploration(!isExpandRow, completedJobsGraphData.explorationTableData.lastTagId)}
                              >
                                <i className="fa fa-angle-double-down first-icon"></i>
                                show Next 50 variables
                                <i className="fa fa-angle-double-down"></i>
                              </a>
                            </td>
                          </tr>
                        </tfoot> : '' }
                      </table>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}

            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showPredictionVsActualChart ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isPredictionVSActualDataOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isPredictionVSActualDataOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Prediction System</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                            This is a traditional chart comparing the
                            predictions with the actuals. The black and green
                            points represent actuals and predictions,
                            respectively. They are shown along the timestamps on
                            the x-axis. A closeness between the actuals and
                            predictions indicate the system is stable and,
                            hence, predictable. If they are distant, the process
                            is significantly unstable and, thus, difficult to
                            predict. The cause for an unstable process could
                            range from poor data to arbitrary process variables.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart5} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Prediction System</h3>
                          <p>
                            This is a traditional chart comparing the
                            predictions with the actuals. The black and green
                            points represent actuals and predictions,
                            respectively. They are shown along the timestamps on
                            the x-axis. A closeness between the actuals and
                            predictions indicate the system is stable and,
                            hence, predictable. If they are distant, the process
                            is significantly unstable and, thus, difficult to
                            predict. The cause for an unstable process could
                            range from poor data to arbitrary process variables.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="toggle-btn"
                    onClick={getPredictionVSActualData}
                  >
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isPredictionVSActualDataOpen}
                >
                  <Card>
                    <CardBody>
                      <PredictionVsActual chartData={completedJobsGraphData.isPredictionVSActualData}></PredictionVsActual>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}
          {/* Error Reduction Plot chart */} 
          {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showErrorReductionChart ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isErrorReductionPlotDataOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isErrorReductionPlotDataOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Error Reduction Plot</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                          This is a line chart comparing the short run and long run error reduction percentages. 
                          The black and green points show the short run and long run error reduction percentages 
                          respectively. They are shown along the number of records on the x-axis. As the number of 
                          records increases, error reduction percentages tend to increase as the model learns more 
                          about the data.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart5} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Error Reduction Plot</h3>
                          <p>
                          This is a line chart comparing the short run and long run error reduction percentages. 
                          The black and green points show the short run and long run error reduction percentages 
                          respectively. They are shown along the number of records on the x-axis. As the number of 
                          records increases, error reduction percentages tend to increase as the model learns more 
                          about the data.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="toggle-btn"
                    onClick={getErrorReductionPlotData}
                  >
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isErrorReductionPlotDataOpen}
                >
                  <Card>
                    <CardBody>
                      <ErrorReductionPlot chartData={completedJobsGraphData.isErrorReductionPlotData}></ErrorReductionPlot>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}




          {/* Error Reduction Plot chart*/}


            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showPredictionFrequencyChart ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isFrequencyChartOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isFrequencyChartOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Dominant Variables</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                            A bar in this chart shows the frequency of the
                            corresponding variable being influential. The longer
                            the bar the more percentage of times the variable
                            was influential in the process. A long tail in this
                            chart would indicate that the majority of the
                            variables do not show much effect on the target.
                            While a short tail or uniform bar size will indicate
                            most variables influence the target evenly.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart6} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Dominant Variables</h3>
                          <p>
                            A bar in this chart shows the frequency of the
                            corresponding variable being influential. The longer
                            the bar the more percentage of times the variable
                            was influential in the process. A long tail in this
                            chart would indicate that the majority of the
                            variables do not show much effect on the target.
                            While a short tail or uniform bar size will indicate
                            most variables influence the target evenly.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-btn" onClick={getFrequencyChart}>
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isFrequencyChartOpen}
                >
                  <Card>
                    <CardBody>
                      <Frequeny chartData={completedJobsGraphData.frequencyChartData}></Frequeny>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}

            {jobDetails && jobDetails.jobDetailsData && jobDetails.jobDetailsData.showPredictionTemporalChart ? <div className="row mt-xl-5 mt-4">
              <div className="col-12">
                <div
                  className={
                    isTemporalChartOpen
                      ? 'accordian-chart-head active'
                      : 'accordian-chart-head'
                  }
                >
                  <div className="accordian-chart-head-left">
                    {isTemporalChartOpen ? (
                      <div className="head-chart-open-content">
                        <div className="open-title">
                          <h3>Influential Variables with time</h3>
                        </div>
                        <div className="open-info-title">
                          <h4>highlights</h4>
                          <p>
                            In a dynamic system, the effect of process variables
                            change over time. A variable which is important in
                            some time period can become sterile in another. The
                            chart below provides a visibility for this
                            phenomena. It is showing the variables that were
                            influential with time. The x-axis has the timestamps
                            and y-axis contain each variable. A green dot in the
                            chart indicates the corresponding variable was
                            influential at the timestamp. And, the intensity of
                            the dot indicates the level of influence
                            (importance).
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="head-chart-content">
                        <div className="chart-img">
                          <img alt="Chart" src={chart7} />
                        </div>
                        <div className="chart-info-title">
                          <h3>Influential Variables with time</h3>
                          <p>
                            In a dynamic system, the effect of process variables
                            change over time. A variable which is important in
                            some time period can become sterile in another. The
                            chart below provides a visibility for this
                            phenomena. It is showing the variables that were
                            influential with time. The x-axis has the timestamps
                            and y-axis contain each variable. A green dot in the
                            chart indicates the corresponding variable was
                            influential at the timestamp. And, the intensity of
                            the dot indicates the level of influence
                            (importance).
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-btn" onClick={(e) => getTemporalChart(e)}>
                    <span className="btn"></span>
                  </div>
                </div>
                <Collapse
                  className="accordian-chart-middle"
                  isOpen={isTemporalChartOpen}
                >
                  <Card>
                    <CardBody>
                      <Temporal chartData={completedJobsGraphData.temporalChartData}></Temporal>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div> : ''}

            {props.match.path === '/jobs-in-progress/:jobId' ? <div className="row mt-xl-5 mt-4 justify-content-end">
              <div className="col-lg-7 d-flex justify-content-end text-right">
                <button
                  type="button"
                  disabled
                  className="btn btn-lg px-5 disabled"
                  onClick={shareJobModalToggle}
                >
                  Email <i className="icon-email"></i>
                </button>
                <button
                  type="button"
                  className="active ml-4 px-5 btn btn-lg min-btn"
                  onClick={() =>  handelDowloadPDF()}
                >
                  Download Report <i className="icon-download"></i>
                </button>
              </div>
            </div> : <div className="row mt-xl-5 mt-4 justify-content-end">
              <div className="col-lg-7 d-flex justify-content-end text-right">
                <button
                  type="button"
                  className="btn btn-lg px-5 disabled"
                  disabled
                  onClick={shareJobModalToggle}
                >
                  Email <i className="icon-email"></i>
                </button>
                <button
                  type="button"
                  className="active ml-4 px-5 btn btn-lg min-btn"
                  onClick={() => handelInProgressDowloadPDF()}
                >
                  Download Report <i className="icon-download"></i>
                </button>
              </div>
            </div>}

          </div>
        </section>
        <ShareJobModal
          modal={shareJobModal}
          toggle={shareJobModalToggle}
          jobTitle={jobDetails && jobDetails.jobDetailsData ? jobDetails.jobDetailsData.jobDetail[0].job_name : ''}
          jobDetails={jobDetails && jobDetails.jobDetailsData ? jobDetails.jobDetailsData.jobDetail[0] : ''}
        ></ShareJobModal>

        <Modal
          className="modal-dialog-centered modal-xl filter-modal"
          isOpen={isZoomModalOpen}
          onClick={() => setZoomModalOpen(!isZoomModalOpen)}
        >
          <ModalHeader toggle={() => setZoomModalOpen(!isZoomModalOpen)}>
            {zoomModalType}
          </ModalHeader>
          <ModalBody className="filter-table-modal">
            {zoomModalType === 'SCATTERPLOT' ? (
              <MaxCorrelationChart height="250" chartData={zoomGraphData}></MaxCorrelationChart>
            ) : zoomModalType === 'HISTOGRAM' ? (
              <DensityPlotChart
                height="250"
                opposite={false}
                chartData={zoomGraphData}
              ></DensityPlotChart>
            ) : zoomModalType === 'BOXPLOT' ? (
              <BoxPlot height="250" chartData={zoomGraphData}></BoxPlot>
            ) : (
              ''
            )}
          </ModalBody>
          <ModalFooter>
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center">
                
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </main>
      <Footer></Footer>
    </>
  )
}
export default Index
