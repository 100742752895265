const initialState = {
    searchedJobs: [],
  }
  
  export const searchedJobs = (state = initialState, action) => {
    switch (action.type) {
      case 'SEARCHED_JOBS':
        return Object.assign([], state, action.payload.searchedJobs)
      default:
        return state
    }
  }
  