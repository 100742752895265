export function isBlank(value) {
	return typeof (value) === 'undefined' || value == null || value == '';
}

export function isNotBlank(value){
	return !isBlank(value)
}

export function isAdminRole(role){
	return (isNotBlank(role) && role === 'Admin')
}