const initialState = {
    searchedCompletedJobs: [],
}
  
export const searchedCompletedJobs = (state = initialState, action) => {
    switch (action.type) {
        case 'SEARCHED_COMPLETED_JOBS':
            return Object.assign([], state, action.payload.searchedCompletedJobs)
        default:
            return state
    }
}
  