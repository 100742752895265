const initialState = {
    searchedJobInProgressJobs: [],
}
  
export const searchedJobInProgressJobs = (state = initialState, action) => {
    switch (action.type) {
        case 'SEARCHED_PROGRESS_JOBS':
            return Object.assign([], state, action.payload.searchedJobInProgressJobs)
        default:
            return state
    }
}