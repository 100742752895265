import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addHighchartsMore from 'highcharts/highcharts-more';

addHighchartsMore(Highcharts);

// require('highcharts/modules')(Highcharts)

let Index = (props) => {
  let data = props.chartData ? props.chartData.data : [];
  const xAxisLabel = props.chartData ? props.chartData.variable : ''
  const yAxisLabel = props.chartData ? props.chartData.y : ''
  
  const chartOptions = {
    chart: {
      type: 'boxplot',
      height: props.height ? props.height : '',
    },

    title: {
      text: '',
    },

    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: xAxisLabel,
      },
    },
    yAxis: {
      title: {
        text: yAxisLabel,
      },
    },

    series: [
      {
        name: 'Observations',
        data: data,
        color: '#3c90a5',
        tooltip: {
          headerFormat: '<em>{point.key}</em><br/>',
        },
      },
    ],
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
