import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/histogram-bellcurve')(Highcharts)
require('highcharts/modules/histogram-bellcurve.src')(Highcharts)

let Index = (props) => {
  
  const data = props.chartData ? props.chartData.data : [];
  const xAxisLabel = props.chartData ? props.chartData.variable : ''
  // const [newData] = useState([[2, 1], [2.2, 7], [2.4, 11], [2.6, 14], [2.8, 24], [3.0, 37], [3.2, 13], [3.4, 18], [3.6, 10], [3.8, 9], [4.0, 3], [4.2, 2], [4.4, 1]])

  const chartOptions = {
    chart: {
      height: props.height ? props.height : 540,
    },
    title: {
      text: props.title ? 'Highcharts Histogram' : '',
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        title: { text: '' },
        alignTicks: true,
      },
      {
        title: { text: xAxisLabel },
        alignTicks: true,
        visible: true,
      },
    ],

    yAxis: [
      {
        title: { text: '' },
      },
      {
        title: { text: '' },
        visible: true
      },
    ],

    legend: {
      enabled: false,
    },
    plotOptions: {
      histogram: {
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#5fcf6e'],
            [1, '#aee7b6'],
          ],
        },
        // color: 'linearGradient(red , yellow)',
        // color: '#00ff00',
        // linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        // colors: ['#0b4572', '#00ff00', '#2f8fce', '#C7432B', '#999999', '#DF9239', '#A14A7B', '#FFFFFF'],
      },
      spline: {
        color: '#3c90a5',
      },
    },
    series: [
      {
        name: 'Histogram',
        type: 'histogram',
        baseSeries: 's1',
        xAxis: 1,
        yAxis: 1,
        zIndex: -1,
        visible: true,
      },
      // {
      //   name: 'Spline',
      //   type: 'spline',
      //   visible: false,
      //   data: props.spline ? newData : [],
      // },
      {
        name: '',
        type: '',
        data: data,
        id: 's1',
        visible: false,
      },
    ],
  }

  return (
    <div className="density-plot">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Index
