const initialState = {
    isLoading: false,
  }
  
  export const Loader = (state = initialState, action) => {
    switch (action.type) {
      case 'SHOW_LOADER':
        return Object.assign({}, state, {
            isLoading: action.payload.isLoading,
        })
      default:
        return state
    }
  }